import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledText } from './Styled';

export function Button({
  content, onClick, disabled, ...props
}) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} title={content} {...props}>
      <StyledText>{content}</StyledText>
    </StyledButton>
  );
}

Button.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => null,
  content: 'Close',
  disabled: false,
};

export default Button;
