import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { read, utils } from 'xlsx';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import api from '../../../../api/req';
import { Loader } from '../../../Styled/Misc';
import AppContext from '../../../../providers/authProvider';

function XlxsViewer({ filePath }) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [data, setData] = useState(null);
  const { auth } = useContext(AppContext);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get(filePath, auth, {}, false, true);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.arrayBuffer();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => {
        const dta = new Uint8Array(d);
        const workbook = read(dta, { type: 'array' });
        const sheets = Object.keys(workbook.Sheets).map((name) => {
          const html = utils.sheet_to_html(workbook.Sheets[name]);
          // console.log(name, d)
          return { name, html };
        });
        setData(sheets);
      })
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, filePath]);
  return (
    <div className="position-relative h-100 w-100 d-flex flex-column">
      {loading && <Loader relative />}
      {err && (
        <Alert variant="danger">
          {err}
        </Alert>
      )}
      <div className="w-100 flex-fill overflow-auto bg-white m-auto">
        <Tabs defaultActiveKey="t0">
          {(data || []).map(({ name, html }, i) => (
            <Tab title={name} key={name} eventKey={`t${i}`}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default memo(XlxsViewer);
