import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../BasicEditor/tableControls';

function AddContactRow({
  row, fields, index, errors, onChange, contactInfoTypes, onDeleteRow,
}) {
  const typeProps = tableHooks.useSelectorInputProps('type', row, fields, errors, onChange, index);
  const contactProps = tableHooks.useStringInputProps('contact', row, fields, errors, onChange, index);

  return (
    <div className="d-flex flex-nowrap align-items-baseline gap-1">
      <Row className="ms-1 flex-fill my-1">
        <Col sm={6}>
          <TableControls.SelectorInput {...typeProps} values={contactInfoTypes.selectorValues} />
        </Col>
        <Col sm={6}>
          <TableControls.StringInput {...contactProps} />
        </Col>
      </Row>
      <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
        <FontAwesomeIcon icon={faTimes} title="Delete record" />
      </Button>
    </div>
  );
}

AddContactRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  contactInfoTypes: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

AddContactRow.defaultProps = {
  errors: {},
};

export default memo(AddContactRow);
