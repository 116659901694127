import React, {
  Fragment,
  memo,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, InputGroup, Nav, Row, Tab, Tabs,
} from 'react-bootstrap';
import { faCheckDouble, faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../api/req';
import AppContext from '../../../providers/authProvider';
import { SaveButton, DeleteButton } from '../../../components/Styled/Buttons';
// eslint-disable-next-line import/no-cycle
import { SmallEditor, Comments, Files } from '../../peoples/editor';
import { useDict } from '../../BaseLister/hook';
import useEditor from '../../BasicEditor/hook/editor';
import EditorContainer from '../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../BasicEditor/editorControls';
import { DayShedule } from '../../../components/Controls';
import { InterviewItem } from '../list';
import DateShow from '../../../components/Controls/dates/dateShow';

const STATUSES = {
  scheduled: {
    variant: 'outline-primary',
    icon: faClock,
  },
  canceled: {
    variant: 'outline-secondary',
    icon: faTimes,
  },
  executed: {
    variant: 'outline-success',
    icon: faCheckDouble,
  },
};

function InterviewForm({
  id, onSave, onClose, peopleId,
}) {
  const { auth, currentUser } = useContext(AppContext);

  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading,
  } = useEditor({
    id,
    backendURL: '/api/interviews/',
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });

  useEffect(() => {
    if (peopleId && data.people_id !== peopleId) {
      actions.onChange({ people_id: peopleId });
    }
  }, [actions, data.people_id, peopleId]);

  const isReadOnly = useMemo(
    () => {
      if (data.executor_id === currentUser.id) return false; // Исполнитель всегда может менять
      if (isNew) return false;
      if (data.author && (data.author.id === currentUser.id)) return false;// Менять может только автор или исполнитель
      return true;
    },
    [currentUser.id, data.author, data.executor_id, isNew],
  );

  const users = useDict({ url: '/api/core/user/' });
  const executorProps = editorHooks.useSelectorInputProps('executor_id', data, fields, fieldErrors, actions.onChange, isReadOnly);
  const startProps = editorHooks.useSelectorInputProps('start', data, fields, fieldErrors, actions.onChange, isReadOnly);
  const commentProps = editorHooks.useTextInputProps('comment', data, fields, fieldErrors, actions.onChange, isReadOnly);

  const date = useMemo(
    () => (data.start ? data.start.substr(0, 10) : null),
    [data.start],
  );
  const cDate = useMemo(() => new Date(date), [date]);
  const onChangeDate = useCallback(
    (e, v) => {
      const st = data.start ? data.start.substr(11, 14) : '09:00:00';
      const end = data.end ? data.end.substr(11, 14) : '09:30:00';
      actions.onChange({
        start: `${v}T${st}`,
        end: `${v}T${end}`,
      });
    },
    [actions, data.end, data.start],
  );
  const { onLoading } = actions;
  const [planedInterviews, setPlanedInterviews] = useState([]);
  useEffect(
    () => {
      const dayLoader = async () => {
        const r = await api.get('/api/interviews/', auth, {
          start__gte: `${date}T00:00:00`,
          start__lte: `${date}T23:59:59`,
        });
        if (!r.ok) throw new Error(`${r.status} ${r.stausText}`);
        return r.json();
      };
      if (date) {
        onLoading(true);
        dayLoader()
          .then((d) => setPlanedInterviews(d.results))
          .catch((e) => console.error(e.message))
          .finally(() => onLoading(false));
      }
    },
    [auth, date, onLoading],
  );

  const [unavaInterval, setUnavaInterval] = useState(null);
  useEffect(
    () => {
      const intervalLoader = async () => {
        const r = await api.get('/api/unavailable-intervals/', auth, {
          start__gte: `${date}T00:00:00`,
          start__lte: `${date}T23:59:59`,
          user: data.executor_id,
        });
        if (!r.ok) throw new Error(`${r.status} ${r.stausText}`);
        return r.json();
      };
      if (data.executor_id) {
        onLoading(true);
        intervalLoader()
          .then((d) => setUnavaInterval(d[0]))
          .catch((e) => console.error(e.message))
          .finally(() => onLoading(false));
      } else {
        setUnavaInterval(null);
      }
    },
    [auth, data.executor_id, date, onLoading],
  );

  const length = useMemo(
    () => {
      const s = new Date(data.start);
      const e = new Date(data.end);
      return Math.round((e.valueOf() - s.valueOf()) / 60000) + 1;
    },
    [data.end, data.start],
  );

  const onSetStart = useCallback(
    (e, v) => {
      const s = new Date(v);
      s.setMinutes(s.getMinutes() + length - 1);
      actions.onChange({ start: v, end: s.toJSON() });
    },
    [actions, length],
  );
  const onSetLength = useCallback(
    (e, v) => {
      const s = new Date(data.start);
      s.setMinutes(s.getMinutes() + v - 1);
      actions.onChange({ end: s.toJSON() });
    },
    [actions, data.start],
  );

  // const canChangeStatus = useMemo(
  //   () => {
  //     if (isNew) return false;
  //     if (data.executor_id === currentUser.id) return true; // Исполнитель всегда может менять
  //     return false;
  //   },
  //   [currentUser.id, data.executor_id, isNew],
  // );
  const onSetStatus = useCallback(
    (status) => {
      const newData = { ...data, status };
      actions.rawSave(newData);
    },
    [actions, data],
  );

  const title = useMemo(
    () => {
      const sDate = new Date(data.start);
      const strStartDate = sDate.toLocaleString('en-CY', { day: 'numeric', month: 'numeric', year: '2-digit' });
      const strStartTime = sDate.toLocaleTimeString('en-CY', { hour: '2-digit', minute: '2-digit' });
      // eslint-disable-next-line no-nested-ternary
      const strAuthor = data.author
        ? ((data.author.first_name || data.author.last_name) ? `${data.author.first_name} ${data.author.last_name}` : data.author.username)
        : '';
      const strPeople = data.people ? `${data.people.name} ${data.people.family_name}` : '';
      const strPositions = data.people ? String(data.people.positions.map((p) => p.repr)) : '';
      const strCompany = data.people && data.people.company ? data.people.company.repr : '';
      return `${strStartTime} ${strStartDate}. Candidate for ${strCompany} - ${strPositions} - ${strPeople}. Scheduled by ${strAuthor}`;
    },
    [data.author, data.people, data.start],
  );
  if (!fields) return null;

  return (
    <Container fluid>
      <Tabs defaultActiveKey="Interview">
        <Tab title="Interview" eventKey="Interview">
          <Nav className="my-2 gap-2">
            <SaveButton onClick={() => actions.onSave()} disabled={isReadOnly} content="Save" />
            <DeleteButton onClick={() => actions.onDelete()} disabled={isReadOnly || isNew} />
            <div className="d-flex ms-auto border-start gap-2 ps-2">
              {fields.status.choices.map((s) => (
                <Button
                  key={s.value}
                  variant={STATUSES[s.display_name].variant}
                  disabled={isReadOnly || data.status === s.value}
                  onClick={() => onSetStatus(s.value)}
                >
                  <FontAwesomeIcon icon={STATUSES[s.display_name].icon} className="me-2" />
                  Set status to
                  {' '}
                  {s.display_name}
                </Button>
              ))}
            </div>
          </Nav>
          <EditorContainer
            title={title}
            isLoading={loading}
            fields={fields}
            err={systemErrors}
            nonFieldErrors={nonFieldErrors}
            fieldErrors={fieldErrors}
            onClearErrors={actions.onClearErrs}
            onClearNonFieldErrors={actions.onClearNonFieldErrors}
          >
            <Row>
              <Col>
                <EditorControls.SelectorInput {...executorProps} values={users.selectorValues} label="Executor" />
              </Col>
              <Col>
                <EditorControls.DateInput
                  label="Interview date"
                  required
                  value={date}
                  onChange={onChangeDate}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>
            {planedInterviews.length ? (
              <>
                <h5 className="text-info">
                  Scheduled interviews on
                  <DateShow className="ms-2" value={date} />
                </h5>
                <DayShedule
                  planningDate={cDate}
                  items={planedInterviews.map((i) => ({
                    start: i.start,
                    end: i.end,
                    item: (
                      <InterviewItem
                        key={i.id}
                        interveiw={i}
                        mode="byPeople"
                        date={i.id === data.id ? data.start : null}
                        short
                      />
                    ),
                  }))}
                />
              </>
            ) : (
              <h5 className="my-2 text-secondary w-100 text-center">
                No existing interview planed on
                {cDate.toLocaleDateString('en-CY')}
              </h5>
            )}
          </EditorContainer>
          {unavaInterval && (
          <h5 className="text-danger">
            Warning. The Executor of the interview is unavailable from
            <DateShow value={unavaInterval.start} className="mx-2" />
            to
            <DateShow value={unavaInterval.end} className="mx-2" />
          </h5>
          )}
          <h5 className="text-primary">
            This interview
          </h5>
          <Row>
            <Col>
              <EditorControls.TimeInput {...startProps} onChange={onSetStart} />
            </Col>
            <Col>
              <EditorControls.NumberInput
                minValue={1}
                maxValue={60 * 8}
                value={length}
                onChange={onSetLength}
                label="Duration"
                append={(
                  <InputGroup.Text>minutes</InputGroup.Text>
                )}
              />
            </Col>
            <Col sm={12}>
              <EditorControls.WysiwygEditor {...commentProps} />
            </Col>
          </Row>

        </Tab>
        <Tab title="CV" eventKey="CV">
          {data.people_id && (
            <SmallEditor id={String(data.people_id)} />
          )}
        </Tab>
        <Tab title="Comments" eventKey="Comments">
          {data.people_id && (
            <Comments peopleId={data.people_id} />
          )}
        </Tab>
        <Tab title="Files" eventKey="Files">
          {data.people_id && (
            <Files peopleId={data.people_id} />
          )}
        </Tab>
      </Tabs>
    </Container>
  );
}

InterviewForm.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  peopleId: PropTypes.string,
};

InterviewForm.defaultProps = {
  onSave: null,
  onClose: null,
  peopleId: null,
};

export default memo(InterviewForm);
