import React, { useCallback, useId } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export const DropZone = styled.div`
  text-align: center;
  margin: 10px 0;
  padding: 10px;
  background: ${({ errored }) => (errored ? 'red' : '#b6c3d4')};
  // border: 4px dashed red;
  border-radius: 10px;
  // min-height: 100px;
  height: ${({ minHeight }) => (minHeight ? '260px' : '70vh')};
  display: flex;
  flex-direction: column;
`;

function FileUpload({
  multiple, onFileUpload, errored, children, className, accept,
}) {
  const fileLoadHandler = useCallback(
    (e) => {
      const { files } = e.dataTransfer;
      e.preventDefault();
      if (!multiple) {
        const item = files[0];
        if (item && item.type) {
          onFileUpload(e, item);
        }
      } else {
        const d = [...files].filter((f) => !!f.type);
        onFileUpload(e, d);
      }
    },
    [multiple, onFileUpload],
  );

  const fileSelectHandler = useCallback(
    (e) => {
      const { files } = e.target;
      if (!multiple) {
        const file = files[0];
        onFileUpload(e, file);
      } else {
        onFileUpload(e, [...files]);
      }
    },
    [multiple, onFileUpload],
  );
  const fileId = useId();
  return (
    <div
      className={classNames(
        'text-center mt-3 p-3 rounded d-flex flex-column ',
        className,
        { 'bg-secondary': !errored, 'bg-danger': errored },
      )}
      onDragOver={(e) => e.preventDefault()}
      onDrop={fileLoadHandler}
    >
      <div>
        <Form.Group>
          <Form.Label className="btn btn-secondary" htmlFor={fileId}>
            <FontAwesomeIcon icon={faFileUpload} className="me-2" />
            Upload File
          </Form.Label>
          <Form.Control id={fileId} type="file" onChange={fileSelectHandler} multiple={multiple} className="d-none" accept={accept} />
        </Form.Group>
      </div>
      {children}
    </div>
  );
}
FileUpload.propTypes = {
  multiple: PropTypes.bool,
  onFileUpload: PropTypes.func.isRequired,
  errored: PropTypes.bool,
  className: PropTypes.string,
  accept: PropTypes.string,
};

FileUpload.defaultProps = {
  multiple: false,
  errored: false,
  className: '',
  accept: '',
};

export default FileUpload;
