import { useStringInputProps } from './stringHook';
import { useDateInputProps } from './dateHook';
import { useNumberInputProps } from './numberHook';
import { useSelectorInputProps } from './selectorHook';
import { useCheckboxInputProps } from './checkboxHook';
import { useTextInputProps } from './textHook';
import { useItemInputProps } from './itemHook';

const tableHooks = {
  useStringInputProps,
  useDateInputProps,
  useNumberInputProps,
  useSelectorInputProps,
  useCheckboxInputProps,
  useTextInputProps,
  useItemInputProps,
};

export default tableHooks;
