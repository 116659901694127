import React from 'react';
import { Alert } from 'react-bootstrap';
import TaskItem from '../tasks/list/TaskItem';
import useSubscribed from './hook';
import LoaderContainer from './LoaderContainer';

const getReqestOptions = (currentUser) => ({
  executor: currentUser.id,
  closed: false,
});

function SubscribedTasks() {
  const {
    items, err, loading, setErr, fields,
    prevPageHandler, nextPageHandler, canNext, canPrev,
  } = useSubscribed({
    uri: '/api/tasks/',
    optionsGetter: getReqestOptions,
  });
  return (
    <div className="rounded border p-2">
      <LoaderContainer
        err={err}
        loading={loading}
        setErr={setErr}
        onPrevPage={prevPageHandler}
        onNextPage={nextPageHandler}
        canNext={canNext}
        canPrev={canPrev}
      >
        {items.length
          ? items.map((row) => (
            <TaskItem key={row.id} task={row} fields={fields} />
          ))
          : (
            <Alert variant="info">
              You have no opened tasks.
            </Alert>
          )}
      </LoaderContainer>
    </div>
  );
}

export default SubscribedTasks;
