import { useTableBaseInputProps } from './baseInputProps';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { number } rowIndex - rowIndex
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  value: string,
 *  required: boolean,
 *  errors: Array}}
 */
export const useDateInputProps = (
  name,
  data,
  fields,
  errors,
  onChange,
  rowIndex,
  isReadOnly = false,
  readOnlyFelds = [],
) => useTableBaseInputProps(
  name,
  data,
  fields,
  errors,
  onChange,
  rowIndex,
  isReadOnly,
  readOnlyFelds,
);

export default useDateInputProps;
