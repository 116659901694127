import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { getDisplayName } from '../../api/utils';

const ErrContainer = Styled.div`
  background: #ffffbb;
  font-weight: 400;
  border: 1px solid #a2b4e8;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  border-radius: 0.3rem;
  padding: 8px;
  color: red;
  width: fit-content;
`;

const ErrText = Styled.div`
  
`;

function withErrors(WrappedComponent) {
  function WithErrors({ errors, ...rest }) {
    return (
      <div className="w-100 h-100">
        {errors && (
        <ErrContainer>
          {errors.map((err, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <ErrText key={key}>{err}</ErrText>
          ))}
        </ErrContainer>
        )}
        <WrappedComponent {...rest} errored={!!errors} />
      </div>
    );
  }
  WithErrors.displayName = `${getDisplayName(WrappedComponent)} wrapped by withErrors`;
  return WithErrors;
}

withErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

withErrors.defauptProps = {
  errors: null,
};

export default withErrors;
