/* eslint-disable no-unused-vars */
import React, {
  createRef,
  forwardRef, memo,
} from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import FormRange from 'react-bootstrap/FormRange';

const RangeInput = forwardRef(({
  value, disabled, readOnly, size, onChange, id,
  errors, className, required, errorAsTooltip,
}, oldRef) => {
  const ref = oldRef || createRef();
  const errored = errors && !!errors.length;

  return (
    <>
      {/* <InputGroup className={classNames(className, { required, 'is-invalid': errored })} size={size || 'sm'}> */}
      <FormRange
        ref={ref}
        id={id}
        // isInvalid={errored}
        // isValid={false}
        // value={value}
        // onChange={onChange}
        // disabled={disabled || readOnly}
      />
      {errors && (
        <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </Form.Control.Feedback>
      )}
      {/* </InputGroup> */}
    </>
  );
});

RangeInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  className: PropTypes.string,
  errorAsTooltip: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

RangeInput.defaultProps = {
  id: '',
  value: '',
  disabled: false,
  readOnly: false,
  required: false,
  className: '',
  size: 'sm',
  errorAsTooltip: false,
  errors: [],
};

export default memo(RangeInput);
