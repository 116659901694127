import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home';

import Peoples from '../../containers/peoples';
import Jobs from '../../containers/jobs';
import Interviews from '../../containers/interview';
import Tasks from '../../containers/tasks';
// import UserEditor from '../../containers/UserEditor';
import Manuals from '../../containers/manuals';

import SettingsPage from './settings';
import ReportsPage from './reports';

import Catalogs from '../../containers/Catalogs';
import Reports from '../../containers/Reports';
import MainLayout from './mainLayout';
// import Error404 from '../errors/Error404';
function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Home />} />

        <Route path="/candidates" element={<Peoples.List />} />
        <Route path="/candidates/:id" element={<Peoples.RouterEditor />} />

        <Route path="/jobs" element={<Jobs.List />} />
        <Route path="/jobs/:id" element={<Jobs.RouterEditor />} />

        <Route path="/interviews" element={<Interviews.List />} />
        <Route path="/interviews/:id" element={<Interviews.RouterEditor />} />

        <Route path="/tasks" element={<Tasks.List />} />
        <Route path="/tasks/:id" element={<Tasks.RouterEditor />} />

        <Route path="/manuals" element={<Manuals />} />

        <Route path="/settings/" element={<SettingsPage />} />

        <Route path="/reports/" element={<ReportsPage />} />

        {/* <Route path="/usersettings/" element={<UserEditor />} /> */}

        <Route path="/pool/" element={<Peoples.PoolPeopleList />} />

        {Object.values(Catalogs).map((cat) => (
          <Route key={cat.frontendURI} path={cat.frontendURI} element={<cat.List />} />
        ))}
        {Object.values(Catalogs).map((cat) => (
          <Route key={cat.frontendURI} path={`${cat.frontendURI}:id`} element={<cat.RouterEditor />} />
        ))}
        {Object.values(Reports).map((rep) => (
          <Route key={rep.url} path={`/reports/${rep.url}`} element={<rep.component />} />
        ))}

        {/* <Route path="*" element={<Error404 />} /> */}
      </Route>
    </Routes>
  );
}

export default AppRoutes;
