import React, {
  memo,
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../api/req';
import AppContext from '../../../../providers/authProvider';
import { JobSelector } from '../../../SimpleSelector';
import { SaveButton } from '../../../../components/Styled/Buttons';
import { useDict } from '../../../BaseLister/hook';
import Loader from '../../../../components/Styled/Misc/loader';

function AddToJob({ peopleId }) {
  const { auth, currentUser } = useContext(AppContext);
  const [currentjob, setCurrentJob] = useState(null);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const readOnly = currentUser.profile.role >= 10;

  const availableJobs = useDict({
    url: '/api/cat/jobs/',
    usePagination: true,
    queryParams: { status: 'O', page_size: 999 },
  });
  useEffect(() => {
    const loader = async () => {
      const r = await api.get(`/api/cat/peoples/${peopleId}/current_job/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => setCurrentJob(d.job || null))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, peopleId]);

  const addToJob = useCallback(
    () => {
      const loader = async () => {
        const r = await api.post(`/api/cat/peoples/${peopleId}/current_job/`, auth, { job });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      loader()
        .then((d) => setCurrentJob(d.job || null))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, job, peopleId],
  );

  if (readOnly && currentjob) {
    return (
      <div>
        This candidate used in job &nbsp;
        <b>{currentjob.repr}</b>
        .
      </div>
    );
  }
  if (readOnly) {
    return (
      <div>
        This candidate not used in any job.
      </div>
    );
  }
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faShip} className="me-2" />
          Current job
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        {loading && (
        <Loader relative />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}
        {currentjob ? (
          <div>
            This candidate used in job &nbsp;
            <Link to={`/jobs/${currentjob.id}/`}>
              <b>{currentjob.repr}</b>
              .
            </Link>
            <br />
            if you want add this candidate to another job - you must close this candidate in this job.
          </div>
        ) : (
          <div>
            Change job:
            <JobSelector
              required
              value={job}
              preloadedData={availableJobs.data}
              onChange={(e, v) => setJob(v)}
            />
            <div className="mt-2">
              <SaveButton
                content="Change"
                disabled={!job || job === (currentjob ? currentjob.id : null)}
                onClick={addToJob}
              />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
AddToJob.propTypes = {
  peopleId: PropTypes.number.isRequired, // people_id to add
};

export default memo(AddToJob);
