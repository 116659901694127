import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { frontendURI } from './uri';

function CheckListItem({ row }) {
  return (
    <Card>
      <Card.Header>
        <Link to={`${frontendURI}${row.id}/`} className="stretched-link text-decoration-none">
          <Card.Title>
            {row.repr}
          </Card.Title>
        </Link>
      </Card.Header>
    </Card>
  );
}

CheckListItem.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }).isRequired,
};

export default CheckListItem;
