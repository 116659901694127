import Styled from 'styled-components';

export const StyledContainer = Styled.div`
  display: flex;
  flex-direction: column;
  // padding: 5px;
  flex: 1 1 auto;
  &>*{
    flex: 1 1 auto;
  }
  // @media (max-width: 900px) {
  //   flex-direction: column;
  // }
`;

export const StyledList = Styled.div`
  background: white;
  // border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-height: 60px;
  // margin: 0 10px;
`;

export const StyledHeader = Styled.h3`
  color: green;
  font-weight: 700; 
  margin: 0 0 5px;
`;

export const StyledSubContainer = Styled.div`
  display: inline-block;
  height: 100%;
  min-height: 30px;
  background: whitesmoke;
`;

export const PeopleLabel = Styled.div.attrs(({ $bg_color: backgroundColor, $color: color }) => ({
  style: {
    backgroundColor,
    color,
  },
}))`
  display: inline-block;
  border-radius: 0.3rem;
  margin-bottom: 5px;
  margin-right: 5px;
    :last-child{
        margin: 0;
    };
  padding: 5px 10px;
`;

export const PositionLabel = Styled(PeopleLabel)`
  width: fit-content;
`;
