import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function AddContactHeader({
  fields,
}) {
  return (
    <Row className="fw-bold">
      <Col sm={6} className="text-center">
        {fields.type.label}
      </Col>
      <Col sm={5} className="text-center">
        {fields.contact.label}
      </Col>
      <Col />
    </Row>
  );
}

AddContactHeader.propTypes = {
  fields: PropTypes.shape({
    type: PropTypes.shape({
      label: PropTypes.string,
    }),
    contact: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default AddContactHeader;
