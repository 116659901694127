import styled from 'styled-components';

export const DivDropStyled = styled.div`
  max-height: 120px;
  overflow: auto;
  border-radius:.28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  padding: 5px;
  margin: 3px 0;
  position: absolute;
  z-index: 2;
  background: white;
  left: 0;
  right: 0;
`;

export const DivInnerDropStyled = styled.div`
  padding: 3px;
  ${({ disabled }) => disabled && 'filter: grayscale(1) opacity(.275);'}
  &:hover{
    background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  }
`;
