import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function DateTimeShow({ value, className }) {
  const v = useMemo(
    () => {
      const d = new Date(value);
      return d.toLocaleString('en-CY', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        hour12: true,
        minute: '2-digit',
      });
    },
    [value],
  );
  return <span className={className}>{v}</span>;
}

DateTimeShow.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DateTimeShow.defaultProps = {
  className: '',
};

export default DateTimeShow;
