import React from 'react';
import PropTypes from 'prop-types';
import { DefaultButton } from './defaultButton';

export function LoginButton({
  content, onClick, disabled, ...otherProps
}) {
  return (
    <DefaultButton onClick={onClick} disabled={disabled} {...otherProps}>
      {content}
    </DefaultButton>
  );
}

LoginButton.propTypes = {
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

LoginButton.defaultProps = {
  onClick: () => null,
  disabled: false,
};

export default LoginButton;
