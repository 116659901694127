import React from 'react';
import { Alert } from 'react-bootstrap';
import InterviewItem from '../interview/list/InterviewItem';
import { interviewStatuses } from '../../const/enums';
import useSubscribed from './hook';
import LoaderContainer from './LoaderContainer';

const getReqestOptions = (currentUser) => ({
  executor: currentUser.id,
  status__in: `${interviewStatuses.SCHEDULED}`,
});

function SubscribedInterviews() {
  const {
    items, err, loading, setErr, fields,
    prevPageHandler, nextPageHandler, canNext, canPrev,
  } = useSubscribed({
    uri: '/api/interviews/',
    optionsGetter: getReqestOptions,
  });
  return (
    <div className="rounded border p-2">
      <LoaderContainer
        err={err}
        loading={loading}
        setErr={setErr}
        onPrevPage={prevPageHandler}
        onNextPage={nextPageHandler}
        canNext={canNext}
        canPrev={canPrev}
      >
        {items.length
          ? items.map((row) => (
            <InterviewItem key={row.id} mode="byPeople" interveiw={row} fields={fields} />
          ))
          : (
            <Alert variant="info">
              You have no planed interviews.
            </Alert>
          )}
      </LoaderContainer>
    </div>
  );
}
export default SubscribedInterviews;
