import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container, ListGroup, ListGroupItem,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { debounce } from '../../../../api/utils';
import useEditor from '../../../BasicEditor/hook/editor';
import { EditorControls } from '../../../BasicEditor/editorControls';
import DateShow from '../../../../components/Controls/dates/dateShow';
import EditorContainer from '../../../BasicEditor/editorContainer';

const StyledCheckBox = styled(EditorControls.CheckboxInput)`
    label{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
`;
function ChecklistShower({ id, onSetEditMode }) {
  const {
    data, systemErrors, loading,
    fields, nonFieldErrors, fieldErrors, actions,
  } = useEditor({
    id,
    backendURL: '/api/checklists/',
  });
  const { rawSave, onChange } = actions;
  const lazySave = useMemo(() => debounce(rawSave, 1000), [rawSave]);
  const columns = useMemo(
    () => {
      if (!data.template) return [];
      return data.template.details
        .filter((td) => !!data.details.filter((d) => d.template_detail === td.id && d.required).length);
    },
    [data],
  );
  const dataColumns = useMemo(
    () => columns.reduce((R, c) => ({
      ...R,
      [c.id]: data.details.filter((d) => d.template_detail === c.id).reduce((R1, r1) => r1, {}),
    }), {}),
    [columns, data.details],
  );
  const onCheckItem = useCallback(
    (itemId, v) => {
      const newData = {
        ...data,
        details: data.details.map((d) => (d.template_detail === itemId ? { ...d, checked: v } : d)),
      };
      onChange(newData);
      lazySave(newData);
    },
    [data, lazySave, onChange],
  );
  return (
    <Container fluid className="px-0 h-100 overflow-hidden">
      <EditorContainer
        title={(
          <div className="d-flex align-items-baseline">
            <span className="me-2">Joining date:</span>
            <DateShow value={data.landing_date} />
            <Button
              onClick={onSetEditMode}
              variant="link"
              className="ms-auto"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        )}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        <ListGroup className="small" variant="flush">
          {columns.map((c) => (
            <ListGroupItem key={c.id} action className="p-0">
              <StyledCheckBox
                value={dataColumns[c.id].checked}
                onChange={(e, v) => onCheckItem(c.id, v)}
                // label={(<span className="text-nowrap text-truncate">{c.name}</span>)}
                title={c.name}
                label={c.name}
              />
            </ListGroupItem>
          ))}
        </ListGroup>
      </EditorContainer>
    </Container>

  );
}
ChecklistShower.propTypes = {
  id: PropTypes.number.isRequired,
  onSetEditMode: PropTypes.func.isRequired,
};

export default ChecklistShower;
