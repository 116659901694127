import styled from 'styled-components';

export const Label = styled.label`
  position: relative;
  color: #605b5b;
  font-weight: bold;
`;

export const MainLabel = styled(Label)`
  border: 1px solid #b6b6b6;
  border-bottom: none;
  border-radius: 0.3rem 0.3rem 0 0;
  padding: 5px;
  background: #5e789b;
  color: white;
  display: inline-block;
`;

// eslint-disable-next-line
export default Label;
