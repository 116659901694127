import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Overlay } from 'react-bootstrap';
import { Checkbox } from '../../../components/Controls';

export function TableCheckbox({
  controlId, label, required, errors, description, ...restProps
}) {
  const controlRef = useRef(null);
  const [showAlert, setShowAlert] = useState(true);
  return (
    <Form.Group controlId={controlId}>
      <Checkbox
        {...restProps}
        id={controlId}
        label={label}
        ref={controlRef}
        errored={errors && !!errors.length}
      />
      <Overlay target={controlRef.current} show={!!errors && showAlert} placement="auto">
        {() => (

          <Alert variant="danger" dismissible onClose={() => setShowAlert(false)}>
            <p className="mb-0">
              {String(errors)}
            </p>
          </Alert>
          // <ErroredTooltip id={`error-${controlId}`} {...props}>
          //   {String(errors)}
          // </ErroredTooltip>
        )}
      </Overlay>
      {description && (
        <Form.Text className="text-muted">
          {description}
        </Form.Text>
      )}
    </Form.Group>
  );
}

TableCheckbox.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
};

TableCheckbox.defaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
};

export default TableCheckbox;
