/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useTablePart from '../../../BasicEditor/hook/tablepart';
import AddContactHeader from './header';
import AddContactRow from './row';

function AddContacts({
  contactInfoTypes, onChange, data, fields, errors,
}) {
  const {
    tableData, tableActions,
    tableContainerRef, tableErrors,
    tableFields,
  } = useTablePart({
    data, onChange, tableName: 'AdditionalContacts', errors, fields,
  });
  return (
    <Card className="border-0 ">
      <Card.Header>
        <AddContactHeader fields={tableFields} />
      </Card.Header>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, rowIndex) => (
          <AddContactRow
            row={row}
            key={rowIndex}
            errors={tableErrors[rowIndex]}
            contactInfoTypes={contactInfoTypes}
            fields={tableFields}
            onChange={tableActions.onCellChange}
            onDeleteRow={tableActions.onDeleteRow}
            index={rowIndex}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Button variant="outline-primary" onClick={() => tableActions.onAddRow()}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add contact
        </Button>
      </Card.Footer>
    </Card>
  );
}

AddContacts.propTypes = {
  data: PropTypes.shape({
  }),
  contactInfoTypes: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

AddContacts.defaultProps = {
  data: [],
};

export default AddContacts;
