import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import Report from './interviews';

const ReportInfo = {
  component: Report,
  url: 'interviews',
  icon: faHeadphones,
  tittle: 'Interviews',
  description: 'Details of planed interviews on selected period',
};

export default ReportInfo;
