import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function LanguageHeader({
  fields,
}) {
  return (
    <Row className="fw-bold">
      <Col sm={6} className="text-center">
        {fields.language.label}
      </Col>
      <Col sm={5} className="text-center">
        {fields.skill.label}
      </Col>
      <Col />
    </Row>
  );
}

LanguageHeader.propTypes = {
  fields: PropTypes.shape({
    language: PropTypes.shape({
      label: PropTypes.string,
    }),
    skill: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default LanguageHeader;
