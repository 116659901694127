import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faBuilding,
  faCheckCircle,
  faClock,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { interviewStatuses } from '../../../const/enums';

function InterviewItem({
  interveiw, date, short,
}) {
  const peopleName = useMemo(
    () => {
      if (interveiw.people) return `${interveiw.people.name} ${interveiw.people.family_name}`;
      return '';
    },
    [interveiw.people],
  );
  const companyName = useMemo(
    () => {
      if (interveiw.people && interveiw.people.company) return interveiw.people.company.repr;
      return '';
    },
    [interveiw.people],
  );

  const strPositions = useMemo(
    () => (interveiw.people ? interveiw.people.positions.map((p) => p.repr).join(', ') : ''),
    [interveiw.people],
  );

  const sDate = useMemo(
    () => {
      const d = new Date(date || interveiw.start);
      return d.toLocaleString('en-CY', {
        day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit',
      });
    },
    [date, interveiw.start],
  );
  const strAuthor = useMemo(
    () => {
      if (interveiw.author) {
        if (interveiw.author.first_name || interveiw.author.last_name) return `${interveiw.author.first_name} ${interveiw.author.last_name}`;
        return interveiw.author.username;
      }
      return '';
    },
    [interveiw.author],
  );

  const statusIcon = useMemo(
    () => {
      switch (interveiw.status) {
        case interviewStatuses.SCHEDULED:
          return faClock;
        case interviewStatuses.CANCELED:
          return faTimesCircle;
        case interviewStatuses.EXECUTED:
          return faCheckCircle;
        default:
          return faAsterisk;
      }
    },
    [interveiw.status],
  );

  return (
    <Card className="_h-100 text-decoration-none" as={Link} to={`/interviews/${interveiw.id}`}>
      <Card.Header className={classNames('text-white', {
        'bg-secondary': interveiw.status === interviewStatuses.SCHEDULED,
        'bg-danger': interveiw.status === interviewStatuses.CANCELED,
        'bg-success': interveiw.status === interviewStatuses.EXECUTED,
      })}
      >
        <Card.Title className="h5 text-decoration-none">
          <FontAwesomeIcon icon={statusIcon} className="me-2" />
          {peopleName}
        </Card.Title>
      </Card.Header>
      {!short && (
        <>
          <Card.Body>
            <Row>
              <Col md={3}>
                <img src={interveiw.people ? interveiw.people.photo : ''} alt={peopleName} className="w-100" />
              </Col>
              <Col>
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faClock} className="me-1 text-muted" alt="Date and time" />
                    <b>{sDate}</b>
                  </li>
                  {companyName && (
                  <li>
                    <FontAwesomeIcon icon={faAsterisk} className="me-1 text-muted" size="sm" alt="Company" />
                    <b>{companyName}</b>
                  </li>
                  )}
                  <li>
                    <FontAwesomeIcon icon={faBuilding} className="me-1 text-muted" size="sm" alt="Position(s)" />
                    <b>{strPositions}</b>
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            Scheduled by
            {' '}
            {strAuthor}
          </Card.Footer>
        </>
      )}
    </Card>
  );
}

InterviewItem.propTypes = {
  interveiw: PropTypes.shape({
    start: PropTypes.string,
    comment: PropTypes.string,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string,
    }),
    executor: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string,
    }),
  }).isRequired,
  date: PropTypes.string,
  short: PropTypes.bool,
};

InterviewItem.defaultProps = {
  date: null,
  short: false,
};
export default InterviewItem;
