import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormCheck } from 'react-bootstrap';

const Check = styled(FormCheck)`
  label {
    cursor: pointer;
  }
`;

const ChekboxInput = forwardRef(({
  value, onChange, disabled, label, id, errored, readOnly, title,
}, ref) => (
  <Check
    ref={ref}
    type="switch"
    checked={value}
    disabled={disabled || readOnly}
    label={label}
    id={id}
    onChange={(e) => onChange(e, !value)}
    isInvalid={errored}
    title={title}
  />
));

ChekboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  errored: PropTypes.bool,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
};

ChekboxInput.defaultProps = {
  disabled: null,
  label: null,
  value: false,
  errored: false,
  readOnly: false,
  title: null,
};

export default ChekboxInput;
