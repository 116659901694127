import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import api from '../../../api/req';
import AppContext from '../../../providers/authProvider';

export const HEADER_KEY = '__HEADER__';

function ItemSaveException(message) {
  this.message = message;
  this.name = 'Item Save Exception';
}

function buildErrors({
  non_field_errors: nonFieldErrors,
  executing_errors: executingErrors,
  ...errorData
}, fields) {
  const getPlainErrors = (ers, flds, prefix = '') => Object.keys(ers).reduce((R, fldName) => {
    if (Array.isArray(ers[fldName])) {
      return [
        ...R,
        ...ers[fldName].map((fldErr) => `${prefix} ${fldName in flds ? flds[fldName].label : fldName}: ${fldErr}`),
      ];
    }
    if (typeof ers[fldName] === 'object' && ers[fldName] !== null) {
      return [
        ...R,
        ...Object.entries(ers[fldName]).map(([key, value]) => (
          value.map((v) => `${flds[fldName]?.children[key] ? flds[fldName].children[key].label : key}: ${v}`)
        )),
      ];
    }
    return [...R, `${prefix} ${flds[fldName]?.label}: ${ers[fldName]}`];
  }, []);
  const headerFields = Object.keys(fields)
    .filter((f) => !fields[f].child)
    .reduce((R, f) => ({ ...R, [f]: fields[f] }), {});
  const headerErrors = Object.keys(errorData)
    .filter((f) => f in headerFields)
    .reduce((R, f) => ({ ...R, [f]: errorData[f] }), {});

  const tpFields = Object.keys(fields)
    .filter((f) => fields[f].child)
    .reduce((R, f) => ({ ...R, [f]: fields[f].child.children }), {});

  const tpErrors = Object.keys(errorData)
    .filter((f) => f in tpFields)
    .reduce((R, f) => ({ ...R, [f]: errorData[f] }), {});

  const plainErrors = [
    ...getPlainErrors(headerErrors, headerFields),
    ...Object.keys(tpErrors)
      .reduce((R, tpName) => [
        ...R,
        ...tpErrors[tpName]
          .map((rowErr, i) => ({ errors: rowErr, rowN: i + 1 }))
          .filter((row) => !!Object.keys(row.errors))
          .reduce((R2, row) => [
            ...R,
            ...getPlainErrors(row.errors, tpFields[tpName], `${fields[tpName].label}, рядок №${row.rowN},`),
          ], []),
      ], []),
  ];

  const nfe = [
    ...nonFieldErrors || [],
    ...executingErrors || [],
    ...plainErrors,
  ];

  return {
    fields: errorData,
    nonFieldErrors: nfe.length ? nfe : null,
  };
}

/**
 * @param editorParams Параметры hook редактора
*  @param editorParams.backendURL {string} тип модели
*  @param editorParams.id {string} - ИД,
*  @param editorParams.reason {string} - Основание (при вводе на основании),
*  @param editorParams.isGroup {boolean} - Это группа (при создании группы),
*  @param editorParams.copyFrom {string} - Ид копируемого элемента,
*  @param editorParams.onSaveCallBack (function) - callback при сохранении документа,
*  @param editorParams.onCloseCallBack (function) - callback при закрытии документа,
*  @param editorParams.defaults {{}} - Значения по умолчанию  для нового объекта,
*  @param editorParams.readOnlyGetter {function(@param data {})} - функция,
*                  которая вычисляет значение аттрибута readOnly,
* }}
 *
 * @returns {{
 *   data: {},
 *   fields: {},
 * options: {},
 * fieldErrors: {},
 * nonFieldErrors: {},
 * systemErrors: string,
 * loading: boolean,
 * changed: boolean,
 * isNew: boolean,
 * actions: {
 *    onReload: (function(): void),
 *    onChange: (function(): void),
 *    onSave: (function(): void),
 *    onUndo: (function(): void),
 *    onRedo: (function(): void),
 *    onErr: (function(): void),
 *    onLoading: (function(): void),
 *    onClearErrs: (function(): void),
 *    onClearNonFieldErrors: (function(): void),
 *    onDelete: (function(): void),
 *    rawSave: (function(): void),
 * },
 * permissions: {
 *    canSave: boolean,
 *    canUndo: boolean,
 *    canRedo: boolean,
 *    canClose: boolean,
 *    canExecute: boolean,
 *    canUnexecute: boolean,
 *    canChange: boolean,
 },
 * }}
 */
const useEditor = (editorParams) => {
  const defaultParams = useMemo(
    () => ({
      id: null,
      reason: '',
      copyFrom: '', // id документа, который копируется
      isGroup: false,
      onSaveCallBack: null, // callback при сохранении документа
      onCloseCallBack: null, // callback при выходе
      defaults: {}, // Значения по умолчанию  для нового объекта
      readOnlyGetter: null, // функция, которая вычисляет значение аттрибута readOnly
    }),
    [],
  );

  const {
    backendURL,
    id,
    reason,
    isGroup,
    copyFrom,
    onSaveCallBack, // callback при сохранении документа
    onCloseCallBack, // callback при выходе
    defaults, // Значения по умолчанию  для нового объекта
    // readOnlyGetter, // функция, которая вычисляет значение аттрибута readOnly
  } = useMemo(
    () => ({ ...defaultParams, ...editorParams }),
    [defaultParams, editorParams],
  );

  const { auth } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
  });
  const [fields, setFields] = useState(null);
  const [options, setOptions] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [saveListeners, setSaveListeners] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [nonFieldErrors, setNonFieldErrors] = useState(null);

  currentData.current = data.current;

  const isNew = id === 'create' || id === 'createGroup';

  const [changed, setChanged] = useState(false);

  const loadData = useCallback(
    async (itemId) => {
      setErr(null);
      const r = await api.get$(`${backendURL}${itemId}/ `, auth);
      if (!r.ok) {
        let e;
        try {
          e = await r.text();
        } catch {
          e = `${r.status} ${r.statusText}`;
        }
        throw new Error(e);
      }
      const d = await r.json();
      setData({
        current: d,
        history: {
          data: [d],
          pointer: null,
        },
      });
      setChanged(false);
      return d;
    },
    [auth, backendURL],
  );

  const loadOptions = useCallback(
    async (itemId) => {
      const url = `${backendURL}${itemId}/`; // isNew ? `${backendURL}0/` : `${backendURL}${itemId}/`;
      const r = await api.options(url, auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const o = await r.json();
      setOptions({
        name: o.name,
        description: o.description,
      });
      const saveMethod = isNew ? 'GET' : 'PUT';
      const rOnly = !(saveMethod in o.actions);
      setReadOnly(rOnly);
      setFields(rOnly ? o.actions.GET : o.actions[saveMethod]);
      return rOnly;
    },
    [auth, backendURL, isNew],
  );

  const loadNew = useCallback(
    async () => {
      const url = `${backendURL}get_new/`; // isNew ? `${backendURL}0/` : `${backendURL}${itemId}/`;
      const r = await api.options(url, auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const d = await r.json();
      setData({
        current: d,
        history: {
          data: [d],
          pointer: null,
        },
      });
      setChanged(false);
      return d;
    },
    [auth, backendURL],
  );

  const onChange = useCallback(
    /**
     *
     * @param partOfData {{}, function }
     */
    async (partOfData) => {
      if (!readOnly) {
        setChanged(true);
        const p = typeof partOfData === 'function' ? partOfData(currentData.current) : partOfData;
        const newCurrent = { ...currentData.current, ...p };
        const hasChange = Object.keys(newCurrent)
          .reduce((Ch, k) => Ch || newCurrent[k] !== currentData.current[k], false);
        setData(({ history }) => {
          const newHData = history.pointer === null
            ? [...history.data, newCurrent]
            : [...history.data.slice(0, history.pointer + 1), newCurrent];
          return ({
            current: newCurrent,
            history: hasChange ? {
              data: newHData,
              pointer: null,
            } : history,
          });
        });
      }
    },
    [readOnly],
  );

  const initAddParams = useMemo(
    () => {
      if (!isNew) return {};
      return {
        is_group: isGroup,
        copy_from: copyFrom,
        reason,
        defaults,
      };
    },
    [isNew, isGroup, copyFrom, reason, defaults],
  );
  const onReload = useCallback(
    () => {
      setLoading(true);
      (isNew ? loadNew() : loadData(id))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [id, isNew, loadData, loadNew],
  );

  const save = useCallback(
    async (savableData) => {
      setLoading(true);
      setErr(null);
      setFieldErrors({});
      setNonFieldErrors(null);
      const r = isNew
        ? await api.post(backendURL, auth, savableData)
        : await api.put(`${backendURL}${id}/`, auth, savableData);
      setLoading(false);
      if (!r.ok && r.status !== 400) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const d = await r.json();
      const errData = r.status === 400 ? d : null;
      const exts = await Promise.allSettled(saveListeners.map((l) => l(d)));
      const errExts = exts.filter((er) => er.status === 'rejected');
      if (errData || errExts.length) {
        const errors = buildErrors(errData, fields);
        setNonFieldErrors(errors.nonFieldErrors || null);
        setFieldErrors(errors.fields || null);

        throw new ItemSaveException(`${r.status} ${r.statusText}`);
      }
      setChanged(false);
      if (!isNew) onReload();
      // setData({ current: d, history: { data: [], pointer: null } });
      return d;
    },
    [auth, backendURL, fields, id, isNew, onReload, saveListeners],
  );

  const undo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer === null) {
          if (history.data.length > 1) {
            return ({
              current: history.data[history.data.length - 2],
              history: {
                data: history.data,
                pointer: history.data.length - 2,
              },
            });
          }
        } else if (history.pointer > 0) {
          return ({
            current: history.data[history.pointer - 1],
            history: {
              data: history.data,
              pointer: history.pointer - 1,
            },
          });
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const redo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer !== null) {
          if (history.data.length > history.pointer + 1) {
            return ({
              current: history.data[history.pointer + 1],
              history: {
                data: history.data,
                pointer: history.data.length > history.pointer + 2
                  ? history.pointer + 1
                  : null,
              },
            });
          }
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const onSave = useCallback(
    () => {
      save(data.current)
        .then((d) => {
          if (onSaveCallBack) onSaveCallBack(d);
        })
        .catch((e) => {
          if (!(e instanceof ItemSaveException)) setErr(e.message);
        });
    },
    [data, onSaveCallBack, save],
  );
  useEffect(
    () => {
      setLoading(true);
      loadOptions(id)
        .then(() => {
          if (isNew) {
            return loadNew();
          }
          return loadData(id);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [id, initAddParams, isNew, loadData, loadNew, loadOptions],
  );

  const permissions = useMemo(
    () => ({
      canSave: !readOnly,
      canUndo: !readOnly && data.history.pointer !== 0 && data.history.data.length > 1,
      canRedo: !readOnly && data.history.pointer !== null
          && data.history.pointer < data.history.data.length,
      canClose: !!onCloseCallBack,
      canChange: !readOnly,
    }),
    [data.history.data.length, data.history.pointer, onCloseCallBack, readOnly],
  );

  const onClearErrs = useCallback(
    () => setErr(null),
    [],
  );
  const onClearNonFieldErrors = useCallback(
    () => setNonFieldErrors(null),
    [],
  );

  const registerSaveListener = useCallback(
    (f) => setSaveListeners((o) => {
      if (o.includes(f)) return o;
      return [...o, f];
    }),
    [],
  );
  const onDelete = useCallback(
    () => {
      const loader = async () => {
        const r = await api.delete(`${backendURL}${id}/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return true;
      };
      setLoading(true);
      setErr(null);
      loader()
        .then(() => {
          if (onCloseCallBack) onCloseCallBack();
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    },
    [auth, backendURL, id, onCloseCallBack],
  );

  const actions = useMemo(
    () => ({
      onReload,
      onChange,
      onSave,
      onRedo: redo,
      onUndo: undo,
      onErr: setErr,
      onLoading: setLoading,
      onDelete,
      onClearErrs,
      onClearNonFieldErrors,
      registerSaveListener,
      rawSave: save,
    }),
    [onChange, onClearErrs, onClearNonFieldErrors, onDelete, onReload, onSave, redo, registerSaveListener, save, undo],
  );

  return {
    data: Object.keys(data.current).length !== 0 ? {
      ...data.current,
      fieldErrors,
    } : data.current,
    fields,
    options,
    fieldErrors,
    nonFieldErrors,
    loading,
    systemErrors: err,
    changed,
    permissions,
    actions,
    isNew,
    readOnly,
  };
};

export default useEditor;
