import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export function DeleteButton({ content, onClick, disabled }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="danger" onClick={() => setShow(true)} disabled={disabled}>
        {content}
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete this?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              onClick();
              setShow(false);
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Yes
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShow(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DeleteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
  onClick: () => null,
  content: 'Delete',
  disabled: false,
};

export default DeleteButton;
