import React from 'react';
import PropTypes from 'prop-types';
import SimpleSelector from './simpleSelector';
import { withErrors } from '../../components/Controls';

const allPropTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  preloadedData: PropTypes.arrayOf(PropTypes.shape()),
};

const allDefaultProps = {
  value: null,
  required: false,
  disabled: false,
  readOnly: false,
  preloadedData: null,
};

const SimpleSelectorWithErrors = withErrors(SimpleSelector);

export function CountrySelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/country/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
CountrySelector.propTypes = allPropTypes;
CountrySelector.defaultProps = allDefaultProps;

export function ContactInfoTypeSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/contact_info_type/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
ContactInfoTypeSelector.propTypes = allPropTypes;
ContactInfoTypeSelector.defaultProps = allDefaultProps;

export function PositionSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/position/"
      dataMapper={({
        id, repr, color, bg_color: bgColor,
      }) => ({
        value: id, display_name: repr, color, bg_color: bgColor,
      })}
      {...props}
    />
  );
}
PositionSelector.propTypes = allPropTypes;
PositionSelector.defaultProps = allDefaultProps;

export function LanguageSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/language/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
LanguageSelector.propTypes = allPropTypes;
LanguageSelector.defaultProps = allDefaultProps;

export function CompanySelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/companies/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
CompanySelector.propTypes = allPropTypes;
CompanySelector.defaultProps = allDefaultProps;

export function JobSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/jobs/"
      dataMapper={(d) => ({ value: d.id, display_name: d.name })}
      {...props}
    />
  );
}
JobSelector.propTypes = allPropTypes;
JobSelector.defaultProps = allDefaultProps;

export function WFStatusSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/workflows/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
WFStatusSelector.propTypes = allPropTypes;
WFStatusSelector.defaultProps = allDefaultProps;

export function UserSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/core/user/"
      dataMapper={(d) => ({ value: d.id, display_name: d.repr })}
      {...props}
    />
  );
}
UserSelector.propTypes = allPropTypes;
UserSelector.defaultProps = allDefaultProps;

export function ChecklistTemplateSelector(props) {
  return (
    <SimpleSelectorWithErrors
      URI="/api/cat/checklist_templates/"
      dataMapper={(d) => ({ value: d.id, display_name: d.name })}
      {...props}
    />
  );
}
ChecklistTemplateSelector.propTypes = allPropTypes;
ChecklistTemplateSelector.defaultProps = allDefaultProps;
