import React from 'react';
import { Alert, Badge } from 'react-bootstrap';

function UnsupportedViewer({ fileType }) {
  return (
    <div className="h-100 w-100 m-auto overflow-auto">
      <Alert variant="danger">
        <Alert.Heading>Format not supported</Alert.Heading>
        Unfortunately,
        <Badge bg="info" className="mx-2">{fileType}</Badge>
        is not supported :=(
      </Alert>
    </div>
  );
}

export default UnsupportedViewer;
