import React, { useMemo } from 'react';
import { AppContext } from './context';
import { childrenPropType } from '../../const/propTypes';

function AnonimousAppContextProvider({
  children,

}) {
  const appContextValue = useMemo(
    () => ({
      currentUser: null,
      logoutHandler: () => null,
      refreshHandler: () => null,
      refreshCurrentUser: () => null,
      auth: () => null,
    }),
    [],
  );

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
}

AnonimousAppContextProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default AnonimousAppContextProvider;
