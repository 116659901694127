import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spinner } from 'react-bootstrap';
import { childrenPropType } from '../../const/propTypes';
import { Paginator } from '../../components/Styled/Misc';

function LoaderContainer({
  loading, err, setErr, children,
  onNextPage, onPrevPage, canNext, canPrev,
}) {
  return (
    <>
      {loading && (
        <div className="position-absolute top-50 start-50 translate-middle z-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {err && (
        <Alert variant="danger" dismissible={!!setErr} onClose={() => setErr(null)}>
          {err}
        </Alert>
      )}
      {children}
      {(canNext || canPrev) && (
      <Paginator
        onNextPage={canNext ? onNextPage : null}
        onPrevPage={canPrev ? onPrevPage : null}
      />
      )}
    </>
  );
}

LoaderContainer.propTypes = {
  loading: PropTypes.bool,
  err: PropTypes.string,
  setErr: PropTypes.func,
  children: childrenPropType,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  canNext: PropTypes.bool,
  canPrev: PropTypes.bool,
};

LoaderContainer.defaultProps = {
  loading: false,
  err: null,
  setErr: null,
  children: null,
  onNextPage: null,
  onPrevPage: null,
  canNext: false,
  canPrev: false,
};

export default LoaderContainer;
