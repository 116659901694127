import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledContainer = Styled.div`
  display: flex;
  align-items: center;
  // border: 1px solid #DDE2E9;
  border: 1px solid #909dc1;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.3rem;
  padding: 10px;
  margin-bottom: 5px
  &:hover{
    border: 1px solid #37a0a7;
    box-shadow: 0 0 20px rgba(0,0,0,0.16);
  };
`;

const StyledContainer2 = Styled.div`
  margin-left: 10px;
  flex: 1 1 auto;
`;

const StyledIcon = Styled.div`
  &>* {
    height: 40px;
  }
`;

const StyledHeader = Styled.div`
  // color: #008F21;
  color: #488de4;
  font-family: 'Roboto', sans-serif;
  // margin-bottom: 10px;
  font-size: 1.05em;
   &>*{
    // color: #3f51b4;
    color: #00b5ad;
    font-weight: 700;
     &:hover{
       // color: #103278;
       color: #0c857f;
     };
   }
`;

const StyledContent = Styled.div`
  font-size: 0.8em;
  font-style: italic;
  color: grey;
`;

function Item({
  header, icon, content, className,
}) {
  return (
    <StyledContainer className={className}>
      {icon && (
      <StyledIcon>
        {icon}
      </StyledIcon>
      )}
      <StyledContainer2>
        {header && (
        <StyledHeader>
          {header}
        </StyledHeader>
        )}
        <StyledContent>
          {content}
        </StyledContent>
      </StyledContainer2>
    </StyledContainer>
  );
}

Item.propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Item.defaultProps = {
  header: null,
  icon: null,
  className: '',
};

export default Item;
