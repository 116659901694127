import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Card,
} from 'react-bootstrap';
import classNames from 'classnames';
import Loader from '../../components/Styled/Misc/loader';

function EditorContainer({
  children,
  err,
  isLoading,
  onClearErrors,
  nonFieldErrors,
  onClearNonFieldErrors,
  title,
  fields,
  noPaddingX,
}) {
  const errView = (msg) => msg.replace('{"detail":"', '').replace('"}', '');

  return (
    <Card className="position-unset">
      <Card.Header>
        <Card.Title>
          {title}
        </Card.Title>
      </Card.Header>
      <Card.Body className={classNames({ 'px-0': noPaddingX })}>
        {err && (
        <Alert dismissible={onClearErrors} onClose={onClearErrors} variant="danger">
          <Alert.Heading>
            {errView(err)}
          </Alert.Heading>
        </Alert>
        )}
        {nonFieldErrors && (

        <Alert dismissible={onClearNonFieldErrors} onClose={onClearNonFieldErrors} variant="danger">
          <Alert.Heading>
            Помилки заповнення даних:
          </Alert.Heading>
          <ul>
            {nonFieldErrors.map((nfe, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <li key={i}>
                {
                  (fields[nfe.message] ? fields[nfe.message].label : nfe.message)
                    || nfe
                }
              </li>
            ))}
          </ul>
        </Alert>
        )}
        {isLoading && (
        <Loader />
        )}
        {children}

      </Card.Body>
    </Card>

  );
}

EditorContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]).isRequired,
  err: PropTypes.string,
  nonFieldErrors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      header: PropTypes.string,
      message: PropTypes.string,
    }),
    PropTypes.string,
  ])),
  isLoading: PropTypes.bool,
  onClearErrors: PropTypes.func,
  onClearNonFieldErrors: PropTypes.func,
  fieldErrors: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fields: PropTypes.shape({}),
  noPaddingX: PropTypes.bool,
};

EditorContainer.defaultProps = {
  err: null,
  nonFieldErrors: null,
  isLoading: false,
  onClearErrors: null,
  onClearNonFieldErrors: null,
  fieldErrors: null,
  title: '',
  fields: {},
  noPaddingX: false,
};

export default EditorContainer;
