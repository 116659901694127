import React from 'react';
import { Alert } from 'react-bootstrap';
import { JobItem } from '../jobs/list';
import useSubscribed from './hook';
import LoaderContainer from './LoaderContainer';

const getReqestOptions = (currentUser) => ({
  subscriber: currentUser.id,
});

function SubscribedJob() {
  const {
    items, fields, err, loading, setErr,
    prevPageHandler, nextPageHandler, canNext, canPrev,
  } = useSubscribed({
    uri: '/api/cat/jobs/',
    optionsGetter: getReqestOptions,
  });

  return (
    <div className="rounded border p-2">
      <LoaderContainer
        err={err}
        loading={loading}
        setErr={setErr}
        onPrevPage={prevPageHandler}
        onNextPage={nextPageHandler}
        canNext={canNext}
        canPrev={canPrev}
      >
        {items.length
          ? items.map((row) => (
            <JobItem key={row.id} row={row} fields={fields} />
          ))
          : (
            <Alert variant="info">
              You have no subscribed jobs.
            </Alert>
          )}
        {/* </Row> */}
      </LoaderContainer>
    </div>
  );
}

export default SubscribedJob;
