import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../api/req';
import AppContext, { withAuthConsumer, mapStateAuth } from '../../../../providers/authProvider';
import { DeleteButton } from '../../../../components/Styled/Buttons';
import Loader from '../../../../components/Styled/Misc/loader';
import { EditorControls } from '../../../BasicEditor/editorControls';

function LandingDate({ peopleId }) {
  const { auth, currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const readOnly = currentUser.profile.role >= 10;
  const [landingDate, setLandingDate] = useState(null);

  const load = useCallback(() => {
    const loader = async () => {
      const r = await api.get(`/api/cat/peoples/${peopleId}/landing_date/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      if (r.status === 204) return null;
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => setLandingDate(d))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, peopleId]);

  useEffect(() => {
    load();
  }, [load]);

  const setValue = useCallback(
    (partValue) => {
      const loader = async () => {
        const r = await api.put(`/api/cat/peoples/${peopleId}/landing_date/`, auth, { ...landingDate, ...partValue });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return load();
      };
      loader()
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, landingDate, load, peopleId],
  );
  const deleteValue = useCallback(
    () => {
      const loader = async () => {
        const r = await api.delete(`/api/cat/peoples/${peopleId}/landing_date/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return load();
      };
      loader()
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, load, peopleId],
  );
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faShip} className="me-2" />
          Contract dates
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        {loading && (
        <Loader relative />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}

        <Row className="mb-2">
          <Col>
            <EditorControls.DateInput
              value={landingDate ? landingDate.date : null}
              onChange={(e, v) => setValue({ date: v })}
              readOnly={readOnly}
              label="Joining date"
            />
          </Col>
          <Col>
            <EditorControls.DateInput
              value={landingDate ? landingDate.end_date : null}
              onChange={(e, v) => setValue({ end_date: v })}
              readOnly={readOnly}
              label="End of contract"
            />
          </Col>
        </Row>
        <DeleteButton
          onClick={deleteValue}
          disabled={!landingDate}
          content="Reset contract dates"
        />
      </Card.Body>
    </Card>
  );
}
LandingDate.propTypes = {
  peopleId: PropTypes.number.isRequired, // people_id to add
};

export default withAuthConsumer(mapStateAuth)(LandingDate);
