import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Overlay } from 'react-bootstrap';
// import { ErroredTooltip } from '../../../components/bootStrap';
import { getDisplayName } from '../../../api/utils';

const withTableControl = (WrappedControl) => {
  function TableInput({
    controlId, required, errors, ...restProps
  }) {
    const controlRef = useRef(null);
    const [showAlert, setShowAlert] = useState(true);

    return (
      <>
        <WrappedControl
          {...restProps}
          id={controlId}
          ref={controlRef}
          errored={errors && !!errors.length}
          errors={errors}
        />
        <Overlay target={controlRef.current} show={(!!errors) && showAlert} placement="auto">
          {() => (
            <Alert variant="danger" dismissible onClose={() => setShowAlert(false)}>
              <p className="mb-0">
                {String(errors)}
              </p>
            </Alert>
            // <ErroredTooltip id={`error-${controlId}`} {...props}>
            //   {String(errors)}
            // </ErroredTooltip>
          )}
        </Overlay>
      </>
    );
  }

  TableInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  TableInput.propTypes = {
    controlId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  TableInput.defaultProps = {
    required: false,
    errors: null,
  };
  return memo(TableInput);
};

export default withTableControl;
