import React, {
  useCallback, useContext, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import Peoples from './peoples';
import Jobs from './jobs';
import Interviews from './interviews';
import Tasks from './tasks';
import Comments from './comments';
import AppContext from '../../providers/authProvider';

function Dashboard() {
  const { currentUser } = useContext(AppContext);
  const isEmployee = currentUser.profile.role < 10;

  const dashboardItems = useMemo(
    () => [
      {
        label: 'Candidates', settingsKey: 'CandidatesWidget', key: 'peoples', visible: true,
      },
      {
        label: 'Jobs', settingsKey: 'JobsWidget', key: 'jobs', visible: isEmployee,
      },
      {
        label: 'Interviews', settingsKey: 'InterviewsWidget', key: 'interviews', visible: true,
      },
      {
        label: 'Tasks', settingsKey: 'TasksWidget', key: 'tasks', visible: true,
      },
      {
        label: 'Acivity', settingsKey: 'AcivityWidget', key: 'comments', visible: true,
      },
    ].filter((d) => d.visible),
    [isEmployee],
  );
  const refs = useRef();
  const peopleRef = useRef();
  const jobsRef = useRef();
  const interviewsRef = useRef();
  const tasksRef = useRef();
  const commentsRef = useRef();
  const headerRef = useRef();

  const dbRef = useRef();

  useLayoutEffect(() => {
    refs.current = {
      peoples: peopleRef.current,
      jobs: jobsRef.current,
      comments: commentsRef.current,
      interviews: interviewsRef.current,
      tasks: tasksRef.current,
    };
  }, []);

  const [activeItem, setActiveItem] = useState('peoples');

  const onSelect = useCallback(
    (key) => {
      setActiveItem(key);
      const rect = headerRef.current.getBoundingClientRect();
      const offset = rect.y + rect.height;
      if (refs.current[key]) {
        dbRef.current.scrollTo({
          top: refs.current[key].offsetTop - offset,
          behavior: 'smooth',
        });
      }
    },
    [],
  );

  const onScroll = useCallback(
    () => {
      const rect = headerRef.current.getBoundingClientRect();
      const offset = rect.y + rect.height;
      const newActiveItem = dashboardItems.filter(
        (di) => dbRef.current.scrollTop >= refs.current[di.key].offsetTop - offset,
      ).reduce((R, di) => di.key, '111');
      if (newActiveItem !== activeItem) setActiveItem(newActiveItem);
    },
    [activeItem, dashboardItems],
  );

  return (
    <div className="dashboard" ref={dbRef} onScroll={onScroll}>
      <Navbar expand="md" sticky="top" className="px-3 bg-primary-subtle z-3" variant="light" ref={headerRef} collapseOnSelect>
        <Navbar.Brand>
          <FontAwesomeIcon icon={faArrowRightLong} beat className="text-primary-emphasis" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="dashboard-controls" />
        <Navbar.Collapse id="dashboard-controls">
          <Nav onSelect={onSelect} variant="underline" activeKey={activeItem}>
            {dashboardItems.map((di) => (
              <Nav.Item key={di.key}>
                <Nav.Link eventKey={di.key}>
                  {di.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="w-100" ref={peopleRef}>
        <div className="d-flex">
          <div className="h6 mb-0 mt-2 bg-primary text-white p-2 rounded-top">
            Candidates
          </div>
        </div>
        <Peoples />
      </div>
      {isEmployee && (
      <div className="w-100" ref={jobsRef}>
        <div className="d-flex">
          <div className="h6 mb-0 mt-2 bg-primary text-white p-2 rounded-top">
            Jobs
          </div>
        </div>
        <Jobs />
      </div>
      )}
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-fill w-100 px-2" ref={interviewsRef}>
          <div className="d-flex">
            <div className="h6 mb-0 mt-2 bg-primary text-white p-2 rounded-top">
              Interviews
            </div>
          </div>
          <Interviews />
        </div>
        <div className="flex-fill w-100 px-2" ref={tasksRef}>
          <div className="d-flex">
            <div className="h6 mb-0 mt-2 bg-primary text-white p-2 rounded-top">
              Tasks
            </div>
          </div>
          <Tasks />
        </div>
      </div>
      <div className="w-100" ref={commentsRef}>
        <div className="d-flex">
          <div className="h6 mb-0 mt-2 bg-primary text-white p-2 rounded-top">
            Activity
          </div>
        </div>

        <Comments />
      </div>
    </div>

  );
}

export default Dashboard;
