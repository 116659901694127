/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useTablePart from '../../../BasicEditor/hook/tablepart';
import PreferenceRow from './row';
import PreferenceHeader from './header';

function Preferences({
  companies, onChange, data, fields, errors,
}) {
  const {
    tableData, tableActions,
    tableContainerRef, tableErrors,
    tableFields,
  } = useTablePart({
    data, onChange, tableName: 'preferences', errors, fields,
  });
  return (
    <Card className="border-0 ">
      <Card.Header>
        <PreferenceHeader fields={tableFields} />
      </Card.Header>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, rowIndex) => (
          <PreferenceRow
            row={row}
            key={rowIndex}
            errors={tableErrors[rowIndex]}
            companies={companies}
            fields={tableFields}
            onChange={tableActions.onCellChange}
            onDeleteRow={tableActions.onDeleteRow}
            index={rowIndex}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Button variant="outline-primary" onClick={() => tableActions.onAddRow()}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add appling for
        </Button>
      </Card.Footer>
    </Card>
  );
}

Preferences.propTypes = {
  data: PropTypes.shape({
    preferences: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.number,
    })),
  }),
  companies: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

Preferences.defaultProps = {
  data: [],
};

export default Preferences;
