import styled from 'styled-components';

export const Select = styled.select`

`;

export const Option = styled.option`

`;

export default Select;
