import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Form, InputGroup, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';

function Login({ onLogin, errorMsg, loading }) {
  const [login, setLogin] = useState('');
  const [passwd, setPasswd] = useState('');
  const onLoginHandler = useCallback(
    (e) => {
      e.preventDefault();
      onLogin(login, passwd);
    },
    [login, onLogin, passwd],
  );
  return (
    <div className="position-absolute top-50 start-50 translate-middle border rounded-2 px-5 py-4 shadow-lg ">
      <Form onSubmit={onLoginHandler}>
        <h3 className="text-primary mb-3">
          Wellcome to HR-Master
        </h3>
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faUser} />
          </InputGroup.Text>
          <Form.Control
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faLock} />
          </InputGroup.Text>
          <Form.Control
            type="password"
            value={passwd}
            onChange={(e) => setPasswd(e.target.value)}
            autoComplete="current-password"
          />
        </InputGroup>
        {errorMsg && (
          <Alert variant="danger">
            {errorMsg}
          </Alert>
        )}
        <Button
          type="submit"
          className="w-100"
          variant="primary"
          disabled={loading || !login || !passwd}
        >
          {loading && (
            <Spinner size="sm" animation="grow" />
          )}
          <span className="ms-2">
            Login
          </span>
        </Button>
      </Form>
    </div>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  loading: PropTypes.bool,
};

Login.defaultProps = {
  errorMsg: null,
  loading: false,
};

export default Login;
