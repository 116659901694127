import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function CloseButton({ content, onClick, disabled }) {
  return (
    <Button variant="secondary" onClick={onClick} disabled={disabled} title={content}>
      <FontAwesomeIcon icon={faCircleXmark} className="me-2" />
      {content}
    </Button>
  );
}

CloseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CloseButton.defaultProps = {
  content: 'Close',
  disabled: false,
};

export default CloseButton;
