import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook, faBuilding, faDiagramProject,
  faEarthAsia, faLanguage, faListCheck, faPeopleLine, faShip, faTags, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
  Card, Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import SettingsItem from './settingsItem';

function SettingsPage() {
  return (
    <Container fluid className="mt-5">
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faUsers} size="lg" className="me-2" />
                Candidates catalogs
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <SettingsItem
                  header="Countries"
                  to="/cat/country/"
                  description="Catalog of countries."
                  icon={faEarthAsia}
                />
                <SettingsItem
                  header="Languages"
                  to="/cat/language/"
                  description="Catalog of languages"
                  icon={faLanguage}
                />
                <SettingsItem
                  header="Positions"
                  to="/cat/position/"
                  description="Catalog of positions"
                  icon={faPeopleLine}
                />
                <SettingsItem
                  header="Types of contact info"
                  to="/cat/contact_info_type/"
                  description="Contact info types. Used for input candidates contact info."
                  icon={faAddressBook}
                />
                <SettingsItem
                  header="Labels"
                  to="/cat/labels/"
                  description="Catalog of labels"
                  icon={faTags}
                />
              </ListGroup>

            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faShip} size="lg" className="me-2" />
                Jobs catalogs
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>

                <SettingsItem
                  header="Companies"
                  to="/cat/companies/"
                  description="Companies catalog"
                  icon={faBuilding}
                />
                {/* <SettingsItem */}
                {/* header="Directions" */}
                {/* to="/cat/directions/" */}
                {/* description="Catalog of directions" */}
                {/* _icon={LabelsIcon} */}
                {/* /> */}
                <SettingsItem
                  header="Workflows"
                  to="/cat/workflows/"
                  description="Statuses of workflow"
                  icon={faDiagramProject}
                />
                <SettingsItem
                  header="Checklist templates"
                  to="/cat/checklist_templates/"
                  description="Checklists"
                  icon={faListCheck}
                />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container>
  );
}

export default SettingsPage;
