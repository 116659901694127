import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import Menu from './menu2';

function MainLayout() {
  const { hash, pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Menu />
      <div className="container-fluid body px-0 px-sm-2">
        <Outlet />
      </div>
    </>
  );
}

export default MainLayout;
