import React, {
  useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Nav, Row } from 'react-bootstrap';
import api from '../../../api/req';
import AppContext, { mapStateAuth, withAuthConsumer } from '../../../providers/authProvider';
import {
  SaveButton, DeleteButton, CloseButton, PrevButton,
} from '../../../components/Styled/Buttons';
import { useDict } from '../../BaseLister/hook';
import { EditorControls, editorHooks } from '../../BasicEditor/editorControls';
import EditorContainer from '../../BasicEditor/editorContainer';

function JobEditor({
  data, isNew, onSave, onDelete, onChange, onReload, isModified, fields, fieldErrors, loading, systemErrors, nonFieldErrors,
  onClearErrs, onClearNonFieldErrors,
}) {
  const positions = useDict({ url: '/api/cat/position/' });
  const companies = useDict({ url: '/api/cat/companies/' });
  const { auth } = useContext(AppContext);

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, onChange);
  const positionProps = editorHooks.useSelectorInputProps('position_id', data, fields, fieldErrors, onChange);
  const companyProps = editorHooks.useSelectorInputProps('company_id', data, fields, fieldErrors, onChange);
  const amountProps = editorHooks.useNumberInputProps('amount', data, fields, fieldErrors, onChange);
  const descriptionProps = editorHooks.useStringInputProps('description', data, fields, fieldErrors, onChange);

  const closeJob = useCallback(
    async () => {
      const r = await api.post(`/api/cat/jobs/${data.id}/close/`, auth);
      if (r.ok) onReload();
    },
    [auth, data.id, onReload],
  );

  const reopenJob = useCallback(
    async () => {
      const r = await api.post(`/api/cat/jobs/${data.id}/reopen/`, auth);
      if (r.ok) onReload();
    },
    [auth, data.id, onReload],
  );
  return (
    <EditorContainer
      title={data.name}
      isLoading={loading}
      fields={fields}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      fieldErrors={fieldErrors}
      onClearErrors={onClearErrs}
      onClearNonFieldErrors={onClearNonFieldErrors}
    >
      <Nav className="gap-2">
        <SaveButton onClick={onSave} />
        <DeleteButton onClick={onDelete} disabled={isNew} />
        {data.status === 'O' ? (
          <CloseButton content="Close" onClick={() => closeJob()} disabled={isNew || isModified} />
        ) : (
          <PrevButton content="Reopen job" onClick={() => reopenJob()} disabled={isNew || isModified} />
        )}
      </Nav>
      <Row>
        <Col md={12}>
          <EditorControls.StringInput {...nameProps} />
        </Col>
        <Col md={4}>
          <EditorControls.SelectorInput {...positionProps} label="Position" values={positions.selectorValues} />
        </Col>
        <Col md={4}>
          <EditorControls.SelectorInput {...companyProps} label="Company" values={companies.selectorValues} />
        </Col>
        <Col md={4}>
          <EditorControls.NumberInput {...amountProps} />
        </Col>
        <Col md={12}>
          <EditorControls.WysiwygEditor {...descriptionProps} />
        </Col>
      </Row>
    </EditorContainer>
  );
}
JobEditor.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.number,
    company: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
  fields: PropTypes.shape({}),
  fieldErrors: PropTypes.shape({}),
  systemErrors: PropTypes.shape({}),
  nonFieldErrors: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onClearErrs: PropTypes.func.isRequired,
  onClearNonFieldErrors: PropTypes.func.isRequired,
};
JobEditor.defaultProps = {
  fields: {},
  nonFieldErrors: null,
  systemErrors: null,
  fieldErrors: null,
};
export default withAuthConsumer(mapStateAuth)(JobEditor);
