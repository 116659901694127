import Styled from 'styled-components';

export const DefaultButton = Styled.button.attrs({
  type: 'button',
})`
  border-radius: .3rem;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  background: none;
`;

export default DefaultButton;
