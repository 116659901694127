import List from './list';
import Editor from './editor';
import RouterEditor from './withRouter';
import { frontendURI } from './list/uri';

const Catalog = {
  List,
  Editor,
  RouterEditor,
  frontendURI,
};

export default Catalog;
