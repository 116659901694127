import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../BasicEditor/tableControls';

function EducationRow({
  row, fields, index, errors, onChange, onDeleteRow,
}) {
  const institutionProps = tableHooks.useStringInputProps('institution', row, fields, errors, onChange, index);
  const dateFromProps = tableHooks.useDateInputProps('date_from', row, fields, errors, onChange, index);
  const dateToProps = tableHooks.useDateInputProps('date_to', row, fields, errors, onChange, index);
  const diplomaProps = tableHooks.useStringInputProps('diploma', row, fields, errors, onChange, index);
  const qualificationProps = tableHooks.useStringInputProps('qualification', row, fields, errors, onChange, index);

  return (
    <div className="d-flex flex-nowrap align-items-baseline gap-1">
      <Row className="ms-1 flex-fill my-1">
        <Col sm={2}>
          <TableControls.DateInput {...dateFromProps} />
        </Col>
        <Col sm={2}>
          <TableControls.DateInput {...dateToProps} />
        </Col>
        <Col sm={4}>
          <TableControls.StringInput {...institutionProps} />
        </Col>
        <Col sm={4}>
          <TableControls.StringInput {...diplomaProps} />
        </Col>
        <Col sm={12}>
          <TableControls.StringInput {...qualificationProps} />
        </Col>
      </Row>
      <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
        <FontAwesomeIcon icon={faTimes} title="Delete record" />
      </Button>
    </div>
  );
}

EducationRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

EducationRow.defaultProps = {
  errors: {},
};

export default memo(EducationRow);
