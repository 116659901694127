import React from 'react';

function PDFDriver({ filePath }) {
  return (
    <object className="h-100">
      <embed
        className="w-100 h-100 rounded"
        src={filePath}
        onDrop={(e) => e.preventDefault()}
      />
    </object>
  );
}

// const PDFDriver = ({ filePath }) => filePath.length > 2048 ? (
//   <ErrorMessage
//     header="File is too large for preview"
//     text="File is too large for preview. After apply, a preview will be available."
//   />
// ) : (
//   <object
//     style={{width: '40vw', height: '60vh'}}
//     aria-label="document"
//     type="application/pdf"
//     data={filePath}
//     onDrop={e => e.preventDefault()}
//   />
// );

export default PDFDriver;
