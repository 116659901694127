import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import Editor from './editor';

function InterviewRouterEditor() {
  const navigate = useNavigate();
  const params = useParams();
  const onClose = useCallback(
    () => navigate('/candidates/'),
    [navigate],
  );

  return (
    <Editor id={params.id} onClose={onClose} />
  );
}

export default InterviewRouterEditor;
