import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormCheck,
} from 'react-bootstrap';

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const Radio = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  required, errored,
  className,
}, ref) => {
  const groupName = useMemo(
    () => Math.random().toString(36).substring(7),
    [],
  );
  return (
    <div className={className} id={id} ref={ref}>
      {values.map((vv) => (
        <FormCheck
          name={groupName}
          type="radio"
          key={vv.value}
          checked={vv.value === value}
          disabled={vv.disabled || disabled}
          readOnly={readOnly}
          // onClick={(e) => onChange(e, vv.value)}
          onChange={(e) => onChange(e, vv.value)}
          label={(
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
            <span role="button" onClick={(e) => onChange(e, vv.value)}>
              {vv.display_name}
            </span>
          )}
          isInvalid={errored}
          required={required}
        />
      ))}
    </div>
  );
});

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  errored: PropTypes.bool,
  className: PropTypes.string,
};

Radio.defaultProps = {
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  required: true,
  errored: false,
  className: null,
};

export default Radio;
