import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import PeopleItem from '../peoples/list/item';
import useSubscribed from './hook';
import LoaderContainer from './LoaderContainer';

const getReqestOptions = (currentUser) => ({
  subscriber: currentUser.id,
  // на главном экране лузерам не место!
  is_pool: false,
});

function SubscribedPeople() {
  const {
    items, fields, err, loading, setErr,
    prevPageHandler, nextPageHandler, canNext, canPrev,
  } = useSubscribed({
    uri: '/api/cat/peoples/',
    optionsGetter: getReqestOptions,
  });

  return (
    <LoaderContainer
      err={err}
      loading={loading}
      setErr={setErr}
      onPrevPage={prevPageHandler}
      onNextPage={nextPageHandler}
      canNext={canNext}
      canPrev={canPrev}
    >
      <Row xs={1} sm={2} md={2} lg={3} xl={3} xxl={4} className="gy-2 w-100">
        {items.length
          ? items.map((row) => (
            <Col key={row.id}>
              <PeopleItem row={row} fields={fields} />
            </Col>
          ))
          : (
            <Alert variant="info">
              You have no subscribed candidates.
            </Alert>
          )}
      </Row>
    </LoaderContainer>
  );
}

export default SubscribedPeople;
