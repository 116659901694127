import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Paginator({
  onNextPage, onPrevPage,
}) {
  return (
    <ButtonGroup size="sm" className="mt-2">
      <Button
        disabled={!onPrevPage}
        alt="Previous page"
        onClick={onPrevPage}
        variant="outline-primary"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Button
        disabled={!onNextPage}
        alt="Next page"
        onClick={onNextPage}
        variant="outline-primary"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </ButtonGroup>
  );
}

Paginator.propTypes = {
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
};

Paginator.defaultProps = {
  onNextPage: null,
  onPrevPage: null,
};

export default Paginator;
