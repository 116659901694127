import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { SaveButton, DeleteButton, CloseButton } from '../../../../components/Styled/Buttons';
import { backendURI } from '../list/uri';
import useEditor from '../../../BasicEditor/hook/editor';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';

function LabelEditor({
  id, onSave, onClose,
}) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, options,
  } = useEditor({
    id,
    backendURL: backendURI,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const colorProps = editorHooks.useSelectorInputProps('color', data, fields, fieldErrors, actions.onChange);
  const bgColorProps = editorHooks.useSelectorInputProps('bg_color', data, fields, fieldErrors, actions.onChange);
  return (
    <Container fluid>
      <EditorContainer
        title={data.name}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        {options.description && (
        <p className="text-muted small">
          {options.description}
        </p>
        )}
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isNew} />
          <CloseButton onClick={() => onClose()} />
        </Nav>
        <Row>
          <Col md={4}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col md={4}>
            <EditorControls.SelectorInput {...colorProps} />
          </Col>
          <Col md={4}>
            <EditorControls.SelectorInput {...bgColorProps} />
          </Col>
          <Col className="py-4">
            <span className="me-2">Preview:</span>
            <Badge
              style={{ backgroundColor: data.bg_color, color: data.color }}
              bg="none"
              className="border"
            >
              {data.name}
            </Badge>
          </Col>
        </Row>
      </EditorContainer>
    </Container>
  );
}

LabelEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

LabelEditor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default LabelEditor;
