import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export const SaveButton = React.memo(({ content, onClick, disabled }) => (
  <Button variant="primary" onClick={onClick} disabled={disabled}>
    <FontAwesomeIcon icon={faSave} className="me-2" />
    {content}
  </Button>
));

SaveButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  content: 'Apply',
  disabled: false,
};

export default SaveButton;
