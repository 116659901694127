import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

function TaskItem({ task }) {
  // eslint-disable-next-line no-unused-vars
  const { ds, isToday, isLate } = useMemo(
    () => {
      const d = new Date(task.remind);
      const today = new Date();
      const ist = today.toJSON().substring(0, 10) === d.toJSON().substring(0, 10);
      return {
        ds: ist
          ? `Today at ${d.toLocaleString('en-CY', { hour: '2-digit', minute: '2-digit' })}`
          : d.toLocaleString('en-CY', {
            day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit',
          }),
        isToday: ist,
        isLate: !ist && d < today,
      };
    },
    [task.remind],
  );
  return (
    <Card as={Link} to={`/tasks/${task.id}`} className="text-decoration-none">
      <Card.Header className={classNames('text-white', {
        'bg-secondary': task.closed,
        'bg-primary': !task.closed && !isLate && !isToday,
        'bg-success': !task.closed && isToday,
        'bg-danger': !task.closed && isLate,
      })}
      >
        <Card.Title>{task.title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <FontAwesomeIcon icon={task.closed ? faCheck : faClock} size="3x" className="me-2 text-muted" bounce={isToday} />
          <div className="text-center flex-fill">
            {ds}
          </div>
        </div>
      </Card.Body>

    </Card>
  );
}

TaskItem.propTypes = {
  task: PropTypes.shape({
    closed: PropTypes.bool,
    title: PropTypes.string.isRequired,
    comment: PropTypes.string,
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string,
    }),
    executor: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string,
    }),
  }).isRequired,
};

export default TaskItem;
