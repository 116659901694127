import React, {
  forwardRef, useCallback, useMemo,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';
import DatePickerInput from './datePickerInput';

const StyledInputGroup = styled(InputGroup)`
  & .react-datepicker-wrapper {
    flex: 1 1 auto;
  }
`;

const TimePicker = forwardRef(({
  id, value, onChange, disabled, readOnly, onFocus, onBlur,
  errors, errorAsTooltip, size, prepend,
}, ref) => {
  const cDate = useMemo(
    () => {
      if (!value) return null;
      if (!Date.parse(value)) return null;
      const d = new Date(value);
      // d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      return d;
    },
    [value],
  );

  const onDateChange = useCallback(
    (v, e) => {
      // v.setMinutes(v.getMinutes() - v.getTimezoneOffset());
      onChange(e, v.toISOString());
    },
    [onChange],
  );

  const errored = errors && errors.length;

  const onFocusHanlder = useCallback(
    (e) => {
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const onBlurHanlder = useCallback(
    (e) => {
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  return (
    <StyledInputGroup className={classNames('flex-nowrap', { 'is-invalid': errored })} size={size}>
      {prepend}
      <DatePicker
        id={id}
        ref={ref}
        selected={cDate}
        onChange={onDateChange}
        onFocus={onFocusHanlder}
        onBlur={onBlurHanlder}
        className="w-100"
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="HH:mm"
        showTimeSelect
        showTimeSelectOnly
        locale="en"
        disabled={disabled || readOnly}
        customInput={(
          <DatePickerInput
            errors={errors}
            errorAsTooltip={errorAsTooltip}
            onFocus={onFocus}
            onBlur={onBlur}
            className="flex-fill"
            size={size}
          />
      )}
      />
    </StyledInputGroup>
  );
});

TimePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  prepend: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

TimePicker.defaultProps = {
  id: '',
  value: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  readOnly: false,
  errors: null,
  errorAsTooltip: false,
  size: 'sm',
  prepend: '',
};

export default TimePicker;
