import React from 'react';
import PropTypes from 'prop-types';
import { CheckedIcon } from '../../../icons';
import { StyledButton, StyledImg, StyledText } from './Styled';

export function OKButton({
  content, onClick, disabled, ...props
}) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} title={content} {...props}>
      <StyledImg src={CheckedIcon} alt={content} />
      <StyledText>{content}</StyledText>
    </StyledButton>
  );
}

OKButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OKButton.defaultProps = {
  onClick: () => null,
  content: 'OK',
  disabled: false,
};

export default OKButton;
