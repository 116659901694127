import PropTypes from 'prop-types';
import StringInput from '../../../components/Controls/stringInput';
import DateInput, { DateTimePicker, TimePicker } from '../../../components/Controls/dates';
import NumberInput from '../../../components/Controls/numberInput';
import Selector from '../../../components/Controls/selector';
import TextInput from '../../../components/Controls/textInput';
import RadioButtons from '../../../components/Controls/radio';
import EditorCheckboxInput from './checkbox';
import editorHooks from './hooks';
import withEditorControl from './editorControl';
import MultipleSelector from '../../../components/Controls/multiple_selector';
import RangeInput from '../../../components/Controls/rangeInput';
import WysiwygEditor from '../../../components/Controls/Wysiwyg2Editor';

export const EditorStingInput = withEditorControl(StringInput);
export const EditorDateInput = withEditorControl(DateInput);
export const EditorTimeInput = withEditorControl(TimePicker);
export const EditorDateTimeInput = withEditorControl(DateTimePicker);
export const EditorNumberInput = withEditorControl(NumberInput);
export const EditorSelectorInput = withEditorControl(Selector);
export const EditorRadioInput = withEditorControl(RadioButtons);
export { EditorCheckboxInput };
export const EditorTextInput = withEditorControl(TextInput);
export const EditorMultiSelectorInput = withEditorControl(MultipleSelector);

export const EditorRangeInput = withEditorControl(RangeInput);

export const EditorWysiwygEditor = withEditorControl(WysiwygEditor);

export { editorHooks };

export const FieldPropType = PropTypes.shape({
  label: PropTypes.string,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['field', 'integer', 'string', 'date', 'boolean', 'decimal', 'nested object', 'choice', 'datetime', 'email', 'url']),
  max_length: PropTypes.number,
  choice: PropTypes.shape({ }),
  resource: PropTypes.string, // backend url for FK fields
});
export const getFieldsPropTypes = (dpt) => Object.keys(dpt)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

export const EditorControls = {
  StringInput: EditorStingInput,
  DateInput: EditorDateInput,
  TimeInput: EditorTimeInput,
  DateTimeInput: EditorDateTimeInput,
  NumberInput: EditorNumberInput,
  SelectorInput: EditorSelectorInput,
  CheckboxInput: EditorCheckboxInput,
  TextInput: EditorTextInput,
  RadioInput: EditorRadioInput,
  MultiSelector: EditorMultiSelectorInput,
  RangeInput: EditorRangeInput,
  WysiwygEditor: EditorWysiwygEditor,
};
