import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert, Badge, Button, Col, Collapse, Container, InputGroup, ListGroup, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  DateInput, MultipleSelector, SearchInput,
} from '../../../components/Controls';
import { UserSelector } from '../../SimpleSelector';
import InterviewItem from './InterviewItem';
import useLister, { useFilterItems } from '../../BaseLister/hook';
import LoaderContainer from '../../DashBoard/LoaderContainer';
import { interviewStatuses } from '../../../const/enums';
import { useLayoutGridHook } from '../../../api/hooks';

const initialStatus = [interviewStatuses.SCHEDULED];

const API_URL = '/api/interviews/';

function InterviewList() {
  const {
    data, fields, onNextPage, onPrevPage,
    onSearch, setErr, loading, err, canNextPage, canPrevPage,
    onSetFilter, filter, loaded, count,
  } = useLister({ url: API_URL, initialFilter: { status__in: initialStatus } });
  const statusFilter = useFilterItems(filter, 'status__in', onSetFilter);
  const dateFilter = useFilterItems(filter, 'date', onSetFilter);
  const executorFilter = useFilterItems(filter, 'executor', onSetFilter);

  const { screenWidth } = useLayoutGridHook();
  const sff = useRef();
  const [showFilters, setShowFilters] = useState(false);
  sff.current = showFilters;
  useEffect(() => {
    if (screenWidth >= 992 && !sff.current) setShowFilters(true);
    else if (screenWidth < 992 && sff.current) setShowFilters(false);
  }, [screenWidth]);

  return (
    <LoaderContainer
      onPrevPage={onPrevPage}
      canPrev={canPrevPage}
      onNextPage={onNextPage}
      canNext={canNextPage}
      setErr={setErr}
      loading={loading}
      err={err}
    >
      {loaded && (
      <Container fluid>
        <div className="position-sticky fixed-under-menu bg-white">
          <div className="d-flex align-items-start">
            <h1 className="text-primary me-2">Interviews</h1>
            <Badge>{count}</Badge>
          </div>
          <Nav className="mb-2 pb-2 border-bottom d-flex flex-column flex-sm-row w-100 align-items-center gy-2 gy-sm-0 gap-2">
            <div className="text-secondary px-3 py-2 rounded fst-italic d-none d-md-block">
              You can add new interview in
              {' '}
              <Link to="/candidates">some candidate item</Link>
            </div>
            <Button
              variant="outline-primary"
              onClick={() => setShowFilters((o) => !o)}
              size="sm"
              active={showFilters}
              className="d-block d-lg-none"
            >
              <FontAwesomeIcon icon={faFilter} className="me-2" />
              Filters
            </Button>

            <div className="ms-sm-auto align-self-stretch">
              <SearchInput onSearch={onSearch} />
            </div>
          </Nav>
        </div>
        <Row className="flex-column-reverse flex-lg-row">
          <Col md={9}>
            <Row xs={1} sm={2} md={2} lg={3} xl={2} xxl={3} className="gy-2">
              {data.length ? data.map((row) => (
                <Col key={row.id}>
                  <InterviewItem
                    key={row.id}
                    interveiw={row}
                    mode="byBoth"
                  />
                </Col>
              )) : (
                <Alert variant="info" className="w-100">
                  <Alert.Heading>Oops</Alert.Heading>
                  There&apos;s nothing here
                </Alert>
              )}
            </Row>
          </Col>
          <Col md={3}>
            <Collapse in={showFilters}>
              <div>
                <h3>Filters</h3>
                <ListGroup>
                  <ListGroup.Item>
                    <MultipleSelector
                      prepend={(
                        <>
                          <InputGroup.Text>Interview status</InputGroup.Text>
                          <InputGroup.Checkbox checked={statusFilter.use} onChange={statusFilter.onToggle} />
                        </>
                      )}
                      value={statusFilter.value}
                      values={fields.status.choices}
                      onChange={statusFilter.onChange}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <DateInput
                      prepend={(
                        <>
                          <InputGroup.Text>Date of interview</InputGroup.Text>
                          <InputGroup.Checkbox checked={dateFilter.use} onChange={dateFilter.onToggle} />
                        </>
                      )}
                      value={dateFilter.value}
                      onChange={dateFilter.onChange}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <UserSelector
                      prepend={(
                        <>
                          <InputGroup.Text>{fields.executor.label}</InputGroup.Text>
                          <InputGroup.Checkbox checked={executorFilter.use} onChange={executorFilter.onToggle} />
                        </>
                      )}
                      value={executorFilter.value}
                      onChange={executorFilter.onChange}
                      required
                    />

                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Collapse>
          </Col>
        </Row>
      </Container>
      )}
    </LoaderContainer>
  );
}

export default InterviewList;
