import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import CheckListReport from './shower';

const ReportInfo = {
  component: CheckListReport,
  url: 'checklists',
  icon: faListCheck,
  tittle: 'Checklists',
  description: 'Report shows candidates with masrks about included files and documents',
};

export default ReportInfo;
