import React, {
  memo,
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import api from '../../api/req';
import { Selector } from '../../components/Controls';
import AppContext from '../../providers/authProvider';

function SimpleSelector({
  URI, dataMapper, value, onChange, required, disabled, readOnly, preloadedData,
  prepend, append,
}) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [values, setValues] = useState([]);
  useEffect(
    () => {
      if (preloadedData) {
        setValues(preloadedData.map(dataMapper));
      } else {
        const loader = async () => {
          const r = await api.get(URI, auth);
          if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
          return r.json();
        };
        setLoading(true);
        setErr(null);
        loader()
          .then((d) => setValues(d.map(dataMapper)))
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [URI, auth, dataMapper, preloadedData],
  );
  return (
    <Selector
      value={value}
      onChange={onChange}
      values={values}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      isLoading={loading}
      errors={err && [err]}
      prepend={prepend}
      append={append}
    />

  );
}
SimpleSelector.propTypes = {
  URI: PropTypes.string.isRequired,
  dataMapper: PropTypes.func.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  preloadedData: PropTypes.arrayOf(PropTypes.shape()),
  prepend: PropTypes.node,
  append: PropTypes.node,
};

SimpleSelector.defaultProps = {
  value: null,
  required: false,
  disabled: false,
  readOnly: false,
  preloadedData: null,
  prepend: null,
  append: null,
};

export default memo(SimpleSelector);
