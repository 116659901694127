import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import Editor from './editor';

function TaskRouterEditor() {
  const navigate = useNavigate();
  const params = useParams();
  const onClose = useCallback(
    () => navigate('/tasks/'),
    [navigate],
  );
  const onSave = useCallback(
    (data) => navigate(`/tasks/${data.id}`),
    [navigate],
  );

  return (
    <Editor id={params.id} onClose={onClose} onSave={onSave} />
  );
}

export default TaskRouterEditor;
