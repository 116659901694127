import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Row } from 'react-bootstrap';

function JobItem({ row }) {
  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title as={Link} to={`/jobs/${row.id}/`} className="text-decoration-none h5">
          {row.name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row xs={1} sm={2}>
          <Col>
            <FontAwesomeIcon icon={faBuilding} className="me-1 text-muted" size="sm" />
            {row.position.repr}
          </Col>
          <Col>
            <FontAwesomeIcon icon={faBuilding} className="me-1 text-muted" size="sm" />
            {row.company.repr}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer dangerouslySetInnerHTML={{ __html: row.description }} />
    </Card>
  );
}

JobItem.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default JobItem;
