import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { SaveButton, DeleteButton, CloseButton } from '../../../../components/Styled/Buttons';
import { backendURI } from '../list/uri';
import useEditor from '../../../BasicEditor/hook/editor';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';

function CITypeEditor({
  id, onSave, onClose,
}) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, options,
  } = useEditor({
    id,
    backendURL: backendURI,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  return (
    <Container fluid>
      <EditorContainer
        title={data.name}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        {options.description && (
        <p className="text-muted small">
          {options.description}
        </p>
        )}
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isNew} />
          <CloseButton onClick={() => onClose()} />
        </Nav>
        <Row>
          <Col md={12}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
      </EditorContainer>
    </Container>
  );
}

CITypeEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

CITypeEditor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default CITypeEditor;
