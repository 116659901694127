import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../api/req';
import { insertIntoArray, deleteFromArray } from '../../../../api/utils';
import AppContext from '../../../../providers/authProvider';
import LabelContainer from './labelsContainer';
import Loader from '../../../../components/Styled/Misc/loader';

function PeopleLabel({ URI }) {
  const { auth, currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [available, setAvailable] = useState([]);
  const [current, setCurrent] = useState([]);
  const readOnly = currentUser.profile.role >= 10;

  useEffect(
    () => {
      const load = async () => {
        const r = await api.get(URI, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(false);
      load()
        .then((d) => {
          const cIds = d.current_labels.map((l) => l.id);
          setCurrent(d.current_labels);
          setAvailable(d.available_labels.filter((l) => !cIds.includes(l.id)));
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [URI, auth],
  );

  const onSaveChanges = useCallback(
    (labels) => {
      const saver = async () => {
        const r = await api.put(URI, auth, { labels });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(false);
      saver()
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [URI, auth],
  );

  const onDragEndHandler = useCallback(
    ({ source, destination }) => {
      if (source && destination) {
        let newAvailable = available;
        let newCurrent = current;
        let save = true;
        if (source.droppableId === 'available' && destination.droppableId === 'current') {
          newAvailable = deleteFromArray(available, source.index);
          newCurrent = insertIntoArray(current, destination.index, available[source.index]);
        } else if (source.droppableId === 'current' && destination.droppableId === 'available') {
          newAvailable = insertIntoArray(available, destination.index, current[source.index]);
          newCurrent = deleteFromArray(current, source.index);
        } else if (source.droppableId === 'current' && destination.droppableId === 'current') {
          newCurrent = deleteFromArray(current, source.index);
          newCurrent = insertIntoArray(newCurrent, destination.index, current[source.index]);
        } else if (source.droppableId === 'available' && destination.droppableId === 'available') {
          newAvailable = deleteFromArray(available, source.index);
          newAvailable = insertIntoArray(newAvailable, destination.index, available[source.index]);
          save = false;
        }
        setAvailable(newAvailable);
        setCurrent(newCurrent);
        if (save) {
          onSaveChanges(newCurrent.map((l) => l.id));
        }
      }
    },
    [available, current, onSaveChanges],
  );
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faLaptop} className="me-2" />
          Labels
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        {loading && (
        <Loader relative />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}
        <DragDropContext onDragEnd={onDragEndHandler}>
          <div className="d-flex flex-column ">
            {!readOnly && (
            <LabelContainer
              labels={available}
              header="Available labels"
              droppableId="available"
            />
            )}
            <LabelContainer
              labels={current}
              header="Candidate labels"
              droppableId="current"
              readOnly={readOnly}
            />
          </div>
        </DragDropContext>
      </Card.Body>
    </Card>
  );
}

PeopleLabel.propTypes = {
  URI: PropTypes.string.isRequired,
};
export default memo(PeopleLabel);
