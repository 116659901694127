import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../BasicEditor/tableControls';

function LanguageRow({
  row, fields, index, errors, onChange, languages, onDeleteRow,
}) {
  const languageProps = tableHooks.useSelectorInputProps('language', row, fields, errors, onChange, index);
  const skillProps = tableHooks.useSelectorInputProps('skill', row, fields, errors, onChange, index);

  return (
    <div className="d-flex flex-nowrap align-items-baseline gap-1">
      <Row className="ms-1 my-1 flex-fill">
        <Col sm={6}>
          <TableControls.SelectorInput {...languageProps} values={languages.selectorValues} />
        </Col>
        <Col sm={6}>
          <TableControls.SelectorInput {...skillProps} />
        </Col>
      </Row>
      <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
        <FontAwesomeIcon icon={faTimes} title="Delete record" />
      </Button>
    </div>
  );
}

LanguageRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

LanguageRow.defaultProps = {
  errors: {},
};

export default memo(LanguageRow);
