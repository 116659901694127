import React from 'react';
import {
  Button, Col, Container, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { SearchInput } from '../../../../components/Controls/index';
import { backendURI, frontendURI } from './uri';
import PositionItem from './item';
import useLister from '../../../BaseLister/hook';
import LoaderContainer from '../../../DashBoard/LoaderContainer';

function PositionsList() {
  const {
    data, onNextPage, onPrevPage,
    onSearch, setErr, loading, err, canNextPage, canPrevPage, options, loaded,
  } = useLister({ url: backendURI });
  const navigate = useNavigate();
  return (
    <LoaderContainer
      onPrevPage={onPrevPage}
      canPrev={canPrevPage}
      onNextPage={onNextPage}
      canNext={canNextPage}
      setErr={setErr}
      loading={loading}
      err={err}
    >
      {loaded && (
      <Container fluid>
        <div className="position-sticky fixed-top bg-white">
          <h1 className="text-primary">{options.name}</h1>
          {options.description && (
          <p className="small text-muted">
            {options.description}
          </p>
          )}
          <Nav className="mb-2 pb-2 border-bottom">
            <Button variant="outline-primary" onClick={() => navigate(`${frontendURI}create`)} size="sm">
              <FontAwesomeIcon icon={faFile} className="me-2" />
              Create new
            </Button>

            <div className="ms-auto w-25">
              <SearchInput onSearch={onSearch} />
            </div>
          </Nav>
        </div>
        <Row xs={1} sm={2} md={2} lg={3} xl={2} xxl={3} className="gy-2">
          {data.map((row) => (
            <Col key={row.id}>
              <PositionItem
                key={row.id}
                row={row}
              />
            </Col>
          ))}
        </Row>
      </Container>
      )}
    </LoaderContainer>
  );
}
export default PositionsList;
