import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  PeopleLabel,
} from './styles';

function LabelContainer({
  labels, header, droppableId, readOnly,
}) {
  return (
    <div className="bg-white flex-fill rounded d-flex flex-column p-1 w-100">
      <h5 className="text-success">{header}</h5>
      <Droppable droppableId={droppableId} direction="horizontal">
        {(providedDrop) => (
          <div className="h-100 bg-secondary-subtle" ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {labels.map((label, index) => (
              <Draggable draggableId={`${droppableId}-${label.id}`} index={index} key={label.id} isDragDisabled={readOnly}>
                {(providedDrag) => (
                  <PeopleLabel
                    ref={providedDrag.innerRef}
                    $color={label.color}
                    $bg_color={label.bg_color}
                    {...providedDrag.dragHandleProps}
                    {...providedDrag.draggableProps}
                  >
                    {label.name}
                  </PeopleLabel>
                )}
              </Draggable>
            ))}
            {providedDrop.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

LabelContainer.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    bg_color: PropTypes.string,
    name: PropTypes.string,
  })),
  header: PropTypes.string,
  droppableId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

LabelContainer.defaultProps = {
  labels: [],
  header: '',
  readOnly: false,
};
export default LabelContainer;
