import { useMemo } from 'react';
import { useTableBaseInputProps } from './baseInputProps';

const getPrecision = (type) => {
  switch (type) {
    case 'integer':
      return 0;
    case 'decimal':
      return 2;
    default:
      return 2;
  }
};

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { number } rowIndex - rowIndex
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  value: number,
 *  required: boolean,
 *  minValue: number,
 *  maxValue: number,
 *  precision: number,
 *  errors: Array}}
 */
export const useNumberInputProps = (
  name,
  data,
  fields,
  errors,
  onChange,
  rowIndex,
  isReadOnly = false,
  readOnlyFelds = [],
) => {
  const baseProps = useTableBaseInputProps(
    name,
    data,
    fields,
    errors,
    onChange,
    rowIndex,
    isReadOnly,
    readOnlyFelds,
  );
  const { minValue, maxValue, precision } = useMemo(
    () => (fields && fields[name] ? {
      minValue: fields[name].min_value,
      maxValue: fields[name].max_value,
      precision: getPrecision(fields[name].type),
    } : {
      minValue: null,
      maxValue: null,
      precision: 0,
    }),
    [fields, name],
  );

  return useMemo(
    () => ({
      ...baseProps,
      minValue,
      maxValue,
      precision,
    }),
    [baseProps, maxValue, minValue, precision],
  );
};

export default useNumberInputProps;
