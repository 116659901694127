import styled from 'styled-components';

const HeaderText = styled.h2`
  // color: #008F21;
  color: #7a7a7a;
  font-family: 'Roboto', sans-serif;
  margin: 20px 0 25px 0;
  font-size: 1.3em;
  text-align: center;
  
  @media (max-width: 600px) {
  margin: 10px 0;
  }
`;

export default HeaderText;
