import React, {
  memo, useId,
} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { getDisplayName } from '../../../api/utils';

const withEditorControl = (WrappedControl) => {
  function EditorInput({
    controlId, label, required, description, inline, ...restProps
  }) {
    const nId = useId();
    const cId = controlId || nId;
    return (
      <Form.Group className={classNames('form-group', { 'd-flex gap-2 align-items-center': inline })}>
        {label && (
        <Form.Label
          title={label}
          htmlFor={cId}
          className={classNames({ 'mb-0': inline, required })}
        >
          {label}
        </Form.Label>
        )}
        <WrappedControl
          {...restProps}
          id={cId}
          required={required}
        />
        {description && (
          <Form.Text>
            {description}
          </Form.Text>
        )}
      </Form.Group>
    );
  }

  EditorInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  EditorInput.propTypes = {
    controlId: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    inline: PropTypes.bool,
  };

  EditorInput.defaultProps = {
    controlId: null,
    label: null,
    required: false,
    description: null,
    inline: false,
  };
  return memo(EditorInput);
};

export default withEditorControl;
