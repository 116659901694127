import React, {
  useState, useEffect, useCallback, memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../api/req';
import AppContext from '../../../../providers/authProvider';
import { WFStatusSelector } from '../../../SimpleSelector';
import { SaveButton } from '../../../../components/Styled/Buttons';
import Loader from '../../../../components/Styled/Misc/loader';

function WFStatus({ peopleId }) {
  const { auth, currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const readOnly = currentUser.profile.role >= 10;
  const [wf, setWf] = useState(null);
  const [curwf, setCurWf] = useState(null);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get(`/api/cat/peoples/${peopleId}/current_wf/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => setCurWf(d.workflow || null))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, peopleId]);

  const changeWF = useCallback(
    () => {
      const loader = async () => {
        const r = await api.put(`/api/cat/peoples/${peopleId}/current_wf/`, auth, { wf_status: wf });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      loader()
        .then((d) => setCurWf(d.workflow || null))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, peopleId, wf],
  );
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faListCheck} className="me-2" />
          Workflow status
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        {loading && (
        <Loader relative />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}
        {curwf ? (
          <div>
            This candidat has
            {' '}
            <b>{curwf.repr}</b>
            {' '}
            workflow status.
          </div>
        ) : (
          <div>
            This candidat hasn&apos;t workflow status.
          </div>
        )}
        {!readOnly && (
        <>
          <br />
          To change workflow status select new status:
          <WFStatusSelector
            required
            value={wf}
            onChange={(e, v) => setWf(v)}
          />
          <div className="mt-2">
            <SaveButton
              content="Change"
              onClick={changeWF}
              disabled={!wf}
            />
          </div>
        </>
        )}
      </Card.Body>
    </Card>
  );
}

WFStatus.propTypes = {
  peopleId: PropTypes.number.isRequired, // people_id to add
};

export default memo(WFStatus);
