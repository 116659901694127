import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Badge, Button, Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk, faBarcode, faBuilding,
  faCalendar,
  faEarthEurope, faEnvelope,
  faPersonCircleQuestion,
  faPersonWalkingLuggage, faPhone,
  faQuestion, faRefresh, faShip, faTimes, faUser, faVenusMars,
} from '@fortawesome/free-solid-svg-icons';
import DateShow from '../../../components/Controls/dates/dateShow';

function PeopleItemRow({
  row, closed, showClosedInfo, onClose, onReopen,
}) {
  return (
    <Card className="h-100 ">
      <Row className="pb-1 mb-1">
        <Col xxl={1} xl={2} className="overflow-hidden d-none d-xl-block">
          <Link to={`/candidates/${row.id}/`}>
            <img src={row.photo} alt={`${row.name} ${row.family_name}`} className="w-100 rounded" />
          </Link>
        </Col>
        <Col xxl={11} xl={10} lg={12}>
          <Card.Title as={Link} className="mt-2 h5 text-decoration-none" to={`/candidates/${row.id}/`}>
            {`${row.name} ${row.family_name}`}
          </Card.Title>
          <div className="d-flex justify-content-end position-absolute top-0 end-0 w-50">
            {row.labels.map((l) => (
              <Badge
                key={l.id}
                style={{ backgroundColor: l.bg_color, color: l.color }}
                bg="none"
                className="border"
              >
                {l.name}
              </Badge>
            ))}
          </div>
          {showClosedInfo && (
            <div className="d-flex justify-content-end">
              {closed && <span>CLOSED</span>}
              {closed ? (
                <Button variant="outline-secondary" onClick={onReopen} size="sm">
                  <FontAwesomeIcon icon={faRefresh} className="me-2" />
                  Reopen
                </Button>
              ) : (
                <Button variant="outline-danger" onClick={onClose} size="sm">
                  <FontAwesomeIcon icon={faTimes} className="me-2" />
                  Close
                </Button>
              )}
            </div>
          )}
          <Row>
            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={12}>
              <FontAwesomeIcon icon={faBarcode} className="me-1 text-muted" size="sm" title="Pin" />
              <span className="fw-bold text-primary">{row.id}</span>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={3} sm={4} xs={12}>
              <FontAwesomeIcon icon={faPhone} title="Phone" />
              <a href={`tel:${row.phone}`} className="text-decoration-none">{row.phone}</a>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={7} sm={6} xs={12}>
              <FontAwesomeIcon icon={faEnvelope} className="me-1 text-muted" size="sm" title="Email" />
              <a href={`mailto:${row.email}`} className="text-decoration-none">{row.email}</a>
            </Col>

            <Col xxl={5} xl={5} lg={5} md={5} sm={6} xs={12}>
              <FontAwesomeIcon icon={faPersonWalkingLuggage} className="me-1 text-muted" size="sm" title="Current job" />
              {row.current_job && (
                <span className="fw-bold text-primary">{row.current_job.job.repr}</span>
              )}
            </Col>
            <Col xxl={2} xl={7} lg={7} md={7} sm={6} xs={12}>
              <FontAwesomeIcon icon={faEarthEurope} className="me-1 text-muted" size="sm" title="Citizeship" />
              <span>{row.citizenship_name}</span>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={6} xs={12}>
              <FontAwesomeIcon icon={faBuilding} className="me-1 text-muted" size="sm" title="Positions" />
              {row.positions.length ? row.positions.map((p) => (
                <span key={p.id} style={{ color: p.color }} className="enum">
                  {p.repr}
                </span>
              )) : (
                <span className="text-danger">
                  No positions
                </span>
              )}
            </Col>

            <Col xxl={5} xl={7} lg={7} md={7} sm={6} xs={12}>
              <FontAwesomeIcon icon={faPersonCircleQuestion} className="me-1 text-muted" size="sm" title="Workflow" />
              {row.current_status && (
                <span style={{ color: row.current_status.workflow.color }}>
                  {row.current_status.workflow.repr}
                </span>
              )}
            </Col>
            <Col xxl={2} xl={3} lg={3} md={3} sm={3} xs={12}>
              <FontAwesomeIcon icon={faShip} className="me-1 text-muted" size="sm" title="Landing date" />
              {!!row.landing_date && (
              <>
                <DateShow value={row.landing_date} />
                -
                {row.end_date ? (<DateShow value={row.end_date} />) : (
                  <FontAwesomeIcon icon={faQuestion} className="me-1 text-muted" size="sm" />
                )}
              </>
              )}
            </Col>

            <Col xxl={5} xl={5} lg={5} md={5} sm={3} xs={12}>
              <FontAwesomeIcon icon={faUser} className="me-1 text-muted" size="sm" title="Recruiter" />
              <span>{row.author ? row.author.repr : 'self'}</span>
            </Col>

            <Col xxl={2} xl={2} lg={2} md={2} sm={3} xs={12}>
              <FontAwesomeIcon icon={faCalendar} className="me-1 text-muted" size="sm" title="Date of bitth" />
              <DateShow value={row.birth_date} />
            </Col>
            <Col xxl={3} xl={2} lg={2} md={2} sm={3} xs={12}>
              <FontAwesomeIcon icon={faVenusMars} className="me-1 text-muted" size="sm" title="Sex" />
              <span>
                {' '}
                {row.gender === 'M' ? 'Male' : 'Female'}
              </span>
            </Col>
            <Col xxl={7} xl={12} lg={12} md={12} sm={12} xs={12}>
              <FontAwesomeIcon icon={faAsterisk} className="me-1 text-muted" size="sm" title="Preferences" />
              {!!row.preferences.length && (
              <>
                {row.preferences.map((pp) => (
                  <span key={pp.id} className="enum">{pp.company_name}</span>
                ))}
              </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

    </Card>
  );
}

PeopleItemRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  showClosedInfo: PropTypes.bool,
  closed: PropTypes.bool,
  onReopen: PropTypes.func,
  onClose: PropTypes.func,
};

PeopleItemRow.defaultProps = {
  showClosedInfo: false,
  closed: false,
  onReopen: null,
  onClose: null,
};

export default PeopleItemRow;
