import React, {
  forwardRef, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, InputGroup, Spinner, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import styled from 'styled-components';
import SearchInput from './searchInput';

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const StyledDropdownMenu = styled(Dropdown.Menu)`
  max-height: min(20em, 50vh);
  overflow: auto;
  z-index: 1031;
`;

const MultipleSelector = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  isLoading, onDropDown, errors, searchable,
  className, errorAsTooltip, onFocus, onBlur, prepend, append,
  size,
}, ref) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const displayValue = useMemo(
    () => {
      if (!value) return [];
      return value.map((cur) => ({
        value: cur,
        label: values.reduce((R, v) => (v.value === cur ? v.display_name : R), ''),
      }));
    },
    [values, value],
  );

  const errored = !!errors && !!errors.length;
  const displayValues = useMemo(
    () => (search ? values.filter((v) => v.display_name.toLowerCase().indexOf(search.toLowerCase()) > -1) : values),
    [search, values],
  );
  const onSelect = useCallback(
    (eventKey, e) => {
      const vv = parseInt(eventKey, 10);

      if (!value) onChange(e, [vv]);
      else if (value.includes(vv)) onChange(e, value.filter((v) => v !== vv));
      else onChange(e, [...value, vv]);
    },
    [onChange, value],
  );
  return (
    <InputGroup size={size}>
      {prepend}
      <div
        id={id}
        ref={ref}
        className={classNames(className, 'form-control d-flex gap-2 overflow-auto', { 'is-invalid': errored })}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {displayValue.map((v) => (
          <span
            key={v.value}
            className="bg-light px-2 rounded me-1 text-dark text-nowrap"
            role="button"
            tabIndex={0}
            onDoubleClick={(e) => onSelect(String(v.value), e)}
          >
            {v.label}
          </span>
        ))}
      </div>
      {append}
      {isLoading && (
      <InputGroup.Text>
        <Spinner animation="grow" size="sm" />
      </InputGroup.Text>
      )}
      <Dropdown
        size={size || 'sm'}
        onToggle={(isOpen, e, md) => {
          if (md ? md.source !== 'select' : true) setShow(!show);
          if (isOpen && onDropDown) onDropDown(e, md);
        }}
        onSelect={onSelect}
        show={show}
      >
        <Dropdown.Toggle variant="light" disabled={disabled || readOnly} split size={size} />
        <StyledDropdownMenu align="end" className="scrollbar">
          {searchable && (
            <div className="mx-2">
              <SearchInput onSearch={setSearch} />
            </div>
          )}
          <Dropdown.Divider />
          {displayValues.map((vv) => (
            <Dropdown.Item
              key={vv.value}
              disabled={vv.disabled}
              // onClick={(e) => onChange(e, [...new Set([...value, vv.value])])}
              eventKey={vv.value}
            >
              {(value || []).includes(vv.value) ? (
                <FontAwesomeIcon icon={faCheck} size="xs" className="me-2" color="green" />
              ) : (
                <FontAwesomeIcon icon={faMinus} size="xs" className="me-2" color="#FFEEEE" />
              )}
              {vv.display_name}
            </Dropdown.Item>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
      {errors && (
      <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
        {errors.join(', ')}
      </Form.Control.Feedback>
      )}
    </InputGroup>
  );
});

MultipleSelector.propTypes = {
  value: PropTypes.arrayOf(valuePropType),
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    display_name: PropTypes.string,
    color: PropTypes.string,
    bg_color: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  searchable: PropTypes.bool,
  prepend: PropTypes.node,
  append: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'lg']),
};

MultipleSelector.defaultProps = {
  value: [],
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  isLoading: false,
  errors: null,
  searchable: true,
  prepend: null,
  append: null,
  size: 'sm',
};

export default MultipleSelector;
