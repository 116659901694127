import React, { useContext, useState } from 'react';
import {
  Container, Nav, Navbar, NavDropdown, Offcanvas,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faBookAtlas,
  faClipboardCheck, faGear,
  faHeadphones,
  faHome,
  faNewspaper,
  faShip, faUserCircle,
  faUsers, faUsersViewfinder,
} from '@fortawesome/free-solid-svg-icons';
import { LogoIcon } from '../../icons';
import AppContext from '../../providers/authProvider';

function Menu() {
  const { currentUser, logoutHandler } = useContext(AppContext);
  const isEmployee = currentUser.profile.role < 10;
  // eslint-disable-next-line no-unused-vars
  const [canvasShow, setCanvasShow] = useState(false);
  return (
    <Navbar expand="lg" data-bs-theme="light" bg="light" fixed="top" className="top-menu" collapseOnSelect>
      <Container fluid className="px-xl-5 px-3">
        <Navbar.Brand>
          <img src={LogoIcon} alt="HR-Master v2.0" height="60" className="my--3" />
        </Navbar.Brand>
        <Navbar.Toggle className="ms-auto" onClick={() => setCanvasShow((o) => !o)} />
        <Navbar.Offcanvas
          placement="end"
          show={canvasShow}
          onShow={() => setCanvasShow(true)}
          onHide={() => setCanvasShow(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="mx-auto gap-2 gap-xl-3" navbarScroll onClick={() => setCanvasShow(false)}>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/">
                  <FontAwesomeIcon icon={faHome} size="lg" className="me-2" />
                  Dashboard
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/candidates/">
                  <FontAwesomeIcon icon={faUsers} size="lg" className="me-2" />
                  Candidates
                </Nav.Link>
              </Nav.Item>
              {isEmployee && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/jobs/">
                  <FontAwesomeIcon icon={faShip} size="lg" className="me-2" />
                  Jobs
                </Nav.Link>
              </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link as={NavLink} to="/interviews/">
                  <FontAwesomeIcon icon={faHeadphones} size="lg" className="me-2" />
                  Interviews
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/tasks/">
                  <FontAwesomeIcon icon={faClipboardCheck} size="lg" className="me-2" />
                  Tasks
                </Nav.Link>
              </Nav.Item>
              {isEmployee && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/manuals/">
                  <FontAwesomeIcon icon={faBookAtlas} size="lg" className="me-2" />
                  Manuals
                </Nav.Link>
              </Nav.Item>
              )}
              {isEmployee && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/reports/">
                  <FontAwesomeIcon icon={faNewspaper} size="lg" className="me-2" />
                  Reports
                </Nav.Link>
              </Nav.Item>
              )}
              {isEmployee && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/settings/">
                  <FontAwesomeIcon icon={faGear} size="lg" className="me-2" />
                  Settings
                </Nav.Link>
              </Nav.Item>
              )}
              {isEmployee && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/pool/">
                  <FontAwesomeIcon icon={faUsersViewfinder} size="lg" className="me-2" />
                  Pool
                </Nav.Link>
              </Nav.Item>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Nav className="ms-2 ms-lg-auto user-menu">
          <NavDropdown
            title={(
              <div className="d-inline-flex flex-column align-items-center_">
                <FontAwesomeIcon icon={faUserCircle} size="2x" alt={currentUser.username} />
              </div>
          )}
            align="end"
          >
            <NavDropdown.Header className="d-inline-flex gap-1">
              <span>{currentUser.first_name}</span>
              <span>{currentUser.last_name}</span>
              <span>
                (
                {currentUser.username}
                )
              </span>
            </NavDropdown.Header>
            <NavDropdown.Item onClick={() => logoutHandler()}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2" />
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Menu;
