import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.3rem;
  box-sizing: border-box;
  padding: 8px;
`;

export default TextArea;
