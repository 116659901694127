import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { SaveButton, DeleteButton, CloseButton } from '../../../../components/Styled/Buttons';
import { backendURI } from '../list/uri';
import useEditor from '../../../BasicEditor/hook/editor';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';

function WorkFlowEditor({
  id, onSave, onClose,
}) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, options,
  } = useEditor({
    id,
    backendURL: backendURI,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const colorProps = editorHooks.useSelectorInputProps('color', data, fields, fieldErrors, actions.onChange);
  const defaultProps = editorHooks.useCheckboxInputProps('default', data, fields, fieldErrors, actions.onChange);
  const cancelProps = editorHooks.useCheckboxInputProps('cancel_candidate', data, fields, fieldErrors, actions.onChange);
  const submitProps = editorHooks.useCheckboxInputProps('submit_candidate', data, fields, fieldErrors, actions.onChange);
  const showInJobProps = editorHooks.useCheckboxInputProps('show_in_job_info', data, fields, fieldErrors, actions.onChange);
  const isPoolProps = editorHooks.useCheckboxInputProps('is_pool', data, fields, fieldErrors, actions.onChange);
  const poolDefaultProps = editorHooks.useCheckboxInputProps('pool_default', data, fields, fieldErrors, actions.onChange);
  return (
    <Container fluid>
      <EditorContainer
        title={data.name}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        {options.description && (
        <p className="text-muted small">
          {options.description}
        </p>
        )}
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isNew} />
          <CloseButton onClick={() => onClose()} />
        </Nav>
        <Row>
          <Col md={6}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col md={6}>
            <EditorControls.SelectorInput {...colorProps} />
          </Col>
          <Col md={6}>
            <span className="me-2">Preview:</span>
            <span className="me-2" style={{ color: data.color }}>
              {data.name}
            </span>
          </Col>
          <Col md={6}>
            <EditorControls.CheckboxInput {...defaultProps} />
            <EditorControls.CheckboxInput {...cancelProps} />
            <EditorControls.CheckboxInput {...submitProps} />
            <EditorControls.CheckboxInput {...showInJobProps} />
            <EditorControls.CheckboxInput {...isPoolProps} />
            <EditorControls.CheckboxInput {...poolDefaultProps} />
          </Col>
        </Row>
      </EditorContainer>
    </Container>
  );
}

WorkFlowEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

WorkFlowEditor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default WorkFlowEditor;
