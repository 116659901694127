import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import useSubscribtion from '../../../BaseSubscriptions/hook';

function PeopleSubscribtion({ URI }) {
  const { subscribed, onSubscribe, onUnSubscribe } = useSubscribtion({ url: URI });
  return (
    <Alert variant="light">
      <Alert.Heading>Subscription</Alert.Heading>
      <div className="d-flex">
        <div className="me-3">
          <FontAwesomeIcon icon={faNewspaper} size="5x" />
        </div>
        <div className="flex-fill">
          <p>
            {subscribed
              ? 'You are subscribed to change notifications for this candidate.'
              : 'You are not subscribed to change notifications for this candidate.'}
          </p>
          <div className="mt-2">
            {subscribed ? (
              <Alert.Link className="text-danger" onClick={onUnSubscribe}>Unsubscribe me</Alert.Link>
            ) : (
              <Alert.Link onClick={onSubscribe}>Subscribe me</Alert.Link>
            )}
          </div>
        </div>
      </div>

    </Alert>
  );
}

PeopleSubscribtion.propTypes = {
  URI: PropTypes.string.isRequired,
};

export default PeopleSubscribtion;
