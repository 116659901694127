import { PeopleList as List, PoolPeopleList } from './list';
import Editor from './editor/editor';
import RouterEditor from './editor/routerEditor';

const instance = {
  List,
  Editor,
  RouterEditor,
  PoolPeopleList,
};
export default instance;
