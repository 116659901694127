import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledButton, StyledImg, StyledText } from './Styled';

const StyledOtherButton = styled(StyledButton)`
  height: 2.5em;
  width: fit-content;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: radial-gradient(farthest-side ellipse at top left,white,#bdc0c5);
  }
`;

export const OtherButton = React.memo(({
  content, onClick, disabled, icon,
}) => (
  <StyledOtherButton onClick={onClick} disabled={disabled} title={content}>
    {icon && (
      <StyledImg src={icon} alt={content} />
    )}
    {content && (
      <StyledText>{content}</StyledText>
    )}
  </StyledOtherButton>
));

OtherButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

OtherButton.defaultProps = {
  onClick: () => null,
  content: null,
  disabled: false,
  icon: null,
};

export default OtherButton;
