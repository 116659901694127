import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SettingsItem({
  header, to, description, icon,
}) {
  const [act, setAct] = useState(false);
  return (
    <ListGroup.Item as={Link} to={to} action onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
      <div className="d-flex">
        {icon && (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={icon} size="2x" className="me-3 text-primary-emphasis" bounce={act} />
          </div>
        )}
        <div className="flex-fill">
          <h5 className="text-primary">{header}</h5>
          <p className="small text-muted">{description}</p>
        </div>
      </div>
    </ListGroup.Item>
  );
}

SettingsItem.propTypes = {
  header: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.shape({}),
};

SettingsItem.defaultProps = {
  description: '',
  icon: null,
};

export default SettingsItem;
