import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../BasicEditor/tableControls';

function PreferenceRow({
  row, fields, index, errors, onChange, onDeleteRow, companies,
}) {
  const companyProps = tableHooks.useSelectorInputProps('company', row, fields, errors, onChange, index);

  return (
    <div className="d-flex flex-nowrap align-items-baseline gap-1">
      <Row className="ms-1 flex-fill my-1">
        <Col>
          <TableControls.SelectorInput {...companyProps} values={companies.selectorValues} />
        </Col>
      </Row>
      <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
        <FontAwesomeIcon icon={faTimes} title="Delete record" />
      </Button>
    </div>
  );
}

PreferenceRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  companies: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

PreferenceRow.defaultProps = {
  errors: {},
};

export default memo(PreferenceRow);
