/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useTablePart from '../../../BasicEditor/hook/tablepart';
import EducationHeader from './header';
import EducationRow from './row';

function Educations({
  onChange, data, fields, errors,
}) {
  const {
    tableData, tableActions,
    tableContainerRef, tableErrors,
    tableFields,
  } = useTablePart({
    data, onChange, tableName: 'educations', errors, fields,
  });
  return (
    <Card className="border-0 ">
      <Card.Header>
        <EducationHeader fields={tableFields} />
      </Card.Header>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, rowIndex) => (
          <EducationRow
            row={row}
            key={rowIndex}
            errors={tableErrors[rowIndex]}
            fields={tableFields}
            onChange={tableActions.onCellChange}
            onDeleteRow={tableActions.onDeleteRow}
            index={rowIndex}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Button variant="outline-primary" onClick={() => tableActions.onAddRow()}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add education
        </Button>
      </Card.Footer>
    </Card>
  );
}

Educations.propTypes = {
  data: PropTypes.shape({
  }),
};

Educations.defaultProps = {
  data: [],
};

export default Educations;
