import React from 'react';
import PropTypes from 'prop-types';
import { ClearIcon } from '../../../icons';
import { StyledButton, StyledImg, StyledText } from './Styled';

export function CancelButton({
  content, onClick, disabled, ...props
}) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} title={content} {...props}>
      <StyledImg src={ClearIcon} alt={content} />
      <StyledText>{content}</StyledText>
    </StyledButton>
  );
}

CancelButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CancelButton.defaultProps = {
  onClick: () => null,
  content: 'Cancel',
  disabled: false,
};

export default CancelButton;
