import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { childrenPropType } from '../../../const/propTypes';

function WidgetContainer({
  headerText, children, className, extra,
}) {
  return (
    <div className={classNames(className, 'd-flex flex-column mb-2')}>
      <div className="border border-bottom-0 rounded-top py-1 px-2 bg-dark-subtle text-dark fw-bold ">
        {headerText}
        {extra}
      </div>
      <div className="d-flex flex-column h-100 border py-3 px-2 bg-white">
        {children}
      </div>
    </div>
  );
}

WidgetContainer.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  extra: PropTypes.element,
};

WidgetContainer.defaultProps = {
  className: '',
  extra: null,
};

export default WidgetContainer;
