import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function DetailsHeader({
  fields,
}) {
  return (
    <Row className="fw-bold">
      <Col sm={11} className="text-center">
        {fields.name.label}
      </Col>
      <Col />
    </Row>
  );
}

DetailsHeader.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default DetailsHeader;
