import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const DatePickerInput = forwardRef(
  ({
    prepend, value, onClick, vValue, disabled, id, placeholder,
    readOnly, required, tabIndex, title, errors, errorAsTooltip,
    onBlur, onChange, onFocus, onKeyDown, className, size,
  }, ref) => {
    const dValue = useMemo(
      () => vValue || value,
      [vValue, value],
    );
    const errored = errors && errors.length;

    return (
      <div className={className}>
        {prepend}
        <FormControl
          id={id}
          ref={ref}
          value={dValue}
          isInvalid={errored}
          maxLength={10}
          onClick={onClick}
          disabled={disabled}
          readOnly={readOnly || !!vValue}
          placeholder={placeholder}
          required={required}
          tabIndex={tabIndex}
          title={title}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          size={size}
          className={className}
        />
        {errors && (
        <FormControl.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </FormControl.Feedback>
        )}
      </div>

    );
  },
);

DatePickerInput.propTypes = {
  prepend: PropTypes.node,
  value: PropTypes.string,
  onClick: PropTypes.func,
  vValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorAsTooltip: PropTypes.bool,
  className: PropTypes.string,
};

DatePickerInput.defaultProps = {
  prepend: '',
  value: null,
  vValue: null,
  onClick: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  id: null,
  placeholder: null,
  disabled: null,
  readOnly: null,
  required: null,
  tabIndex: null,
  title: null,
  errors: null,
  errorAsTooltip: false,
  className: '',
};

export default DatePickerInput;
