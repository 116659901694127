import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import App from './containers/Root';
import CV from './containers/SendCV';
import * as serviceWorker from './serviceWorker';

registerLocale('en', en);

if (document.getElementById('root')) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <App />,
  );
}
if (document.getElementById('hrm-cv')) {
  const cvRoot = ReactDOM.createRoot(document.getElementById('hrm-cv'));
  cvRoot.render(
    <CV />,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
