import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FileViewer from './file-viewer';

function FilePreview({ filePath, fileType, className }) {
  if (!filePath) return null;
  return (
    <FileViewer
      filePath={filePath}
      fileType={fileType}
      className={className}
    />
  );
}

FilePreview.propTypes = {
  filePath: PropTypes.string,
  fileType: PropTypes.string,
  className: PropTypes.string,
};

FilePreview.defaultProps = {
  className: '',
  filePath: '',
  fileType: '',
};

export default memo(FilePreview);
