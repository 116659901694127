import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

function ReportItem({
  header, to, description, icon,
}) {
  return (
    <Card className="d-flex flex-row">
      <div className="py-1 px-3 d-flex align-items-center text-secondary">
        {icon && (
        <FontAwesomeIcon icon={icon} size="3x" />
        )}
      </div>
      <div className="flex-fill">
        <Card.Body>
          <Card.Title as={Link} to={to} className="h4 text-primary text-decoration-none stretched-link">{header}</Card.Title>
          <Card.Text className="small text-muted">{description}</Card.Text>
        </Card.Body>
      </div>
    </Card>
  );
}

ReportItem.propTypes = {
  header: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.shape({}),
};

ReportItem.defaultProps = {
  description: '',
  icon: null,
};

export default ReportItem;
