import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

export const NewButton = React.memo(({ content, onClick, disabled }) => (
  <Button variant="primary" onClick={onClick} disabled={disabled} title={content}>
    <FontAwesomeIcon icon={faFile} className="me-2" />
    {content}
  </Button>
));

NewButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NewButton.defaultProps = {
  content: 'Create new',
  disabled: false,
};

export default NewButton;
