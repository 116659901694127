import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import AppContext from '../../providers/authProvider';
import api from '../../api/req';

const useSubscribtion = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const { auth } = useContext(AppContext);

  const loadStatus = useCallback(
    async () => {
      const r = await api.get(url, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, url],
  );

  useEffect(() => {
    setLoading(true);
    setErr(null);
    loadStatus()
      .then((d) => setSubscribed(d.subscribed))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [loadStatus]);

  const onSubscribe = useCallback(
    () => {
      const load = async () => {
        const r = await api.post(url, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      load()
        .then(() => loadStatus())
        .then((d) => setSubscribed(d.subscribed))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, loadStatus, url],
  );
  const onUnSubscribe = useCallback(
    () => {
      const load = async () => {
        const r = await api.delete(url, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(true);
      setErr(null);
      load()
        .then(() => loadStatus())
        .then((d) => setSubscribed(d.subscribed))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, loadStatus, url],
  );
  return {
    loading,
    err,
    subscribed,
    onSubscribe,
    onUnSubscribe,
  };
};

export default useSubscribtion;
