import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledLoaderContainer = styled.div`
  z-index: 1031;
`;

function Loader({ text, relative }) {
  return (
    <StyledLoaderContainer
      className={classNames('top-50 start-50 translate-middle p-3 shadow-lg rounded bg-white d-flex align-items-center', {
        'position-fixed': !relative,
        'position-absolute': relative,
      })}
      role="status"
    >
      <Spinner animation="border" className="me-2" size="sm" />
      <span className="small">
        {text}
      </span>
    </StyledLoaderContainer>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
  relative: PropTypes.bool,
};

Loader.defaultProps = {
  text: 'Loading...',
  relative: false,
};

export default Loader;
