import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function EducationHeader({
  fields,
}) {
  return (
    <Row className="fw-bold">
      <Col sm={11}>
        <Row>
          <Col sm={2} className="text-center">
            {fields.date_from.label}
          </Col>
          <Col sm={2} className="text-center">
            {fields.date_to.label}
          </Col>
          <Col sm={4} className="text-center">
            {fields.institution.label}
          </Col>
          <Col sm={4} className="text-center">
            {fields.diploma.label}
          </Col>
          <Col sm={12} className="text-center">
            {fields.qualification.label}
          </Col>
        </Row>
      </Col>
      <Col />
    </Row>
  );
}

EducationHeader.propTypes = {
  fields: PropTypes.shape({
    type: PropTypes.shape({
      label: PropTypes.string,
    }),
    contact: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default EducationHeader;
