import React, {
  useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import StringInput from './stringInput';
import { debounce } from '../../api/utils';

function SearchInput({ onSearch }) {
  const [searchText, setSearchText] = useState('');
  const debouncedOnSearch = useMemo(
    () => debounce(onSearch, 500),
    [onSearch],
  );
  const onSrch = useCallback(
    (e, v) => {
      setSearchText(v);
      debouncedOnSearch(v);
    },
    [debouncedOnSearch, setSearchText],
  );

  return (
    <StringInput
      value={searchText}
      onChange={onSrch}
      prepend={(
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} title="Search" />
        </InputGroup.Text>
    )}
    />
  );
}

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchInput;
