import List from './list';
import Editor from './editor';
import RouterEditor from './withRouter';

const instance = {
  List,
  Editor,
  RouterEditor,

};
export default instance;
