import React, {
  Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Alert, Card, Row, Col, Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import AppContext from '../../../../providers/authProvider';
import api from '../../../../api/req';
import { getFileTypeFromURL } from '../../../../api/utils';
import Folders from './folders';
import Files from './files';
import { FileUpload, FilePreview } from '../../../../components/Controls';
import Loader from '../../../../components/Styled/Misc/loader';
import CheckListEditor from '../checklsits';
import { useLayoutGridHook } from '../../../../api/hooks';

function LoadError(message) {
  this.name = 'load error';
  this.message = message;
}

const StyledDiv = styled.div`
  height: ${({ $top }) => `calc(100vh - ${$top}px - 2rem)`};
  @media (max-width: 767px) {
    & {
        height: auto!important;
    }
  }
`;

function FilesNFolders({ peopleId }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragState, setDragState] = useState({
    draggedOverFolder: null,
    draggedFile: null,
    dragAllowed: false,
  });
  // plainFolders: [],

  const [top, setTop] = useState(0);
  const resizeObsever = useRef(
    new ResizeObserver((enteries) => enteries.forEach((ent) => {
      const newTop = ent.target.getBoundingClientRect().top;
      if (top !== newTop) setTop(newTop);
    })),
  );
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      resizeObsever.current.observe(node.parentNode);
      setTop(node.getBoundingClientRect().top);
    }
  }, []);

  const loadFolders = useCallback(
    async () => {
      const r = await api.get(`/api/folders/?people=${peopleId}`, auth);
      if (!r.ok) throw new LoadError(r.statusText);
      const flds = await r.json();
      const getParentFolders = (parent, level) => flds
        .filter((f) => f.parent === parent)
        .reduce((R, f) => [
          ...R,
          { ...f, level },
          ...getParentFolders(f.id, level + 1),
        ], []);
      return getParentFolders(null, 0);
    },
    [auth, peopleId],
  );

  const loadFiles = useCallback(
    async () => {
      const r = await api.get(`/api/files/?people=${peopleId}`, auth);
      if (!r.ok) throw new LoadError(r.statusText);
      return r.json();
    },
    [auth, peopleId],
  );

  useEffect(() => {
    setErr(null);
    setLoading(true);
    loadFolders()
      .then((fld) => {
        setFolders(fld);
        return loadFiles();
      })
      .then((fls) => {
        setFiles(fls);
      })
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [loadFiles, loadFolders]);

  const onFolderSelect = useCallback(
    (e, id) => {
      setSelectedFolder(id);
      setSelectedFile(null);
    },
    [],
  );

  const onAddFolder = useCallback(
    (e, folder) => {
      const oper = async () => {
        const r = await api.post('/api/folders/', auth, { people: peopleId, ...folder });
        if (!r.ok) throw new LoadError(r.statusText);
        return true;
      };
      setLoading(true);
      setErr(false);
      oper()
        .then(() => loadFolders())
        .then((fld) => setFolders(fld))
        .catch((er) => setErr(er.message))
        .finally(() => setLoading(false));
    },
    [auth, loadFolders, peopleId],
  );

  const onEditFolder = useCallback(
    async (e, { id, ...folder }) => {
      const oper = async () => {
        const r = await api.put(`/api/folders/${id}/`, auth, { people: peopleId, ...folder });
        if (!r.ok) throw new LoadError(r.statusText);
        return true;
      };
      oper()
        .then(() => loadFolders())
        .then((fld) => setFolders(fld))
        .catch((er) => setErr(er.message))
        .finally(() => setLoading(false));
    },
    [auth, loadFolders, peopleId],
  );

  const onDeleteFolder = useCallback(
    async (e, id) => {
      const oper = async () => {
        const r = await api.delete(`/api/folders/${id}/`, auth);
        if (!r.ok) throw new LoadError(r.statusText);
        return true;
      };
      oper()
        .then(() => loadFolders())
        .then((fld) => setFolders(fld))
        .then(() => setSelectedFolder(null))
        .catch((er) => setErr(er.message))
        .finally(() => setLoading(false));
    },
    [auth, loadFolders],
  );

  const onAddFile = useCallback(
    (e, fls) => {
      const newFiles = fls.map((f) => ({
        file: f,
        result: null,
      }));

      const reader = new FileReader();

      const onload = (f) => () => {
        f.result = reader.result;
        readFile();
      };

      const readFile = () => {
        const firstFile = newFiles.filter((f) => !f.result);
        if (firstFile.length) {
          const f = firstFile[0];
          reader.onload = onload(f);
          reader.readAsDataURL(f.file);
        } else {
          const fileUploader = async (f) => {
            const r = await api.post('/api/files/', auth, {
              people: peopleId,
              file: {
                file: f.result,
                filename: f.file.name,
              },
              name: f.file.name,
              comment: '',
              folder: selectedFolder,
            });
            if (!r.ok) throw new LoadError(r.statusText);
            return true;
          };
          Promise.allSettled(newFiles.map((f) => fileUploader(f)))
            .then((result) => {
              const errors = result.filter((r) => r.status === 'rejected');
              if (errors.length) throw new LoadError(errors.map((r) => r.reason).join(', '));
              return loadFiles();
            })
            .then((flss) => setFiles(flss))
            .catch((er) => setErr(er.message))
            .finally(() => setLoading(false));
        }
      };

      readFile();
    },
    [auth, loadFiles, peopleId, selectedFolder],
  );

  const onEditFile = useCallback(
    (e, { id, ...file }) => {
      const oper = async () => {
        const r = await api.patch(`/api/files/${id}/`, auth, { people: peopleId, ...file });
        if (!r.ok) throw new LoadError(r.statusText);
        return true;
      };
      oper()
        .then(() => setFiles((o) => o.map((f) => (f.id === id ? { ...f, ...file } : f))))
        .catch((er) => setErr(er.message))
        .finally(() => setLoading(false));
    },
    [auth, peopleId],
  );
  const onDeleteFile = useCallback(
    (e, id) => {
      const oper = async () => {
        const r = await api.delete(`/api/files/${id}/`, auth);
        if (!r.ok) throw new LoadError(r.statusText);
        return true;
      };
      oper()
        .then(() => {
          setSelectedFile(null);
          setFiles((o) => o.filter((f) => f.id !== id));
        })
        .catch((er) => setErr(er.message))
        .finally(() => setLoading(false));
    },
    [auth],
  );

  const onDragEnd = useCallback(
    ({ draggableId, destination, source }) => {
      if (destination) {
        const { index, droppableId } = destination;
        if (source.droppableId === 'Files' && droppableId === 'Folders' && index <= folders.length) {
          const currentFile = files.filter((f) => `file-${f.id}` === draggableId).reduce((R, f) => f, null);
          const currentFolderId = index === 0 ? null : folders[index - 1].id;
          if (currentFile) {
            const { people, file, ...r } = currentFile;
            onEditFile(null, { ...r, folder: currentFolderId });
          }

          setDragState({
            draggedFile: null,
            draggedOverFolder: null,
            dragAllowed: false,
          });
        }
      }
    },
    [files, folders, onEditFile],
  );

  const onDragUpdate = useCallback(
    ({ draggableId, destination, source }) => {
      if (destination) {
        const { index, droppableId } = destination;
        if (source.droppableId === 'Files' && droppableId === 'Folders' && index <= folders.length) {
          const currentFile = files.filter((f) => `file-${f.id}` === draggableId).reduce((R, f) => f, null);
          const currentFolderId = index === 0 ? null : folders[index - 1].id;
          setDragState({
            draggedFile: draggableId,
            draggedOverFolder: currentFolderId,
            dragAllowed: currentFile.folder !== currentFolderId,
          });
        } else {
          setDragState({
            draggedFile: draggableId,
            draggedOverFolder: null,
            dragAllowed: false,
          });
        }
      }
    },
    [files, folders],
  );

  const onDownloadFolder = useCallback(
    () => {
      const oper = async () => {
        const r = await api.get('/api/folders/download_folder/', auth, { people: peopleId, folder: selectedFolder });
        if (!r.ok) throw new LoadError(r.statusText);
        return r.json();
      };

      setErr(null);
      setLoading(true);
      oper()
        .then((d) => {
          const fStr = ';base64,';
          const idx = d.file.indexOf(fStr);
          const fType = d.file.slice(5, idx);
          const fContent = d.file.slice(idx + fStr.length);
          const byteCharacters = atob(fContent);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i += 1) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: fType });
          const URL = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = URL;
          a.download = 'folder.zip';
          a.click();
          window.URL.revokeObjectURL(URL);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, peopleId, selectedFolder],
  );
  const currentFiles = useMemo(
    () => files.filter((f) => f.folder === selectedFolder),
    [files, selectedFolder],
  );

  const currentFile = useMemo(
    () => files.filter((f) => f.id === selectedFile).reduce((R, f) => f, {}),
    [files, selectedFile],
  );

  const [filePreviewOpened, setFilePreviewOpened] = useState(false);

  const { screenWidth } = useLayoutGridHook();
  const isMobile = screenWidth < 768;

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragUpdate={onDragUpdate}
    >
      <StyledDiv ref={measuredRef} $top={top} className="d-flex flex-column overflow-hidden">
        {loading && (
        <Loader />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}
        <Row className="flex-fill h-50 overflow-hidden">
          <Col md={3} className="d-flex flex-column h-100 overflow-hidden">
            <Card className="flex-fill h-25">
              <Card.Header>
                <Card.Title>Folders</Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Folders
                  folders={folders}
                  selected={selectedFolder}
                  onSelect={onFolderSelect}
                  onAddFolder={onAddFolder}
                  onEditFolder={onEditFolder}
                  onDeleteFolder={onDeleteFolder}
                  draggedOverFolder={dragState.draggedOverFolder}
                  dragAllowed={dragState.dragAllowed}
                  onDownloadFolder={onDownloadFolder}
                />
              </Card.Body>
            </Card>
            <Card className="flex-fill h-50">
              <Card.Header>
                <Card.Title>Files</Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Files
                  files={currentFiles}
                  folders={folders}
                  selected={selectedFile}
                  onSelect={(e, id) => {
                    setSelectedFile(id);
                    if (isMobile) setFilePreviewOpened(true);
                  }}
                  onEditFile={onEditFile}
                  onDeleteFile={onDeleteFile}
                  dragAllowed={dragState.dragAllowed}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="h-100 overflow-hidden d-none d-md-block">
            <FileUpload
              multiple
              onFileUpload={onAddFile}
              className="h-100 overflow-auto"
            >
              <div className="flex-fill overflow-auto d-flex flex-column">
                <h3 className="text-uppercase">Select file to preview</h3>
                {currentFile.file && (
                <>
                  {currentFile.comment && (
                  <div>{currentFile.comment}</div>
                  )}
                  <FilePreview
                    filePath={currentFile.file}
                    fileType={getFileTypeFromURL(currentFile.file)}
                    className="flex-fill"
                  />
                </>
                )}
              </div>
            </FileUpload>
          </Col>
          <Col md={3} className="overflow-auto h-100">
            <CheckListEditor peopleId={peopleId} />
          </Col>
        </Row>
      </StyledDiv>
      <Modal show={filePreviewOpened} onHide={() => setFilePreviewOpened(false)} scrollable>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFile.comment && (
          <div>{currentFile.comment}</div>
          )}
          <FilePreview
            filePath={currentFile.file}
            fileType={getFileTypeFromURL(currentFile.file)}
            className="flex-fill"
          />
        </Modal.Body>
      </Modal>
    </DragDropContext>
  );
}

FilesNFolders.propTypes = {
  peopleId: PropTypes.number.isRequired,
};

export default memo(FilesNFolders);
