import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from './context';
import { childrenPropType } from '../../const/propTypes';

function AppContextProvider({
  children, currentUser, tokens, logoutHandler, refreshHandler, currentUserRefresh,
}) {
  const getAuth = useCallback(
    () => (currentUser ? ({
      access: tokens.current.accessToken,
      onRefresh: refreshHandler,
    }) : () => null),
    [currentUser, refreshHandler, tokens],
  );
  const refreshCurrentUser = useCallback(
    () => (
      currentUserRefresh
      && currentUserRefresh(tokens.current.accessToken, tokens.current.refreshToken)
    ),
    [currentUserRefresh, tokens],
  );
  const appContextValue = useMemo(
    () => ({
      currentUser,
      logoutHandler,
      refreshHandler,
      refreshCurrentUser,
      auth: getAuth,
    }),
    [currentUser, getAuth, logoutHandler, refreshHandler, refreshCurrentUser],
  );

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: childrenPropType.isRequired,
  currentUser: PropTypes.shape({
  }),
  tokens: PropTypes.shape({
    current: PropTypes.shape({
      accessToken: PropTypes.string,
      refreshToken: PropTypes.string,
    }),
  }).isRequired,
  logoutHandler: PropTypes.func,
  refreshHandler: PropTypes.func,
  currentUserRefresh: PropTypes.func,
};

AppContextProvider.defaultProps = {
  currentUser: null,
  currentUserRefresh: null,
  logoutHandler: null,
  refreshHandler: null,
};
export default AppContextProvider;
