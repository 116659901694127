import React from 'react';
import PropTypes from 'prop-types';
import { NextIcon } from '../../../icons';
import { StyledCommandPanelButton, StyledImg, StyledText } from './Styled';

export const NextButton = React.memo(({ content, onClick, disabled }) => (
  <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={NextIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledCommandPanelButton>
));

NextButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

NextButton.defaultProps = {
  onClick: () => null,
  content: 'Next',
  disabled: false,
};

export default NextButton;
