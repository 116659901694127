import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../BasicEditor/tableControls';

function WorkExpirienceRow({
  row, fields, index, errors, onChange, positions, onDeleteRow,
}) {
  const positionProps = tableHooks.useSelectorInputProps('position', row, fields, errors, onChange, index);
  const dateFromProps = tableHooks.useDateInputProps('date_from', row, fields, errors, onChange, index);
  const dateToProps = tableHooks.useDateInputProps('date_to', row, fields, errors, onChange, index);
  const companyProps = tableHooks.useStringInputProps('company', row, fields, errors, onChange, index);
  const leavingProps = tableHooks.useStringInputProps('leaving_reason', row, fields, errors, onChange, index);

  return (
    <div className="d-flex flex-nowrap align-items-baseline gap-1">
      <Row className="ms-1 flex-fill my-1">
        <Col sm={10}>
          <TableControls.SelectorInput {...positionProps} values={positions.selectorValues} />
        </Col>
        <Col sm={2}>
          <TableControls.DateInput {...dateFromProps} />
        </Col>
        <Col sm={10}>
          <TableControls.StringInput {...companyProps} />
        </Col>
        <Col sm={2}>
          <TableControls.DateInput {...dateToProps} />
        </Col>
        <Col>
          <TableControls.StringInput {...leavingProps} />
        </Col>
      </Row>
      <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
        <FontAwesomeIcon icon={faTimes} title="Delete record" />
      </Button>
    </div>
  );
}

WorkExpirienceRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  positions: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

WorkExpirienceRow.defaultProps = {
  errors: {},
};

export default memo(WorkExpirienceRow);
