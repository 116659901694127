import React, {
  useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Col, Container, ListGroup, ListGroupItem, Nav, Row,
} from 'react-bootstrap';
import {
  Loader,
} from '../../../../components/Styled/Misc';
import AppContext from '../../../../providers/authProvider';
import { SaveButton, DeleteButton } from '../../../../components/Styled/Buttons';
import api from '../../../../api/req';
import { useDict } from '../../../BaseLister/hook';
import useEditor from '../../../BasicEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';

function ChecklistEditor({
  id, peopleId, onSave, onDelete,
}) {
  const cheklisttemplates = useDict({ url: '/api/cat/checklist_templates/' });
  const { auth } = useContext(AppContext);
  const {
    data, actions, fields, fieldErrors, loading, systemErrors,
  } = useEditor({
    id,
    backendURL: '/api/checklists/',
    onSaveCallBack: onSave,
  });
  const templateProps = editorHooks.useSelectorInputProps('template_id', data, fields, fieldErrors, actions.onChange);
  const landingDateProps = editorHooks.useDateInputProps('landing_date', data, fields, fieldErrors, actions.onChange);
  const {
    onDelete: onDeleteAction, onLoading, onErr, onChange,
  } = actions;

  useEffect(() => {
    if (data.people_id !== peopleId) onChange({ people_id: peopleId });
  }, [data.people_id, onChange, peopleId]);

  const delCheckList = useCallback(
    () => {
      onDeleteAction();
      onDelete();
    },
    [onDelete, onDeleteAction],
  );

  const updateTemplate = useCallback(
    (templateId) => {
      const loader = async () => {
        const r = await api.get(`/api/cat/checklist_templates/${templateId}/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      onLoading(true);
      loader()
        .then((d) => {
          onChange((o) => {
            const oldVls = o.details.reduce((R, td) => ({ ...R, [td.template_detail]: td }), {});
            return {
              ...o,
              details: d.details.map((td) => ({
                checked: false,
                required: false,
                ...(oldVls[td.id] || {}),
                template_detail: td.id,
                name: td.name,
              })),
            };
          });
        })
        .catch((e) => onErr(`${e.status} ${e.statusText}`))
        .finally(() => onLoading(false));
    },
    [auth, onChange, onErr, onLoading],
  );
  useEffect(() => {
    if (data.template_id) updateTemplate(data.template_id);
  }, [data.template_id, updateTemplate]);
  const onSetTD = useCallback(
    (value, tdId) => onChange((o) => ({
      ...o,
      details: o.details.map((td) => (td.template_detail === tdId ? {
        ...td,
        required: value,
      } : td)),
    })),
    [onChange],
  );
  return (
    <Container fluid className="position-relative">
      {loading && (
      <Loader relative />
      )}
      {systemErrors && (
      <Alert variant="danger" onClose={() => actions.onClearErrs()}>
        {systemErrors}
      </Alert>
      )}
      <Row>
        <Col md={6}>
          <EditorControls.SelectorInput {...templateProps} values={cheklisttemplates.selectorValues} label="template" />
        </Col>
        {data.template_id && (
        <>
          <Col md={6}>
            <EditorControls.DateInput {...landingDateProps} />
          </Col>
          <Col sm={12}>
            <p className="text-primary text-muted">
              Please, set landing date and check required items:
            </p>
            <ListGroup>
              {data.details.map((td) => (
                <ListGroupItem key={td.id} action>
                  <EditorControls.CheckboxInput
                    label={td.name}
                    value={td.required}
                    onChange={(e, v) => onSetTD(v, td.template_detail)}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </>
        )}

      </Row>
      <Nav className="gap-2 my-2">
        <SaveButton onClick={() => actions.onSave()} />
        <DeleteButton onClick={() => delCheckList()} />
      </Nav>
    </Container>
  );
}
ChecklistEditor.propTypes = {
  id: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  peopleId: PropTypes.number.isRequired,
};

export default ChecklistEditor;
