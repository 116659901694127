import React, {
  useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Nav, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faFileLines, faTimes, faUpDownLeftRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { EditorControls } from '../../../BasicEditor/editorControls';

function getFileIcon(isDragging, DragOver, dragAllowed) {
  if (!isDragging) return faFileLines;
  if (DragOver === 'Folders' && dragAllowed) return faUpDownLeftRight;
  return faTimes;
}

function Files({
  files, folders, onSelect, selected, onEditFile, onDeleteFile, dragAllowed,
}) {
  const [editedFile, setEditedFile] = useState(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(null);
  const currentFile = useMemo(
    () => files.filter((f) => f.id === selected).reduce((R, f) => f, {}),
    [files, selected],
  );

  const folderValues = useMemo(
    () => folders.map((f) => ({ value: f.id, display_name: f.name })),
    [folders],
  );

  return (
    <div className="d-flex flex-column h-100">
      <Nav className="gap-2">
        <Button
          size="sm"
          variant="outline-primary"
          disabled={!selected}
          onClick={() => {
            setEditedFile({
              fileName: currentFile.name,
              fileComment: currentFile.comment,
              fileFolder: currentFile.folder,
            });
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button size="sm" variant="outline-danger" onClick={() => setDeleteModalOpened(true)} disabled={!selected}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Nav>
      <Modal show={deleteModalOpened} onHide={() => setDeleteModalOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deleting &quot;
            {currentFile.name}
            &quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure to delete file ${currentFile.name}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={(e) => {
              onDeleteFile(e, selected);
              setDeleteModalOpened(false);
            }}
          >
            Yes, sure
          </Button>
          <Button variant="secondary" onClick={() => setDeleteModalOpened(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Droppable droppableId="Files">
        {(provided) => (
          <div className="flex-fill py-1 px-2 border bg-white h-100 overflow-auto" {...provided.droppabeProps} ref={provided.innerRef}>
            {files.map((f, index) => (
              <Draggable key={f.id} draggableId={`file-${f.id}`} index={index}>
                {(dragProvided, snapshot) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                  <div
                    role="listitem"
                    key={f.id}
                    className={classNames('px-2 py-1 rounded ', `ps-${f.level}`, { 'bg-secondary-subtle': f.id === selected })}
                    onClick={(e) => onSelect(e, f.id)}
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                  >
                    <FontAwesomeIcon icon={getFileIcon(snapshot.isDragging, snapshot.draggingOver, dragAllowed)} className="me-2" />
                    {f.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {editedFile && (
      <Modal show={!!editedFile} onHide={() => setEditedFile(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{editedFile.fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorControls.StringInput
            label="Name"
            value={editedFile.fileName}
            onChange={(e, v) => setEditedFile((o) => ({ ...o, fileName: v }))}
          />
          <EditorControls.SelectorInput
            label="Folder"
            value={editedFile.fileFolder}
            onChange={(e, v) => setEditedFile((o) => ({ ...o, fileFolder: v }))}
            values={folderValues}
            required={false}
            nullLabel="root"
          />
          <EditorControls.TextInput
            label="Comment"
            value={editedFile.fileComment}
            onChange={(e, v) => setEditedFile((o) => ({ ...o, fileComment: v }))}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              onEditFile(e, {
                id: selected,
                name: editedFile.fileName,
                folder: editedFile.fileFolder,
                comment: editedFile.fileComment,
              });
              setEditedFile(null);
            }}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={() => setEditedFile(null)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </div>
  );
}
Files.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    comment: PropTypes.string,
    folder: PropTypes.number,
  })).isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
  onEditFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  dragAllowed: PropTypes.bool,
};

Files.defaultProps = {
  selected: null,
  dragAllowed: true,
};

export default Files;
