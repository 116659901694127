import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Layout from '../../components/Layout';
import api from '../../api/req';
import LoginForm from '../../components/Login';
import AppContextProvider from '../../providers/authProvider/provider';
import SettingsContextProvider from '../../providers/settingsProvider/provider';
import '../../assets/scss/theme.scss';

function Root() {
  const [currentUser, setCurrentUser] = useState(null);
  const tokens = useRef({
    accessToken: null,
    refreshToken: null,
  });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  // key-value store for all settings in current session
  const clearSession = useCallback(
    () => {
      window.localStorage.removeItem('refreshToken');
      setCurrentUser(null);
      tokens.current.accessToken = null;
      tokens.current.refreshToken = null;
    },
    [],
  );

  const tryLogout = useCallback(
    () => {
      clearSession();
    },
    [clearSession],
  );
  const tryCurrentUser = useCallback(
    async (accessToken, refreshToken) => {
      try {
        const resp = await api.get('/api/core/user/current_user/', () => ({ access: accessToken }));
        if (resp.ok) {
          const d = await resp.json();
          setCurrentUser(d);
          setLoading(false);
          tokens.current.accessToken = accessToken;
          tokens.current.refreshToken = refreshToken;
          setErr(null);
          return true;
        }
        setErr(`${resp.status} ${resp.statusText}`);
      } catch (e) {
        setErr(e.message);
      }

      setLoading(false);
      clearSession();

      return false;
    },
    [clearSession],
  );

  const tryRefresh = useCallback(
    async () => {
      // eslint-disable-next-line
      const refreshToken = tokens.current.refreshToken || window.localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const r = await api.post('/api/token/refresh', () => null, { refresh: refreshToken });
          if (r.ok) {
            const d = await r.json();
            window.localStorage.setItem('refreshToken', refreshToken);
            tokens.current.accessToken = d.access;
            tokens.current.refreshToken = refreshToken;
            return ({
              refresh: refreshToken,
              access: d.access,
            });
          }
        } catch (e) {
          setErr(e.message);
        }
      }
      tryLogout();
      return false;
    },
    [tryLogout],
  );

  const tryLogin = useCallback(
    async (username, password) => {
      setLoading(true);
      try {
        const r = await api.post('/api/token/', () => null, { username, password });
        if (r.ok) {
          const d = await r.json();
          if (await tryCurrentUser(d.access, d.refresh)) {
            window.localStorage.setItem('refreshToken', d.refresh);
          }
        } else {
          try {
            const d = await r.json();
            const errors = Object.keys(d).reduce((R, k) => [...R, d[k]], []);
            const errorMsg = errors.reduce((R, e) => `${R} ${e}`, '');
            clearSession();
            setLoading(false);
            setErr(errorMsg);
          } catch {
            setLoading(false);
            setErr(`${r.status} ${r.statusText}`);
            clearSession();
          }
        }
      } catch (e) {
        setLoading(false);
        setErr(e.message);
        clearSession();
      }
    },
    [clearSession, tryCurrentUser],
  );

  useEffect(
    () => {
      tryRefresh().then(
        (r) => {
          if (r) tryCurrentUser(r.access, r.refresh);
          else tryLogout();
        },
      );
    },
    [tryCurrentUser, tryLogout, tryRefresh],
  );

  return !currentUser ? (
    <LoginForm onLogin={tryLogin} errorMsg={err} loading={loading} />
  ) : (
    <AppContextProvider
      currentUser={currentUser}
      currentUserRefresh={tryCurrentUser}
      logoutHandler={tryLogout}
      refreshHandler={tryRefresh}
      tokens={tokens}
    >
      <SettingsContextProvider>
        <Layout />
      </SettingsContextProvider>
    </AppContextProvider>
  );
}

export default Root;
