import React, { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableControls, tableHooks } from '../../../../BasicEditor/tableControls';

function DetailsRow({
  row, fields, index, errors, onChange, onDeleteRow,
}) {
  const nameProps = tableHooks.useStringInputProps('name', row, fields, errors, onChange, index);

  return (
    <Row className="ms-1 my-1">
      <Col sm={11}>
        <TableControls.StringInput {...nameProps} />
      </Col>
      <Col>
        <Button variant="outline-danger" onClick={() => onDeleteRow(index)} size="sm">
          <FontAwesomeIcon icon={faTimes} title="Delete record" />
        </Button>
      </Col>
    </Row>
  );
}

DetailsRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DetailsRow.defaultProps = {
  errors: {},
};

export default memo(DetailsRow);
