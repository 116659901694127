/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useTablePart from '../../../BasicEditor/hook/tablepart';
import LanguageRow from './row';
import LanguageHeader from './header';

function Languages({
  languages, onChange, data, fields, errors,
}) {
  const {
    tableData, tableActions,
    tableContainerRef, tableErrors,
    tableFields,
  } = useTablePart({
    data, onChange, tableName: 'languages', errors, fields,
  });
  return (
    <Card className="border-0 ">
      <Card.Header>
        <LanguageHeader fields={tableFields} />
      </Card.Header>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, rowIndex) => (
          <LanguageRow
            row={row}
            key={rowIndex}
            errors={tableErrors[rowIndex]}
            languages={languages}
            fields={tableFields}
            onChange={tableActions.onCellChange}
            onDeleteRow={tableActions.onDeleteRow}
            index={rowIndex}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Button variant="outline-primary" onClick={() => tableActions.onAddRow()}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add language
        </Button>
      </Card.Footer>
    </Card>
  );
}

Languages.propTypes = {
  data: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      language: PropTypes.number,
    })),
  }),
  languages: PropTypes.shape({
    selectorValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

Languages.defaultProps = {
  data: [],
};

export default Languages;
