import React, {
  memo,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import api from '../../../api/req';
import { debounce } from '../../../api/utils';
import AppContext from '../../../providers/authProvider';
import {
  Loader,
} from '../../../components/Styled/Misc';

moment.locale('en-CY', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const localizer = momentLocalizer(moment);

const cViews = Object.values(Views);

// const StyledCalendar = styled(Calendar)`
//   // background: white;
//   border: 1px solid #909dc1;
//   background: rgba(255,255,255,0.7);
//   border-radius: 0.3rem;
//   padding: 10px;
//   margin: 10px 0;
// `;

function InterviewReport() {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [interviews, setInterviews] = useState([]);
  const [period, setPeriod] = useState({
    bDate: new Date(moment().startOf('month').startOf('week').valueOf()),
    eDate: new Date(moment().endOf('month').endOf('week').valueOf()),
  });

  const { auth } = useContext(AppContext);
  const navigate = useNavigate();

  const executeReport = useCallback(
    (d1, d2) => {
      const loader = async () => {
        const r = await api.get('/api/interviews/', auth, { page_size: 999, start__gte: d1.toISOString(), end__lte: d2.toISOString() });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setErr(null);
      setLoading(true);
      loader()
        .then((d) => {
          setInterviews(d.results.map((i) => {
            const start = new Date(i.start);
            const end = new Date(i.end);
            return ({
              title: `${i.people.name} ${i.people.family_name}`,
              start,
              end,
              allDay: false,
              resource: i,
            });
          }));
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth],
  );

  const debouncedExecuteReport = useMemo(
    () => debounce(executeReport),
    [executeReport],
  );

  useEffect(() => {
    const eDate2 = new Date(period.eDate.valueOf());
    eDate2.setHours(23, 59, 59);
    debouncedExecuteReport(period.bDate, eDate2);
  }, [debouncedExecuteReport, period.bDate, period.eDate]);

  const tooltipAccessor = useCallback(
    (e) => `Interview ${e.resource.people.name} ${e.resource.people.family_name} by ${e.resource.executor.repr} at ${e.start.toLocaleTimeString('en-CY', { hour12: true, hour: 'numeric', minute: '2-digit' })}`,
    [],
  );
  const onRangeChange = useCallback(
    (r) => {
      if (r.length) {
        setPeriod({
          bDate: r[0],
          eDate: r[r.length - 1],
        });
      } else {
        setPeriod({
          bDate: r.start,
          eDate: r.end,
        });
      }
    },
    [],
  );

  return (
    <>
      {loading && <Loader />}
      {err && (
        <Alert variant="danger">{err}</Alert>
      )}
      <h5 className="text-primary">Interviews</h5>
      <div className="vh-100">
        <Calendar
          views={cViews}
          showMultiDayTimes
          localizer={localizer}
          events={interviews}
          tooltipAccessor={tooltipAccessor}
          step={15}
          onDoubleClickEvent={(event) => navigate(`/interviews/${event.resource.id}`)}
          onRangeChange={onRangeChange}
        />
      </div>
    </>
  );
}

export default memo(InterviewReport);
