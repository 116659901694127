import { useEffect, useRef, useState } from 'react';

export const useLayoutGridHook = () => {
  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const resizeObsever = useRef(
    new ResizeObserver((enteries) => enteries.forEach(() => {
      const { height, width } = document.body.getBoundingClientRect();
      if (screenHeight !== height) setScreenHeight(height);
      if (screenWidth !== width) setScreenWidth(width);
    })),
  );

  useEffect(() => {
    resizeObsever.current.observe(document.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => resizeObsever.current.unobserve(document.body);
  }, []);

  return { screenHeight, screenWidth };
};
