import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function WysiwygEditor({ className, value, onChange }) {
  const refValue = useRef(value);
  const [eState, setEState] = useState(() => EditorState.createEmpty());
  useEffect(
    () => {
      if (value !== refValue.current) {
        refValue.current = value;
        const contentBlock = htmlToDraft(value || '');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEState(EditorState.createWithContent(contentState));
      }
    },
    [eState, value],
  );
  const onEditorChange = useCallback(
    (state) => {
      const newValue = draftToHtml(convertToRaw(state.getCurrentContent()));
      refValue.current = newValue;
      setEState(state);
      onChange(null, newValue);
    },
    [onChange],
  );
  return (
    <Editor
      editorState={eState}
      onEditorStateChange={onEditorChange}
      wrapperClassName={classNames('w-100 rounded border p-2', className)}
    />
  );
}

WysiwygEditor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

WysiwygEditor.defaultProps = {
  className: '',
  value: '',

};

export default WysiwygEditor;
