import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SmallEditor } from '../peoples/editor';
import AnonimousAppContextProvider from '../../providers/authProvider/AnonimousProvider';

function SendCV() {
  const [saved, setSaved] = useState(false);
  return (
    <Router>
      <AnonimousAppContextProvider>
        {!saved ? (
          <SmallEditor id="create" onSave={() => setSaved(true)} />
        ) : (
          <h3>Thank you for your cv!</h3>
        )}
      </AnonimousAppContextProvider>
    </Router>
  );
}

export default SendCV;
