import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function PreferenceHeader({
  fields,
}) {
  return (
    <Row className="fw-bold">
      <Col sm={11} className="text-center">
        {fields.company.label}
      </Col>
      <Col />
    </Row>
  );
}

PreferenceHeader.propTypes = {
  fields: PropTypes.shape({
    company: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default PreferenceHeader;
