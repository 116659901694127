import React from 'react';
import PropTypes from 'prop-types';
import { DrowDownIcon } from '../../../icons';
import { StyledDropDownButton, StyledImg } from './Styled';

export function DropdownButton({ content, onClick, disabled }) {
  return (
    <StyledDropDownButton onClick={onClick} disabled={disabled} title={content}>
      <StyledImg src={DrowDownIcon} alt={content} />
    </StyledDropDownButton>
  );
}

DropdownButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DropdownButton.defaultProps = {
  onClick: () => null,
  content: 'Show',
  disabled: false,
};

// eslint-disable-next-line
export default DropdownButton;
