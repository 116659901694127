import React from 'react';

function ImgViewer({ filePath }) {
  return (
    <div className="h-100 w-100 m-auto overflow-auto">
      <img style={{ width: '100%' }} src={filePath} alt={filePath} />
    </div>
  );
}

export default ImgViewer;
