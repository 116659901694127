import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { SettingsContext } from './context';
import { childrenPropType } from '../../const/propTypes';

function SettingsContextProvider({
  children,
}) {
  const [settings, setSetts] = useState({});

  useEffect(
    () => {
      const d = window.localStorage.getItem('settings');
      if (d) {
        try {
          setSetts(JSON.parse(d));
        } catch {
          console.error('Error parsing settings in browers loval storrage!');
          setSetts({});
        }
      }
    },
    [],
  );

  const onSetSettings = useCallback(
    (key, value) => {
      setSetts((o) => {
        const newSettings = { ...o, [key]: value };
        window.localStorage.setItem('settings', JSON.stringify(newSettings));
        return newSettings;
      });
    },
    [],
  );

  const ctxValue = useMemo(
    () => ({
      settings,
      onSetSettings,
    }),
    [onSetSettings, settings],
  );

  return (
    <SettingsContext.Provider value={ctxValue}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsContextProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default SettingsContextProvider;
