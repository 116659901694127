import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import DateTimeShow from '../../../../components/Controls/dates/timeShow';

const StyledImage = styled.img`
  height: 10rem;
`;

function MessageShower({ message, showPeople }) {
  const isSystem = !message.author;
  const author = message.author || { username: 'System' };
  // eslint-disable-next-line camelcase
  const { first_name, last_name, username } = author;
  // eslint-disable-next-line camelcase
  const user = first_name || last_name ? `${first_name} ${last_name}` : username;
  const peopleName = `${message.people.name} ${message.people.family_name}`;
  return (
    <div className={classNames('border-bottom pb-2 mb-2 w-100', { 'opacity-50': isSystem })}>
      <div className="d-flex flex-column flex-md-row">
        <div className="d-flex gap-1">
          <h5 className="text-primary">
            {user}
          </h5>
          {showPeople && (
            <>
              &nbsp;regarding&nbsp;
              <OverlayTrigger overlay={(
                <Popover>
                  <Popover.Body className="text-center">
                    <StyledImage
                      src={message.people.photo}
                      alt={message.people.family_name}
                      className="rounded"
                    />
                  </Popover.Body>
                  <Popover.Header as="h3">{peopleName}</Popover.Header>
                </Popover>
              )}
              >
                <Link to={`/candidates/${message.people.id}/`}>
                  {peopleName}
                </Link>
              </OverlayTrigger>
            </>
          )}
        </div>
        <DateTimeShow value={message.date} className="text-muted ms-0 ms-md-auto small" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: message.comment }} />
    </div>
  );
}

MessageShower.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      username: PropTypes.string,
    }),
    date: PropTypes.string,
    comment: PropTypes.string,
    people: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }).isRequired,
  showPeople: PropTypes.bool,
};

MessageShower.defaultProps = {
  showPeople: false,
};
export default MessageShower;
