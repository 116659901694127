import styled from 'styled-components';
import { DefaultButton } from '../defaultButton';

const getBorderRadius = (neighbors) => {
  const br = '.3rem';
  switch (neighbors) {
    case 'left':
      return `0 ${br} ${br} 0`;
    case 'right':
      return '0';
    case 'both':
      return br;
    case 'none':
      return `0 ${br} ${br} 0`;
    default:
      return `0 ${br} ${br} 0`;
  }
};

export const ButtonStyled = styled.button.attrs({
  type: 'button',
})`
  padding: 8px 8px;
  // border-radius: ${({ neighbors }) => getBorderRadius(neighbors)};
  border-radius: 0.3rem;
  border: 1px solid rgb(177, 216, 255);
  background: linear-gradient(0deg,rgba(204, 230, 255, 0.88),rgba(255, 255, 255, 0.88)),#4281C9;
  // border: 1px solid rgba(34,36,38,.15);
  // background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  color: #4D4D4D;
  cursor: pointer;
  outline: none;
  &:hover{
    background: #AAC6E6;
  }
`;

export const StyledButton = styled(DefaultButton)`
  // transform: scale(1.1);
  // background: white;
  border: 1px solid #909dc1;
  background: radial-gradient(farthest-side ellipse at top left, white, #d4d4d4);
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  color: #605b5b;
  border-radius: .3rem;
  outline: none;
  padding: 5px 8px;
  cursor: pointer;
  // margin-left: 12px;
  margin-right: 10px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  //font-size: 0;
  ${({ disabled }) => disabled && 'filter: grayscale(1) opacity(.275);'}
  // transition: all 0.3s ease-out ;
  &:hover{
    ${({ disabled }) => !disabled && 'background: radial-gradient(farthest-side ellipse at top left,white,#bdc0c5);'}
  }
`;

export const StyledDropDownButton = styled(ButtonStyled)`
  padding: 8px 9px;
  align-self: flex-start;
  min-height: 33px;
`;

export const StyledColorButton = styled(StyledButton)`
  background: linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
`;

export const StyledImg = styled.img`
  cursor: pointer;
  max-height: 20px;
  display: grid;
  align-self: center;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
`;

export const StyledText = styled.div`
 display: initial;
 margin-left: 5px;
 
 @media (max-width: 1395px) {
  display: none;
  }
`;

export const StyledCommandPanelButton = styled(StyledButton)`
  height: 2.5em;
  // &:hover{
  //   ${({ disabled }) => !disabled && 'transform: none;'}
  //   background: #d0f7d4fc;
  // }
`;
