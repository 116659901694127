import backendUri from '../const/urls';

/**
 * @const
 * @type {Set<string>}
 */
const METHODS = new Set(['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'OPTIONS']);

/**
 * @function
 * @param {object} params
 * @return {string}
 */
const paramConverter = (params) => Object.keys(params)
  .map((k) => (Array.isArray(params[k])
    ? params[k].map((v) => `${k}=${encodeURIComponent(v)}&`).join('')
    : `${k}=${encodeURIComponent(params[k])}&`))
  .join('');

/**
 * @function
 * @param {string} method
 * @param {func} auth
 * @param {string} uri
 * @param {object} msg
 * @param {bool} isFormData
 * @param {bool} tryRefresh
 * @return {Promise<Response>}
 */
export const initReq = async (method, auth, uri, msg, isFormData = false, tryRefresh = true) => {
  const { access, onRefresh } = auth() || {};
  const bodyIfExists = {};

  if (isFormData) {
    bodyIfExists.body = msg;
  } else if (!(['get', 'options']).includes(method.toLowerCase()) && !!msg && !!Object.keys(msg).length) {
    bodyIfExists.body = JSON.stringify(msg);
  }
  const response = await fetch(
    uri,
    {
      method,
      headers: Object.assign(
        isFormData ? {} : {
          'Content-Type': 'application/json',
        },
        access ? { Authorization: `Bearer ${access}` } : {},
      ),
      mode: 'cors',
      credentials: 'include',
      ...bodyIfExists,
    },
  );
  if (response.status === 401 && tryRefresh && onRefresh) {
    const newAuth = await onRefresh();
    if (newAuth) {
      return initReq(method, newAuth, uri, msg, isFormData, false);
    }
  }
  return response;
};

/**
 * @function
 * @property {func} get
 * @property {func} post
 * @property {func} put
 * @property {func} delete
 * @property {func} patch
 * @property {func} options
 * @return {Promise<object>}
 */
const api = new Proxy(initReq, {
  get(target, propKey) {
    // eslint-disable-next-line
    const _reqMethod = [...METHODS].filter(el => propKey.startsWith(el.toLowerCase()));
    const reqMethod = !!_reqMethod.length && _reqMethod[0];

    if (!_reqMethod) {
      throw new Error('Unknown or disabled http-method.\n'
        + `Use Force or [${[...METHODS]}], Luke.`);
    }
    const fn = propKey
      .substring(reqMethod.length)
      .toLowerCase();
    return (...args) => {
      /**
       * @const
       * @desc uri part from argument or empty-string
       * @type {string}
       */

      const partUri = args.shift() || '';

      const auth = args.shift() || '';

      /**
       * @const
       * @desc Prepared path for request with cutted '$/' at end.
       * @type {string}
       */
      const resourcePath = partUri.length
        ? `${fn.slice(0, -1)}${partUri}`
        : `${fn.slice(0, -1)}`;

      /**
       * @const
       * @type {object}
       */
      const requestData = args.shift() || {};

      const isFormData = args.shift() || false;

      const isFullPath = args.shift() || false;

      const fullPath = (`${isFullPath ? '' : backendUri}${resourcePath}`
        + `${reqMethod === 'GET' && !!Object.keys(requestData).length ? `?${paramConverter(requestData)}` : ''}`);
      const msg = (!!Object.keys(requestData).length && requestData)
        || (isFormData && requestData)
        || null;

      // console.debug(
      //   `API LOGGER: REQUEST "${reqMethod}" TO "${fullPath} with data"`,
      //   msg,
      //   'auth', auth(),
      // );

      return target(reqMethod, auth, `${fullPath}`, msg, isFormData);
    };
  },
});

function NetworkException(message) {
  this.message = message;
  this.name = 'Network Exception';
}

export {
  NetworkException,
};
export default api;
