import {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import AppContext from '../../providers/authProvider';
import api from '../../api/req';

const useSubscribed = ({ uri, optionsGetter }) => {
  const { currentUser, auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [items, setItems] = useState([]);
  const [fields, setFields] = useState([]);
  const [next, setNext] = useState([]);
  const [previous, setPrev] = useState([]);
  const currentPage = useRef(1);
  const count = useRef(0);

  const loadOptions = useCallback(
    async () => {
      const r = await api.options(uri, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, uri],
  );
  const loadPage = useCallback(
    async (page) => {
      const options = { ...optionsGetter(currentUser), page };
      const r = await api.get(uri, auth, options);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, currentUser, optionsGetter, uri],
  );

  const onLoad = useCallback(
    (page) => {
      setLoading(true);
      setErr(null);
      loadPage(page)
        .then((d) => {
          setItems(d.results);
          count.current = d.count;
          setNext(d.next);
          setPrev(d.previous);
          currentPage.current = page;
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [loadPage],
  );

  const nextPageHandler = useCallback(
    () => {
      onLoad(currentPage.current + 1);
    },
    [onLoad],
  );

  const prevPageHandler = useCallback(
    () => {
      onLoad(currentPage.current - 1);
    },
    [onLoad],
  );

  useEffect(
    () => {
      loadOptions().then((f) => {
        setFields(f);
        onLoad(1);
      });
    },
    [loadOptions, onLoad],
  );

  return {
    loading,
    err,
    setErr,
    items,
    fields,
    prevPageHandler,
    nextPageHandler,
    count: count.current,
    canNext: !!next,
    canPrev: !!previous,
  };
};

export default useSubscribed;
