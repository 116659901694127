import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
// import Header from './header';
// import Menu from './menu';
// import Logo from './logo';
import AppRoutes from './routes';

function Layout() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default Layout;
