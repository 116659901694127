import React, { useEffect, useRef, useState } from 'react';
import {
  Alert, Badge, Button, Col, Collapse, Container, InputGroup, ListGroup, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilter } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import {
  SearchInput, Selector,
} from '../../../components/Controls';
import { PositionSelector, CompanySelector } from '../../SimpleSelector';
import JobItem from './jobItem';
import useLister, { useDict, useFilterItems } from '../../BaseLister/hook';
import LoaderContainer from '../../DashBoard/LoaderContainer';
import { useLayoutGridHook } from '../../../api/hooks';

const API_URL = '/api/cat/jobs/';

function JobsList() {
  const navigate = useNavigate();
  const {
    data, fields, onNextPage, onPrevPage,
    onSearch, setErr, loading, err, canNextPage, canPrevPage,
    onSetFilter, filter, loaded, count,
  } = useLister({ url: API_URL, initialFilter: { status: 'O' } });
  const jobStatusFilter = useFilterItems(filter, 'status', onSetFilter);
  const positionFilter = useFilterItems(filter, 'position', onSetFilter);
  const companyFilter = useFilterItems(filter, 'company', onSetFilter);
  const companies = useDict({ url: '/api/cat/companies/' });
  const positions = useDict({ url: '/api/cat/position/' });

  const { screenWidth } = useLayoutGridHook();
  const sff = useRef();
  const [showFilters, setShowFilters] = useState(false);
  sff.current = showFilters;
  useEffect(() => {
    if (screenWidth >= 992 && !sff.current) setShowFilters(true);
    else if (screenWidth < 992 && sff.current) setShowFilters(false);
  }, [screenWidth]);

  return (
    <LoaderContainer
      onPrevPage={onPrevPage}
      canPrev={canPrevPage}
      onNextPage={onNextPage}
      canNext={canNextPage}
      setErr={setErr}
      loading={loading}
      err={err}
    >
      {loaded && (
        <Container fluid>
          <div className="position-sticky fixed-under-menu bg-white">
            <div className="d-flex align-items-start">
              <h1 className="text-primary me-2">Jobs list</h1>
              <Badge>{count}</Badge>
            </div>
            <Nav className="mb-2 pb-2 border-bottom d-flex flex-column flex-sm-row w-100 align-items-center gy-2 gy-sm-0 gap-2">
              <div className="d-flex gap-2">
                <Button variant="outline-primary" onClick={() => navigate('/jobs/create')} size="sm">
                  <FontAwesomeIcon icon={faFile} className="me-2" />
                  Create new
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setShowFilters((o) => !o)}
                  size="sm"
                  active={showFilters}
                  className="d-block d-lg-none"
                >
                  <FontAwesomeIcon icon={faFilter} className="me-2" />
                  Filters
                </Button>
              </div>
              <div className="ms-sm-auto align-self-stretch">
                <SearchInput onSearch={onSearch} />
              </div>
            </Nav>
          </div>
          <Row className="flex-column-reverse flex-lg-row">
            <Col md={9}>
              <Row xs={1} sm={2} md={2} lg={3} xl={2} xxl={3} className="gy-2">
                {data.length ? data.map((row) => (
                  <Col key={row.id}>
                    <JobItem row={row} />
                  </Col>
                )) : (
                  <Alert variant="info" className="w-100">
                    <Alert.Heading>Oops</Alert.Heading>
                    There&apos;s nothing here
                  </Alert>
                )}
              </Row>
            </Col>
            <Col md={3}>
              <Collapse in={showFilters}>
                <div>
                  <h3>Filters</h3>
                  <ListGroup>
                    <ListGroup.Item>
                      <Selector
                        prepend={(
                          <>
                            <InputGroup.Text>Job status</InputGroup.Text>
                            <InputGroup.Checkbox checked={jobStatusFilter.use} onChange={jobStatusFilter.onToggle} />
                          </>
                      )}
                        required
                        value={jobStatusFilter.value}
                        onChange={jobStatusFilter.onChange}
                        values={[
                          ...fields.status.choices,
                          { display_name: 'All', value: null },
                        ]}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <PositionSelector
                        prepend={(
                          <>
                            <InputGroup.Text>{fields.position.label}</InputGroup.Text>
                            <InputGroup.Checkbox checked={positionFilter.use} onChange={positionFilter.onToggle} />
                          </>
                      )}
                        required
                        value={positionFilter.value}
                        onChange={positionFilter.onChange}
                        preloadedData={positions.data}
                      />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <CompanySelector
                        prepend={(
                          <>
                            <InputGroup.Text>{fields.company.label}</InputGroup.Text>
                            <InputGroup.Checkbox checked={companyFilter.use} onChange={companyFilter.onToggle} />
                          </>
                      )}
                        required
                        value={companyFilter.value}
                        onChange={companyFilter.onChange}
                        preloadedData={companies.data}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Collapse>
            </Col>
          </Row>
        </Container>
      )}
    </LoaderContainer>
  );
}
export default JobsList;

export { JobItem };
