import React, { useContext, useMemo } from 'react';
import {
  Col, Container, InputGroup, Nav, Row,
} from 'react-bootstrap';
import AppContext from '../../../providers/authProvider';
import {
  SaveButton, DeleteButton, CloseButton, PrevButton as ReopenButton,
} from '../../../components/Styled/Buttons';
import useEditor from '../../BasicEditor/hook/editor';
import { useDict } from '../../BaseLister/hook';
import { EditorControls, editorHooks } from '../../BasicEditor/editorControls';
import EditorContainer from '../../BasicEditor/editorContainer';
import Subscriptions from '../../peoples/editor/subscriptions';

function TaskEditor({ id, onSave, onClose }) {
  const {
    isNew, data, systemErrors, loading,
    fields, nonFieldErrors, fieldErrors, actions,
  } = useEditor({
    id,
    backendURL: '/api/tasks/',
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const { currentUser } = useContext(AppContext);

  const users = useDict({ url: '/api/core/user/' });
  const isReanOnly = useMemo(
    () => {
      if (data.executor_id === currentUser.id) return false; // Исполнитель всегда может менять
      if (isNew) return false;
      if (data.author && data.author.id === currentUser.id) return false;// Менять может только автор или исполнитель
      return true;
    },
    [currentUser.id, data.author, data.executor_id, isNew],
  );
  const titleProps = editorHooks.useStringInputProps('title', data, fields, fieldErrors, actions.onChange);
  const executorProps = editorHooks.useSelectorInputProps('executor_id', data, fields, fieldErrors, actions.onChange);
  const commentProps = editorHooks.useSelectorInputProps('comment', data, fields, fieldErrors, actions.onChange);
  const remindProps = editorHooks.useDateInputProps('remind', data, fields, fieldErrors, actions.onChange);

  return (
    <Container fluid>
      <EditorContainer
        title="Task"
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} disabled={isReanOnly} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isReanOnly || isNew} />
          {data.closed ? (
            <ReopenButton
              content="Reopen task"
              onClick={() => actions.rawSave({ ...data, closed: false })}
              disabled={isNew || isReanOnly}
            />
          ) : (
            <CloseButton
              content="Close task"
              onClick={() => actions.rawSave({ ...data, closed: true })}
              disabled={isNew || isReanOnly}
            />
          ) }
        </Nav>
        <Row>
          <Col md={isNew ? 12 : 9}>
            <Row>
              <Col md={4}>
                <EditorControls.StringInput {...titleProps} />
              </Col>
              <Col md={4}>
                <EditorControls.SelectorInput {...executorProps} values={users.selectorValues} />
              </Col>
              <Col md={4}>
                <EditorControls.DateTimeInput
                  {...remindProps}
                  prepend={(
                    <InputGroup.Checkbox
                      checked={data.remind !== null}
                      onChange={() => actions.onChange({ remind: data.remind ? null : new Date().toJSON() })}
                    />
                )}
                />
              </Col>
              <Col md={12}>
                <EditorControls.WysiwygEditor {...commentProps} />
              </Col>
            </Row>
          </Col>
          {!isNew && (
          <Col>
            <div className="ms-2">
              <Subscriptions URI={`/api/tasks/${data.id}/subscribe/`} />
            </div>
          </Col>
          )}
        </Row>
      </EditorContainer>
    </Container>
  );
}
export default TaskEditor;
