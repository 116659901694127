import React from 'react';
import {
  ListGroup, ListGroupItem, Tab, Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Reports from '../../../containers/Reports';
import ReportItem from './reportItem';

function ReportsPage() {
  return (
    <Tabs defaultActiveKey="t1">
      <Tab
        eventKey="t1"
        title={(
          <>
            <FontAwesomeIcon icon={faUser} className="me-2" />
            Candidates catalog
          </>
        )}
      >
        <ListGroup variant="flush">
          {Object.values(Reports).map((r) => (
            <ListGroupItem key={r.url}>
              <ReportItem
                header={r.tittle}
                to={`/reports/${r.url}`}
                description={r.description}
                icon={r.icon}
              />
            </ListGroupItem>
          ))}
        </ListGroup>
      </Tab>
    </Tabs>
  );
}

export default ReportsPage;
