import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledItemContainer = styled.div`
  max-width: 350px;
`;

function DayShedule({
  minHour, maxHour, items, planningDate,
}) {
  const { hours } = useMemo(
    () => {
      const itemsWH = items.map((item) => {
        const cStart = new Date(item.start);
        const cEnd = new Date(item.end);
        return {
          ...item,
          cStart,
          cEnd,
        };
      }).sort((a, b) => {
        if (a.cStart < b.cStart) return -1;
        if (a.cStart > b.cStart) return 1;
        return 0;
      }).map((item, index) => ({ ...item, index }));

      const minmax = itemsWH.reduce((R, item) => ({
        min: Math.min(R.min, item.cStart.getHours()),
        max: Math.max(R.max, item.cEnd.getHours()),
      }), { min: minHour, max: maxHour });

      const cDate = planningDate;

      const hrs = [...Array(minmax.max - minmax.min + 1)].map((h, i) => {
        const cT0 = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), i + minmax.min);
        const cT30 = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), i + minmax.min, 30);
        const cT60 = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), i + minmax.min + 1);
        return ({
          hour: i + minmax.min,
          items0: itemsWH.filter((item) => item.cStart < cT30 && item.cEnd > cT0).map((item) => item.index),
          items30: itemsWH.filter((item) => item.cStart < cT60 && item.cEnd > cT30).map((item) => item.index),
          items0_s: itemsWH.filter((item) => item.cStart < cT30 && item.cStart >= cT0).map((item) => item.index),
          items30_s: itemsWH.filter((item) => item.cStart < cT60 && item.cStart >= cT30).map((item) => item.index),
        });
      });
      const itemsWH2 = hrs.reduce((R, h) => {
        const setColumns = (hItems, is) => {
          let lastColumn = 0;
          // console.log(h.hour, is, hs)
          return hItems.reduce((cR, itemIndex) => {
            const currentColumn = cR[itemIndex].column;
            // console.log(cR[itemIndex], lastColumn);
            if (!currentColumn) {
              lastColumn += 1;
              return cR.map((item) => (item.index === itemIndex ? {
                ...cR[itemIndex],
                column: lastColumn,
              } : item));
            }
            lastColumn = currentColumn;
            return cR;
          }, is);
        };
        return setColumns(h.items30, setColumns(h.items0, R));
      }, itemsWH);

      const itemsWH3l = itemsWH2.map((item) => {
        const cEnd = new Date(item.cEnd.valueOf());
        cEnd.setSeconds(-1);
        const startH = item.cStart.getHours();
        const endH = cEnd.getHours();
        const startM = item.cStart.getMinutes();
        const endM = cEnd.getMinutes();
        return {
          ...item,
          rows: Math.floor(endM / 30) + endH * 2 - (Math.floor(startM / 30) + startH * 2) + 1,
        };
      });

      const columnCount = itemsWH2.reduce((R, item) => Math.max(R, item.column), 0);

      return {
        hours: hrs,
        itemsWH3: itemsWH3l,
        columnCount,
      };
    },
    [items, maxHour, minHour, planningDate],
  );
  return hours.map((h) => (
    <Fragment key={h}>
      {!!h.items0_s.length && (
        <div className="border-bottom d-flex">
          <div className="me-3 d-flex align-items-start">
            <span className="fs-1">{h.hour}</span>
            <span className="fs-4 mt-2">:00</span>
          </div>
          {h.items0_s.map((itemIndex) => (
            <StyledItemContainer key={itemIndex}>
              {items[itemIndex].item}
            </StyledItemContainer>
          ))}
        </div>
      )}
      {!!h.items30_s.length && (
        <div className="border-bottom d-flex">
          <div className="me-3 d-flex align-items-start">
            <span className="fs-1">{h.hour}</span>
            <span className="fs-4 mt-2">:30</span>
          </div>
          {h.items30_s.map((itemIndex) => (
            <StyledItemContainer key={itemIndex}>
              {items[itemIndex].item}
            </StyledItemContainer>
          ))}
        </div>
      )}
    </Fragment>
  ));
}

DayShedule.propTypes = {
  minHour: PropTypes.number,
  maxHour: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    item: PropTypes.element.isRequired,
  })).isRequired,
  planningDate: PropTypes.instanceOf(Date), //
};
DayShedule.defaultProps = {
  minHour: 23,
  maxHour: 1,
};

export default DayShedule;
