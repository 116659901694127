import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import PeopleEditor from './editorPage';
import useEditor from '../../BasicEditor/hook/editor';
import EditorContainer from '../../BasicEditor/editorContainer';

function SmallEditor({ id, onSave }) {
  const {
    isNew, data, changed,
    fields, fieldErrors, actions, loading, systemErrors, nonFieldErrors,
  } = useEditor({
    id,
    backendURL: '/api/cat/peoples/',
    onSaveCallBack: onSave,
  });
  const authorText = data.author ? `${data.author.first_name} ${data.author.last_name} (${data.author.username})` : 'some (anonymous)';
  const title = useMemo(
    () => {
      if (isNew) return 'Application form';
      const createdAt = new Date(data.created_at);
      const created = createdAt.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      return `${data.name} ${data.family_name} (${data.email}) / Created by ${authorText} on ${created}`;
    },
    [authorText, data.created_at, data.email, data.family_name, data.name, isNew],
  );

  if (!fields) return null;
  return (
    <EditorContainer
      title={title}
      isLoading={loading}
      fields={fields}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      fieldErrors={fieldErrors}
      onClearErrors={actions.onClearErrs}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      noPaddingX
    >
      <PeopleEditor
        data={data}
        fields={fields}
        fieldErrors={fieldErrors}
        onChange={actions.onChange}
        isNew={isNew}
        changed={changed}
        onSave={actions.onSave}
        onDelete={actions.onDelete}
      />
    </EditorContainer>
  );
}

SmallEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
};

SmallEditor.defaultProps = {
  onSave: null,
};

export default SmallEditor;
