import React from 'react';
import { AppContext } from './context';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withAuthConsumer = (mapStateToProps) => (WrappedComponent) => {
  function WithAuthConsumer({ ...ownProps }) {
    return (
      <AppContext.Consumer>
        {(context) => (
          <WrappedComponent {...mapStateToProps(context, ownProps)} {...ownProps} />
        )}
      </AppContext.Consumer>
    );
  }
  WithAuthConsumer.displayName = getDisplayName(WrappedComponent);
  return WithAuthConsumer;
};

export const mapStateAuth = (state) => ({
  authF: state.auth,
  currentUser: state.currentUser,
  onSetSettings: state.setSettings,
});
