import React, {
  useContext, useState, useCallback, useEffect, memo, lazy, Suspense,
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../api/req';
import AppContext from '../../../../providers/authProvider';
import { NewButton } from '../../../../components/Styled/Buttons';
import InterviewItem from '../../../interview/list/InterviewItem';
import { interviewStatuses } from '../../../../const/enums';
import Loader from '../../../../components/Styled/Misc/loader';

function SheduleInterview({ peopleId }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [planed, setPlaned] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);

  // eslint-disable-next-line import/no-cycle
  const InerviewEditor = lazy(() => import('../../../interview/editor'));

  const load = useCallback(() => {
    const loader = async () => {
      const r = await api.get('/api/interviews/', auth, { people: peopleId, status__exclude: [interviewStatuses.CANCELED, interviewStatuses.EXECUTED] });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    setErr(null);
    loader()
      .then((d) => setPlaned(d.results))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, peopleId]);
  useEffect(() => load(), [load]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faHeadphones} className="me-2" />
          Sheduled interviews
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        {loading && (
        <Loader relative />
        )}
        {err && (
        <Alert dismissible onClose={() => setErr(null)} variant="danger">
          <Alert.Heading>
            {err}
          </Alert.Heading>
        </Alert>
        )}
        <div className="mb-2">
          {planed.map((i) => (
            <InterviewItem
              key={i.id}
              interveiw={i}
              mode="byPeople"
            />
          ))}
        </div>
        <div>
          <NewButton content="Schedule an interview" onClick={() => setModalOpened(true)} />
        </div>
        <Modal show={modalOpened} onHide={() => setModalOpened(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Schedule an interview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Suspense fallback={<Loader relative />}>
              <InerviewEditor
                id="create"
                peopleId={peopleId}
                onSave={() => {
                  setModalOpened(false);
                  load();
                }}
              />
            </Suspense>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
}
SheduleInterview.propTypes = {
  peopleId: PropTypes.number.isRequired, // people_id to add
};

export default memo(SheduleInterview);
