import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { SaveButton, DeleteButton, CloseButton } from '../../../../components/Styled/Buttons';
import { backendURI } from '../list/uri';
import useEditor from '../../../BasicEditor/hook/editor';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';
import { PositionLabel } from '../../../peoples/editor/labels/styles';

function PositionEditor({
  id, onSave, onClose,
}) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, options,
  } = useEditor({
    id,
    backendURL: backendURI,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const colorProps = editorHooks.useSelectorInputProps('color', data, fields, fieldErrors, actions.onChange);
  return (
    <Container fluid>
      <EditorContainer
        title={data.name}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        {options.description && (
          <p className="text-muted small">
            {options.description}
          </p>
        )}
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isNew} />
          <CloseButton onClick={() => onClose()} />
        </Nav>
        <Row>
          <Col md={6}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col md={6}>
            <EditorControls.SelectorInput {...colorProps} />
          </Col>
          <Col md={12}>
            <h6 className="mt-4">Preview:</h6>
            <PositionLabel
              $color={data.color}
            >
              {data.name}
            </PositionLabel>
          </Col>
        </Row>
      </EditorContainer>
    </Container>
  );
}

PositionEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
PositionEditor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default (PositionEditor);
