import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, Tab, Tabs } from 'react-bootstrap';
import PeopleEditor from './editorPage';
// eslint-disable-next-line import/no-cycle
import Files from './files';
import useEditor from '../../BasicEditor/hook/editor';
import EditorContainer from '../../BasicEditor/editorContainer';
// eslint-disable-next-line import/no-cycle
import Comments from './comments';

function Editor({ id, onSave, onClose }) {
  const {
    isNew, data, changed, systemErrors, loading,
    fields, nonFieldErrors, fieldErrors, actions,
  } = useEditor({
    id,
    backendURL: '/api/cat/peoples/',
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });

  const authorText = data.author ? `${data.author.first_name} ${data.author.last_name} (${data.author.username})` : 'some (anonymous)';
  const title = useMemo(
    () => {
      if (isNew) return 'Application form';
      const createdAt = new Date(data.created_at);
      const created = createdAt.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      return `${data.name} ${data.family_name} (${data.email}) / Created by ${authorText} on ${created}`;
    },
    [authorText, data.created_at, data.email, data.family_name, data.name, isNew],
  );
  if (!fields) return null;
  return (
    <Container fluid>
      <EditorContainer
        title={title}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
        noPaddingX
      >
        {(isNew || changed) && (
        <div className="small text-muted">
          Comments, labels, files and jobs will be available after saving item.
        </div>
        )}
        <Tabs defaultActiveKey="editor" unmountOnExit>
          <Tab title="General" eventKey="editor">
            <PeopleEditor
              data={data}
              fields={fields}
              fieldErrors={fieldErrors}
              onChange={actions.onChange}
              isNew={isNew}
              changed={changed}
              onSave={actions.onSave}
              onDelete={actions.onDelete}
            />
          </Tab>
          <Tab title="Comments" eventKey="comments" disabled={isNew || changed}>
            {data.id && (
              <Comments peopleId={data.id} />
            )}
          </Tab>
          <Tab title="Files" eventKey="files" disabled={isNew || changed}>
            {data.id && (
              <Files peopleId={data.id} />
            )}
          </Tab>
        </Tabs>
      </EditorContainer>
    </Container>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default Editor;
