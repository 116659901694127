import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
`;

export const StyledStar = Styled.span`
  color: #FF0000;
  &:after{
    content: '*';
  }
`;

function ContentLabel({ text, children, required }) {
  return (
    <StyledLabel>
      {text}
      { required && <StyledStar />}
    &nbsp;
      {children}
    </StyledLabel>
  );
}

ContentLabel.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  required: PropTypes.bool,
};

ContentLabel.defaultProps = {
  required: false,
  children: null,
};

export default ContentLabel;
