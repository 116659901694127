/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Button,
  Col, Container, Modal, Row, Tab, Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import JobEditor from './jobEditor';
import PeopleItem from '../../peoples/list/item';
import api from '../../../api/req';
import useEditor from '../../BasicEditor/hook/editor';
import AppContext from '../../../providers/authProvider';

function Editor({ id, onSave, onClose }) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, changed,
  } = useEditor({
    id,
    backendURL: '/api/cat/jobs/',
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const { auth } = useContext(AppContext);
  const [idForDelete, setIdForDelete] = useState(null);
  const onDeletePeopleFromJob = useCallback(
    async (peopleId) => {
      await api.delete(`/api/cat/peoples/${peopleId}/current_job/`, auth);
      actions.onReload();
    },
    [actions, auth],
  );

  return (
    <Container fluid>
      <h3 className="text-primary">
        {isNew ? 'New job' : data.name}
      </h3>

      <Tabs defaultActiveKey="job">
        <Tab title="Job" eventKey="job">
          <JobEditor
            data={data}
            isNew={isNew}
            isModified={changed}
            fields={fields}
            fieldErrors={fieldErrors}
            loading={loading}
            systemErrors={systemErrors}
            nonFieldErrors={nonFieldErrors}
            onChange={actions.onChange}
            onSave={actions.onSave}
            onDelete={actions.onDelete}
            onReload={actions.onReload}
            onClearErrs={actions.onClearErrs}
            onClearNonFieldErrors={actions.onClearNonFieldErrors}
          />
        </Tab>
        {(isNew || !data.counts) ? [] : Object.values(data.counts).filter((cc) => cc.count).map((cc) => (
          <Tab
            key={cc.status_name}
            title={(
              <div className="d-flex align-items-sm-start">
                <span className="me-1">{cc.status_name}</span>
                <Badge bg="secondary" pill>
                  {cc.count}
                </Badge>
              </div>
            )}
            eventKey={cc.status_name}
          >
            <Row md={3} className="mt-3">
              {cc.peoples.map((r) => (
                <Col key={r.people.id}>
                  <PeopleItem
                    row={r.people}
                    fields={data.peoples_meta}
                    closed={r.closed}
                    showClosedInfo
                    onClose={() => setIdForDelete(r.people.id)}
                  />
                </Col>
              ))}
            </Row>
          </Tab>
        ))}
      </Tabs>
      <Modal show={!!idForDelete} onHide={() => setIdForDelete(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Remove candidate from job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this candidate from the job?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              onDeletePeopleFromJob(idForDelete);
              setIdForDelete(null);
            }}
          >
            <FontAwesomeIcon icon={faThumbsUp} className="me-2" />
            Yes, i&apos;m sure
          </Button>
          <Button variant="outline-success" onClick={() => setIdForDelete(null)}>
            <FontAwesomeIcon icon={faThumbsDown} className="me-2" />
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>

  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default Editor;
