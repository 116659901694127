import React, {
  memo,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import CommentEditor from './editor';
import AppContext from '../../../../providers/authProvider';
import api from '../../../../api/req';
import MessageShower from './list';
import Labels from '../labels';
import Subscriptions from '../subscriptions';
import { FilePreview } from '../../../../components/Controls';
import Loader from '../../../../components/Styled/Misc/loader';
import JobStatus from '../add_to_job/JobsStatus';
import WFStatus from '../add_to_job/WorkFlowStatus';
import LandingDate from '../add_to_job/LandingDate';
// eslint-disable-next-line import/no-cycle
import SheduleInterview from '../add_to_job/SheduleInterview';
import NewTask from '../add_to_job/NewTask';

function Comments({ peopleId }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [commentsList, setCommentsList] = useState([]);
  const [cv, setCV] = useState(null);

  const commentsUri = useMemo(
    () => `/api/cat/peoples/${peopleId}/comments/`,
    [peopleId],
  );
  const loadCV = useCallback(async () => {
    const r = await api.get(`/api/cat/peoples/${peopleId}/`, auth);
    if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
    const d = await r.json();
    return d.cv;
  }, [auth, peopleId]);
  const load = useCallback(async () => {
    const r = await api.get(commentsUri, auth);
    if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
    return r.json();
  }, [auth, commentsUri]);

  useEffect(
    () => {
      if (peopleId) {
        setLoading(true);
        setErr(null);
        loadCV()
          .then((lcv) => {
            setCV(lcv);
            return load();
          })
          .then((d) => setCommentsList(d))
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [load, loadCV, peopleId],
  );
  const onCommitMessage = useCallback(
    (message) => {
      const saver = async () => {
        const r = await api.post(commentsUri, auth, { comment: message });
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return load();
      };
      setLoading(true);
      setErr(null);
      saver()
        .then((d) => setCommentsList(d))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, commentsUri, load],
  );
  const cvFileType = useMemo(
    () => {
      if (cv) {
        const sts = cv.split('.');
        if (sts.length) return sts[sts.length - 1];
      }
      return '';
    },
    [cv],
  );
  if (!peopleId) return null;
  return (
    <>
      {err && (
      <Alert dismissible onClose={() => setErr(null)} variant="danger">
        <Alert.Heading>
          {err}
        </Alert.Heading>
      </Alert>
      )}
      {loading && (
        <Loader />
      )}
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Row className="border rounded p-3">
              <Col sm={6}>
                {commentsList.map((m) => (
                  <MessageShower
                    key={m.id}
                    message={m}
                  />
                ))}
              </Col>
              <Col className="vh-100 position-sticky fixed-top">
                <FilePreview
                  filePath={cv}
                  fileType={cvFileType}
                />
              </Col>
            </Row>
            <div className="d-none d-xl-block position-sticky fixed-bottom bg-white">
              <CommentEditor onCommit={onCommitMessage} />
            </div>
            <div className="d-block d-xl-none">
              <CommentEditor onCommit={onCommitMessage} />
            </div>
          </Col>
          <Col xl={4}>
            <div className="mr-2 d-flex flex-column gap-2">
              <Labels URI={`/api/cat/peoples/${peopleId}/labels/`} />
              <JobStatus peopleId={peopleId} />
              <WFStatus peopleId={peopleId} />
              <LandingDate peopleId={peopleId} />
              <SheduleInterview peopleId={peopleId} />
              <NewTask />
              <Subscriptions URI={`/api/cat/peoples/${peopleId}/subscribe/`} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Comments.propTypes = {
  peopleId: PropTypes.number.isRequired,
};

export default memo(Comments);
