import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';

export const ReopenButton = React.memo(({ content, onClick, disabled }) => (
  <Button variant="success" onClick={onClick} disabled={disabled} title={content}>
    <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
    {content}
  </Button>
));

ReopenButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ReopenButton.defaultProps = {
  content: 'Repoen',
  disabled: false,
};

export default ReopenButton;
