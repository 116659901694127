import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { InputStyled, DivStyled, ImgInput } from '../Styled/Input/StyledInput';

const MyInput = Styled(InputStyled).attrs({
  type: 'datetime-local',
})`
`;

class DateTimeInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    errored: PropTypes.bool,
    required: PropTypes.bool,
    icon: PropTypes.node,
  };

  static defaultProps = {
    value: '',
    onChange: () => null,
    disabled: false,
    readOnly: false,
    errored: false,
    required: false,
    icon: null,
  };

  changeHandler = (e) => {
    const { readOnly, onChange } = this.props;
    const newValue = new Date(e.target.value).toISOString();
    if (!readOnly) {
      onChange(e, newValue);
    }
  };

  render() {
    const {
      value, disabled, errored, readOnly, required, icon,
    } = this.props;
    let vv = '';
    if (value) {
      const cDate = new Date(value);
      vv = `${cDate.getFullYear()}-${(`0${cDate.getMonth() + 1}`).slice(-2)}-${(`0${cDate.getDate()}`).slice(-2)}T${(`0${cDate.getHours()}`).slice(-2)}:${(`0${cDate.getMinutes()}`).slice(-2)}`;
    }
    return (
      <DivStyled
        required={required}
        errored={errored}
      >
        {icon && (
          <ImgInput src={icon} alt="icon" />
        )}
        <MyInput
          value={vv}
          onChange={this.changeHandler}
          disabled={disabled}
          errored={errored}
          readOnly={readOnly}
          required={required}

        />
      </DivStyled>
    );
  }
}

export default DateTimeInput;
