import React, { memo, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import TaskEditor from '../../../tasks/editor';

function NewTask() {
  const [taskModalOpened, setTaskModalOpened] = useState(false);
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <FontAwesomeIcon icon={faClipboardCheck} className="me-2" />
          Tasks
        </Card.Title>
      </Card.Header>
      <Card.Body className="position-relative">
        <span>You can</span>
        <Card.Link className="mx-2" role="button" onClick={() => setTaskModalOpened(true)}>create</Card.Link>
        <span>a new task for this candidate.</span>
        <Modal
          size="xl"
          show={taskModalOpened}
          onHide={() => setTaskModalOpened(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TaskEditor
              id="create"
              onSave={() => setTaskModalOpened(false)}
            />
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
}

export default memo(NewTask);
