import { useNavigate, useParams } from 'react-router';
import React, { useCallback } from 'react';

function withRouterEditor(frontendURL) {
  return function w(Editor) {
    function RouterEditor() {
      const navigate = useNavigate();
      const params = useParams();
      const onClose = useCallback(
        () => navigate(frontendURL),
        [navigate],
      );
      const onSave = useCallback(
        (data) => navigate(`${frontendURL}${data.id}`),
        [navigate],
      );
      return (
        <Editor id={params.id} onClose={onClose} onSave={onSave} />
      );
    }
    return RouterEditor;
  };
}

export default withRouterEditor;
