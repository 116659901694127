import { useMemo } from 'react';
import { useTableBaseInputProps } from './baseInputProps';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { number } rowIndex - rowIndex
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  value: string,
 *  required: boolean,
 *  values: Array,
 *  errors: Array}}
 */
export const useSelectorInputProps = (
  name,
  data,
  fields,
  errors,
  onChange,
  rowIndex,
  isReadOnly = false,
  readOnlyFelds = [],
) => {
  const baseProps = useTableBaseInputProps(
    name,
    data,
    fields,
    errors,
    onChange,
    rowIndex,
    isReadOnly,
    readOnlyFelds,
  );
  const values = useMemo(
    () => (fields && fields[name] ? fields[name].choices : []),
    [fields, name],
  );
  return useMemo(
    () => ({
      ...baseProps,
      values,
    }),
    [baseProps, values],
  );
};

export default useSelectorInputProps;
