import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { SaveButton } from '../../../../components/Styled/Buttons';

const MessageContainer = styled.div`
  div .rdw-editor-main{
    border-radius: .3rem;
    border: 1px solid var(--bs-border-color);
    background: var(--bs-white);
    padding: .25rem .5rem;
    margin: .5rem 0;
    }
  & a{
    color: unset;
    text-decoration: unset;
  }
`;
function CommentEditor({ onCommit, onSetSettings, lastComment }) {
  const [eState, setEState] = useState(null);
  useEffect(() => {
    const contentBlock = htmlToDraft(lastComment || '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEState(editorState);
  }, [lastComment]);

  useEffect(
    () => () => {
      if (onSetSettings) {
        const settings = { comment: draftToHtml(convertToRaw(eState.getCurrentContent())) };
        onSetSettings('peopleEditor', settings);
      }
    },
    [eState, onSetSettings],
  );

  const onCommitMessage = useCallback(
    () => {
      onCommit(draftToHtml(convertToRaw(eState.getCurrentContent())));
      setEState(EditorState.createEmpty());
    },
    [eState, onCommit],
  );

  return (
    <>
      <MessageContainer>
        <Editor
          // onChange={(raw) => setEState(raw)}
          editorState={eState}
          onEditorStateChange={setEState}
        />
      </MessageContainer>
      <SaveButton onClick={onCommitMessage} content="Send" />
    </>
  );
}
CommentEditor.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onSetSettings: PropTypes.func,
  lastComment: PropTypes.string,
};

CommentEditor.defaultProps = {
  lastComment: null,
  onSetSettings: null,
};

export default memo(CommentEditor);
