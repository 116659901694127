import styled from 'styled-components';

export const BoxContainer = styled.div`
  border: 1px solid #b6b6b6;
  padding: 15px 10px;
  background: white;
`;

export const GridContainerTwoCol = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-row-gap: 5px;
  grid-column-gap: 2%;
  box-sizing: border-box;
`;

export const GridContainerNCol = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-row-gap: 5px;
  grid-column-gap: 2%;
  box-sizing: border-box;
`;

export const GridRowContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  padding: 10px;
  max-height: 100vh;
  overflow: auto;
`;
