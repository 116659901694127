/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import useLister from '../../BaseLister/hook';
import LoaderContainer from '../../DashBoard/LoaderContainer';
import PeoplesList from './listlist';
import { useLayoutGridHook } from '../../../api/hooks';

const FILTER_BASIC = { is_pool: false };
const FILTER_POOL = { is_pool: true };
const API_URL = '/api/cat/peoples/';
export function PeopleList() {
  const {
    count, data, fields, onNextPage, onPrevPage,
    onSearch, setErr, loading, err, canNextPage, canPrevPage,
    onSetFilter, filter, loaded,
  } = useLister({ url: API_URL, overrideFilter: FILTER_BASIC });
  const { screenWidth } = useLayoutGridHook();
  const sff = useRef();
  const [showFilters, setShowFilters] = useState(false);
  sff.current = showFilters;
  useEffect(() => {
    if (screenWidth >= 992 && !sff.current) setShowFilters(true);
    else if (screenWidth < 992 && sff.current) setShowFilters(false);
  }, [screenWidth]);
  return (
    <LoaderContainer
      onPrevPage={onPrevPage}
      canPrev={canPrevPage}
      onNextPage={onNextPage}
      canNext={canNextPage}
      setErr={setErr}
      loading={loading}
      err={err}
      count={count}
    >
      {loaded && (
        <PeoplesList
          count={count}
          onSearch={onSearch}
          data={data}
          fields={fields}
          filter={filter}
          onSetFilter={onSetFilter}
          showFilters={showFilters}
          onSetShowFilters={setShowFilters}
        />
      )}
    </LoaderContainer>
  );
}

export function PoolPeopleList() {
  const {
    count, data, fields, onNextPage, onPrevPage,
    onSearch, setErr, loading, err, canNextPage, canPrevPage,
    onSetFilter, filter, loaded,
  } = useLister({ url: API_URL, overrideFilter: FILTER_POOL });

  const { screenWidth } = useLayoutGridHook();
  const sff = useRef();
  const [showFilters, setShowFilters] = useState(false);
  sff.current = showFilters;
  useEffect(() => {
    if (screenWidth >= 992 && !sff.current) setShowFilters(true);
    else if (screenWidth < 992 && sff.current) setShowFilters(false);
  }, [screenWidth]);

  return (
    <LoaderContainer
      onPrevPage={onPrevPage}
      canPrev={canPrevPage}
      onNextPage={onNextPage}
      canNext={canNextPage}
      setErr={setErr}
      loading={loading}
      err={err}
    >
      {loaded && (
        <PeoplesList
          count={count}
          onSearch={onSearch}
          data={data}
          fields={fields}
          filter={filter}
          onSetFilter={onSetFilter}
          showFilters={showFilters}
          onSetShowFilters={setShowFilters}

        />
      )}
    </LoaderContainer>
  );
}
