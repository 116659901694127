import React, {
  memo, useCallback,
  useContext, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Alert, OverlayTrigger, Popover, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import classNames from 'classnames';
import api from '../../../api/req';
import AppContext from '../../../providers/authProvider';
import {
  Loader,
} from '../../../components/Styled/Misc';
import { useDict } from '../../BaseLister/hook';
import { EditorControls } from '../../BasicEditor/editorControls';
import DateShow from '../../../components/Controls/dates/dateShow';

const Th90 = styled.td`
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    vertical-align: middle;
`;
const ThPeople = styled.th`
    left: 0;
    position: sticky;
`;

const TdPeople = styled.td`
    left: 0;
    position: sticky;
`;
const StyledTR = styled.tr`
  &.gut td {
      background: var(--bs-success-bg-subtle);
  }
`;

const StyledImage = styled.img`
  height: 10rem;
`;

const StyledThead = styled.thead.attrs(({ top }) => ({
  style: { top: `${top}px` },
}))`
  z-index: 1;
`;

function ReportChecklistShower() {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [template, setTemplate] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableTop, setTableTop] = useState(0);

  const { auth } = useContext(AppContext);
  const cheklisttemplates = useDict({ url: '/api/cat/checklist_templates/' });
  useEffect(() => {
    const load = async (url, params = {}) => {
      const r = await api.get(url, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    if (templateId !== null) {
      setErr(null);
      setLoading(true);
      Promise.all([
        load(`/api/cat/checklist_templates/${templateId}/`),
        load('/api/checklists/', { template: templateId }),
      ]).then(([tmpl, checklists]) => {
        setTemplate(tmpl);
        setRows(
          checklists.map(({ people, landing_date: landingDate, details }) => ({
            people,
            landingDate,
            details: tmpl.details.reduce((R, td) => ({
              ...R,
              [td.id]: details.filter((d) => d.template_detail === td.id).reduce((R2, dd) => dd, {}),
            }), {}),
            allChecked: details.reduce((D, d) => D && (!d.required || d.checked), true),
          })),
        );
      })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    }
  }, [auth, templateId]);

  const tableRef = useCallback(
    (node) => {
      if (node) setTableTop(node.getBoundingClientRect().top);
    },
    [],
  );
  return (
    <>
      {loading && <Loader />}
      {err && (
        <Alert variant="danger">{err}</Alert>
      )}
      <div className="w-100 bg-white position-sticky fixed-under-menu">
        <div className="w-25">
          <EditorControls.SelectorInput
            required
            value={templateId}
            onChange={(e, v) => setTemplateId(v)}
            values={cheklisttemplates.selectorValues}
            label={<span className="text-nowrap">Checklist template</span>}
            inline
          />
        </div>
      </div>
      {template && (
      <Table striped hover size="sm" bordered className="w-auto">
        <StyledThead className="fixed-under-menu position-sticky" top={tableTop} ref={tableRef}>
          <tr>
            <th scope="col">#</th>
            <ThPeople className="z-1" scope="col">Candidate</ThPeople>
            <th scope="col">Joining date</th>
            {template.details.map((c) => (
              <Th90 key={c.id} scope="col">
                {c.name}
              </Th90>
            ))}
          </tr>
        </StyledThead>
        <tbody>
          {rows.map((row, i) => (
            <StyledTR key={row.people.id} className={classNames('text-danger', { gut: row.allChecked })}>
              <td>{i + 1}</td>
              <TdPeople
                scope="row"
                className="d-flex"
              >
                <OverlayTrigger overlay={(
                  <Popover>
                    <Popover.Body className="text-center">
                      <StyledImage
                        src={row.people.photo}
                        alt={row.people.family_name}
                        className="rounded"
                      />
                    </Popover.Body>
                    <Popover.Header as="h3">
                      {row.people.name}
                      {' '}
                      {row.people.family_name}
                    </Popover.Header>
                  </Popover>
              )}
                >
                  <Link to={`/candidates/${row.people.id}/`} className="text-decoration-none">
                    {`${row.people.name} ${row.people.family_name}`}
                  </Link>
                </OverlayTrigger>
              </TdPeople>
              <td>
                <DateShow value={row.landingDate} />
              </td>
              {template.details.map((c) => (
                <td key={c.id} className="text-center">
                  {row.details[c.id].required && row.details[c.id].checked && (
                  <FontAwesomeIcon icon={faCheck} className="text-success" size="lg" />
                  )}
                  {row.details[c.id].required && !row.details[c.id].checked && (
                  <FontAwesomeIcon icon={faTimesCircle} className="text-danger" size="lg" />
                  )}
                </td>
              ))}
            </StyledTR>
          ))}

        </tbody>
      </Table>
      )}
    </>
  );
}

export default memo(ReportChecklistShower);
