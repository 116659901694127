/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useTablePart from '../../../../BasicEditor/hook/tablepart';
import DetailsHeader from './header';
import DetailsRow from './row';

function Details({
  onChange, data, fields, errors,
}) {
  const {
    tableData, tableActions,
    tableContainerRef, tableErrors,
    tableFields,
  } = useTablePart({
    data, onChange, tableName: 'details', errors, fields,
  });

  return (
    <Card className="border-0 ">
      <Card.Header>
        <DetailsHeader fields={tableFields} />
      </Card.Header>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData.map((row, rowIndex) => (
          <DetailsRow
            row={row}
            key={rowIndex}
            errors={tableErrors[rowIndex]}
            fields={tableFields}
            onChange={tableActions.onCellChange}
            onDeleteRow={tableActions.onDeleteRow}
            index={rowIndex}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Button variant="outline-primary" onClick={() => tableActions.onAddRow()}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add row
        </Button>
      </Card.Footer>
    </Card>
  );
}
Details.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  fields: PropTypes.shape({
    details: PropTypes.shape(),
  }),
  errors: PropTypes.shape({
  }),
  onChange: PropTypes.func.isRequired,
};

Details.defaultProps = {
  data: {},
  errors: {},
  fields: {},
};

export default Details;
