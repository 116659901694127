import React, {
  createRef,
  forwardRef, memo, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

const StringInput = forwardRef(({
  value, disabled, readOnly, size,
  onClick, onFocus, onBlur, maxLength, onChange, id,
  errors, prepend, append, className, required, errorAsTooltip, type, as, placeholder,
}, oldRef) => {
  const ref = oldRef || createRef();
  const oldSelectionStart = useRef({ start: null, end: null });
  useEffect(
    () => {
      if (type === 'email') return;
      // eslint-disable-next-line no-constant-condition
      if (value || true) {
        ref.current.setSelectionRange(
          oldSelectionStart.current.start,
          oldSelectionStart.current.end,
        );
      }
    },
    [ref, type, value],
  );
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      if (type !== 'email') {
        oldSelectionStart.current = {
          start: ref.current.selectionStart,
          end: ref.current.selectionEnd,
        };
      }
      onChange(e, newValue);
    }
  };
  const displayValue = useMemo(
    () => value || '',
    [value],
  );
  const errored = errors && !!errors.length;

  return (
    <InputGroup className={classNames(className, { required, 'is-invalid': errored })} size={size || 'sm'}>
      {prepend}
      <FormControl
        ref={ref}
        id={id}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        isInvalid={errored}
        isValid={false}
        type={type}
        as={as}
        placeholder={placeholder}
        value={displayValue}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
      />
      {append}
      {errors && (
        <Form.Control.Feedback type="invalid" tooltip={errorAsTooltip}>
          {errors.join(', ')}
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
});

StringInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  prepend: PropTypes.node,
  append: PropTypes.node,
  errors: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  errorAsTooltip: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

StringInput.defaultProps = {
  id: '',
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  onBlur: null,
  prepend: null,
  append: null,
  required: false,
  className: '',
  size: 'sm',
  type: 'text',
  as: 'input',
  errorAsTooltip: false,
  errors: [],
  placeholder: '',
};

export default memo(StringInput);
