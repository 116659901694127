import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import {
  // CsvViewer,
  // DocxViewer,
  // VideoViewer,
  XlsxViewer,
  // XBimViewer,
  PDFViewer,
  UnsupportedViewer,
  ImgViewer,
  // PhotoViewerWrapper,
  // AudioViewer,
} from './drivers';

function FileViewer({ fileType, filePath, className }) {
  const Driver = useMemo(
    () => {
      switch (fileType.toLowerCase()) {
      // case 'csv': {
      //   return withFetching(CsvViewer, this.props);
      // }
        case 'xlsx': {
          return XlsxViewer;
        // const newProps = Object.assign({}, this.props, { responseType: 'arraybuffer' });
        // return withFetching(XlsxViewer, newProps);
        }
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png': {
          return ImgViewer;
        }
        case 'pdf': {
          return PDFViewer;
        }
        // case 'docx': {
        //   return DocxViewer;
        // }
        // case 'mp3': {
        //   return AudioViewer;
        // }
        // case 'webm':
        // case 'mp4': {
        //   return VideoViewer;
        // }
        // case 'wexbim': {
        //   return XBimViewer;
        // }
        default: {
          return UnsupportedViewer;
        }
      }
    },
    [fileType],
  );
  return (
    <div className={classNames('h-100 d-flex flex-column', className)}>
      <div className="h-100 position-relative flex-fill d-flex flex-column justify-content-center">
        <Driver filePath={filePath} fileType={fileType} />
      </div>
      <Button variant="link" className="text-white" download href={filePath} target="_blank">download this</Button>
    </div>
  );
}

FileViewer.propTypes = {
  fileType: PropTypes.string.isRequired,
  filePath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FileViewer.defaultProps = {
  className: '',
};
export default FileViewer;
